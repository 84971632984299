// Module
var code = `<template>
    <section id="faq">
        <div class="faq-container">
            <div class="container-header">
                <h1 class="container-heading-text text-left font-bold mb-0">
                    <cx-page-content-area
                        key.bind="'CX_FAQS_TITLE'"
                    ></cx-page-content-area>
                </h1>
                <div class="container-search">
                    <div class="search-mobile">
                        <cds-drawer drawertoggle.trigger="handleDrawerToggle(\$event)" class="drawer">
                            <div show.bind="showDrawerContent" ref="drawerContentSimplebar" slot="drawer-content" data-simplebar class="purple-scrollbar drawer-content">
                                <cds-collapse-group slot="content" if.bind="shouldRenderCollapse" multiple.bind="collapseGroupMultiple">
                                    <div class="d-flex section-column-mobile" >
                                            <div class="no-results" if.bind="noResults">
                                                <cds-large-button is-clickable.bind="false" icon="search_off">
                                                    <p class="mb-0 no-results-message">There are no entries to show here. <br/> Please consider changing search keywords, or updating filter options.</p>
                                                </cds-large-button>
                                            </div>
                                            <cds-collapse show.bind="!noResults" repeat.for="section of filteredSections" closed-icon="arrow_drop_down" opened-icon.bind="null" id="cds-collapse-\${section.id}" 
                                                collapsetoggle.trigger="handleCollapseAnimation(\$event, section)"
                                                class="cds-collapse"
                                                is-open.bind="section.openByDefault">
                                                <div slot="collapse-header-icon" class="container-section-icon">
                                                    <cds-icon icon.bind="getIconSrc(section.iconPath)" color="#310FA2"></cds-icon>
                                                </div>
                                                <h2 title="\${section.title}" ref="sectionHeadingRef" id="section-header-\${section.id}" slot="collapse-header" class="section-header line-clamp">
                                                    \${section.title}
                                                </h2>
                                                <div class="question-card-border-container">
                                                    <div class="question-card">
                                                        <div repeat.for="question of section.questions">
                                                            <div class="\${!\$last && 'question-card-border'} hover-whole-question">
                                                                <cds-question-collapse opened-icon="remove" closed-icon="add"
                                                                    side-icon-fading.bind="true"
                                                                    question="\${question.question}"
                                                                    question-title="\${question.question}"
                                                                    is-open.bind="question.openByDefault || shouldQuestionBeOpen">
                                                                    <p slot="collapse-question-answer" class="question-content">\${question.answer}</p>
                                                                </cds-question-collapse>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </cds-collapse>
                                        </div>
                                </cds-collapse-group>
                            </div>
                            <div class="drawer-search prevent-border-movement" slot="drawer-header" >
                                <cds-search 
                                    class="mb-10"
                                    trailing-icon="close" 
                                    indicator="border" 
                                    leading-icon.bind="currentLeadingIcon" 
                                    value.two-way="search" 
                                    input.trigger="handleSearchChange(\$event)" 
                                    label="Search"
                                ></cds-search>
                            </div>
                        </cds-drawer>
                    </div>
                    
                </div>
                <div class="search-bar">
                    <cds-search 
                        class="mb-10"
                        trailing-icon="close" 
                        indicator="border" 
                        leading-icon.bind="currentLeadingIcon" 
                        value.bind="search" 
                        input.trigger="handleSearchChange(\$event)"
                        label="Search"
                    ></cds-search>
                </div>
            </div>
            <hr class="divider-mobile" />
            <cds-collapse-group if.bind="shouldRenderCollapse" multiple.bind="collapseGroupMultiple">
                <div if.bind="!showDrawerContent" class="container-question-section">
                    <div class="no-results" show.bind="noResults">
                        <cds-large-button is-clickable.bind="false" icon="search_off">
                            <p class="mb-0 no-results-message">There are no entries to show here. <br/> Please consider changing search keywords, or updating filter options.</p>
                        </cds-large-button>
                    </div>
                    <div if.bind="!noResults" class="d-flex section-column">
                        <cds-collapse closed-icon="arrow_drop_down" opened-icon.bind="null" id="cds-collapse-\${section.id}" class="cds-collapse"
                            collapsetoggle.trigger="handleCollapseAnimation(\$event, section)"
                            repeat.for="section of getFirstColumnSliced(filteredSections)"
                            is-open.bind="section.openByDefault">
                            <div slot="collapse-header-icon" class="container-section-icon">
                                <cds-icon icon.bind="getIconSrc(section.iconPath)" color="#310FA2"></cds-icon>
                            </div>
                            <h2 title="\${section.title}" ref="sectionHeadingRef" id="section-header-\${section.id}" slot="collapse-header"
                                class="section-header line-clamp">
                                \${section.title}
                            </h2>
                            <div class="question-card-border-container">
                                <div class="question-card">
                                    <div repeat.for="question of section.questions">
                                        <div class="\${!\$last && 'question-card-border'} hover-whole-question">
                                            <cds-question-collapse 
                                                opened-icon="remove"
                                                side-icon-fading.bind="true"
                                                closed-icon="add"
                                                question="\${question.question}"
                                                question-title="\${question.question}" 
                                                is-open.bind="question.openByDefault || shouldQuestionBeOpen"
                                            >
                                                <p slot="collapse-question-answer" class="question-content">\${question.answer}</p>
                                            </cds-question-collapse>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </cds-collapse>
                    </div>
                    <div if.bind="!noResults" class="d-flex section-column">
                        <cds-collapse closed-icon="arrow_drop_down" opened-icon.bind="null" id="cds-collapse-\${section.id}" class="cds-collapse"
                            collapsetoggle.trigger="handleCollapseAnimation(\$event, section)"
                            repeat.for="section of getSecondColumnSliced(filteredSections)"
                            is-open.bind="section.openByDefault || question.openByDefault">
                            <div slot="collapse-header-icon" class="container-section-icon">
                                <cds-icon icon.bind="getIconSrc(section.iconPath)" color="#310FA2"></cds-icon>
                            </div>
                            <h2 title="\${section.title}" ref="sectionHeadingRef" id="section-header-\${section.id}" slot="collapse-header"
                                class="section-header line-clamp">
                                \${section.title}
                            </h2>
                            <div class="question-card-border-container">
                                <div class="question-card">
                                    <div repeat.for="question of section.questions">
                                        <div class="\${!\$last && 'question-card-border'} hover-whole-question">
                                            <cds-question-collapse opened-icon="remove" closed-icon="add"
                                                side-icon-fading.bind="true"
                                                question="\${question.question}"
                                                question-title="\${question.question}"
                                                is-open.bind="question.openByDefault || shouldQuestionBeOpen">
                                                <p slot="collapse-question-answer" class="question-content">\${question.answer}</p>
                                            </cds-question-collapse>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </cds-collapse>
                    </div>
                </div>
            </cds-collapse-group>  
        </div>
    </section>
</template>
`;
// Exports
export default code;