import { Router } from 'aurelia-router';
import './cx-page-content-area.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

@autoinject()
export class CxPageContentArea {
    @bindable key: string;
    @bindable triggerBorderChange: boolean = false;
    @bindable alternativeKey: string;
    pageContent = '';
    viewingAsAdmin: boolean;
    adminViewSubscriber;
    legalPages = ['copyright-policy', 'cookies', 'terms-of-service', 'privacy-policy'];
    reportPages = ['crypto-asset-statements'];
    contentElement;
    retrievedContentChangedSubscriber : Subscription;
    user;

    constructor(private pageContentAreaService: PageContentAreaService, private sessionService: SessionService, private eventAggregator: EventAggregator, private router: Router) {
    }

    async attached() {
        try {
            this.user = await this.sessionService.getProfile();
            const canAccessAdminPanel = await this.sessionService.checkRolesForPanelAccess();
            this.viewingAsAdmin = this.sessionService.getAdminView() && this.user && canAccessAdminPanel;
            this.setFontInheritForTitles();
            await this.setByKey();
            this.handleEventSubscriptions();
        } catch (e) {
            console.log(e);
        } finally {
            if (this.triggerBorderChange) this.setBorders('#bug-bounty');
            else if (this.legalPages.includes(this.router.currentInstruction.config.name)) this.setBorders('.legal-page');
            else if (this.reportPages.includes(this.router.currentInstruction.config.name)) this.setBreadcrumbs();
        }
    }

    detached() {
        this.adminViewSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
            this.setByKey();
            if (!this.triggerBorderChange) return;
            this.setBorders('#bug-bounty');
        });

        this.retrievedContentChangedSubscriber = this.eventAggregator.subscribe('retrieved-content-changed', () => this.setByKey());
    }

    setFontInheritForTitles() {
        this.contentElement?.querySelectorAll('h1, h2, h3, h4, h5, p, span, strong').forEach(el => {
            el.classList.add('font-size-inherit');
        });
    }

    async setByKey() {
        const result = await this.pageContentAreaService.getByKey(this.key);
        if (this.viewingAsAdmin) {
            this.pageContent = `Key: { ${this.key} } ${this.alternativeKey ? `Alternative Key: { ${this.alternativeKey} }` : ''}`;
            if (this.contentElement) this.contentElement.style.fontSize = '10px';
        } else {
            this.pageContent = result ? result.markup : await this.getAlternativeKeyContent();
            if (this.contentElement) this.contentElement.style.fontSize = 'inherit';
        }
    }

    async getAlternativeKeyContent(): Promise<string> {
        if (!this.alternativeKey) return '';
        const result = await this.pageContentAreaService.getByKey(this.alternativeKey);
        return result ? result.markup : '';
    }

    async keyChanged() {
        await this.setByKey();
        if (!this.triggerBorderChange) return;
        this.setBorders('#bug-bounty');
    }

    setBorders(selector) {
        setTimeout(() => {
            const borders = Array.from(document.querySelectorAll(`${selector} p, ${selector} p span`)).filter(element => ['<hr>', '#border#', '#line#', '#horizontal-line#', '#horizontal-rule#', '&lt;hr&gt;'].some(e => e === (element as HTMLElement).innerText));
            borders.forEach(element => element.replaceWith(document.createElement('hr')));
        }, 50);
    }

    setBreadcrumbs() {
        setTimeout(() => {
            const breadcrumbs = Array.from(document.querySelectorAll('a, span')).filter(element => ['<', '&lt;', '>', '&gt;'].some(e => (element as HTMLElement).innerText.includes(e)));
            breadcrumbs.forEach(element => {
                if (['<', '&lt;'].some(e => (element as HTMLElement).innerText.includes(e))) {
                    const html = (element as HTMLElement).innerHTML.replace('&lt;', '');
                    (element as HTMLElement).innerHTML = `<span class="material-icons">chevron_left</span> <span>${html}</span>`;
                }

                if (['>', '&gt;'].some(e => (element as HTMLElement).innerText.includes(e))) {
                    const html = (element as HTMLElement).innerHTML.replace('&gt;', '');
                    (element as HTMLElement).innerHTML = `<span class="material-icons text-large" style="padding-bottom: 1px;">keyboard_arrow_right</span> <span>${html}</span>`;
                }
            });
        }, 10);
    }
}
