// Module
var code = `<template>
    <div id="cx-amount-currency-selector" class="position-relative" ref="amountCurrencySelector">
        <div class="amount-currency-container align-items-center d-none d-lg-flex" tabindex="1">
            <div class="double-input-wallet-address" ref="doubleInputRef">
                <div class="icon-with-tooltip" if.bind="showTooltip">
                    <span class.bind="labelClass">\${intentHandler(intent)}</span>
                    <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon"
                        aria-label="tooltip" 
                        show.bind="showTooltipForReceive"
                        id.bind="tooltipRefId">
                        contact_support
                    </mdc-icon>
                    <cds-tooltip
                        show.bind="showTooltipForReceive"
                        reference-id.bind="tooltipRefId"
                        placement="right"
                        show-arrow="true">
                        <div class="d-flex flex-column" receive-tooltip-content></div>
                    </cds-tooltip>
                </div>
                <mdc-text-field
                    class="cx-form-control text-black pe-0"
                    placeholder="Enter amount"
                    required.bind="true"
                    value.bind="inputAmount"
                    type="number"
                    input.delegate="disableExchangeBtn()"
                    keydown.delegate="focusOutCheckValid() & debounce: 1000"
                    focusout.delegate="focusOutCheckValid() & debounce: 1000"
                ></mdc-text-field>
            </div>
            <div class="double-input-extra-field">
                <cx-currency-selector
                    currency-list.bind="currencyList"
                    currency-selected.two-way="currencySelected"
                    exchange-currency-selected.two-way="exchangeCurrencySelected"
                    intent.bind="intent"
                    active-currencies.bind="activeCurrencies"
                ></cx-currency-selector>
            </div>
        </div>
        <div class="d-lg-none">
            <div class="icon-with-tooltip" if.bind="showTooltip">
                <span class.bind="labelClass" skeleton-loading="is-loading.bind:pageLoading;">\${intentHandler(intent)}</span>
                <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon"
                    aria-label="tooltip"
                    id="\${tooltipRefId}-mobile">
                    contact_support
                </mdc-icon>
                <cds-tooltip
                    reference-id="\${tooltipRefId}-mobile"
                    placement="right"
                    show-arrow="true">
                    <div class="d-flex flex-column" receive-tooltip-content></div>
                </cds-tooltip>
            </div>
            <div class="amount-input-mobile \${pageLoading ? 'mt-2' : ''}" ref="amountInputMobileRef" skeleton-loading="is-loading.bind:pageLoading;">
                <cds-text-field
                    class="\${!inputAmount ? 'mw-100' : ''}"
                    type="number"
                    variant="filled"
                    placeholder="Enter amount"
                    value.two-way="inputAmount"
                    keydown.trigger="focusOutCheckValid() & debounce: 1000"
                    input.trigger="getAmountWidth(\$event.target.value)"
                    focusout.trigger="focusOutCheckValid() & debounce: 1000"
                    no-spinner="true">
                </cds-text-field>
                <div show.bind="inputAmount.length && !pageLoading" class="currency-code-suffix" ref="currencyCodeSuffixRef">
                    \${currencySelected.code}
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;