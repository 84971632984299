// Module
var code = `<template>
    <div id="footer" class="w-100" ref="footerContainer">
        <div class="footer-content">
            <div class="info-section">
                <a href="\${homePageRoute.routeName | languageRoute & async}" class="logo d-block">
                    <img src.bind="\`/logo/\${logoImg}.svg\`" mouseover.delegate="mouseOverLogo()"
                        mouseout.delegate="mouseOutLogo()" alt="ChicksX Logo" loading="lazy">
                </a>
                <a href="\${baseUrl}" class="exchange-link">The lowest fee crypto exchange</a>
                <a href="mailto:support@chicksx.com" class="contact-email d-block">support@chicksx.com</a>
                <cx-language-currency-selector class="d-none d-lg-block"></cx-language-currency-selector>
            </div>
            <div class="main-content">
                <div class="links-section-mobile">
                    <cx-footer-dropdown title.bind="'ChicksX'" links.bind="chicksxSection"></cx-footer-dropdown>
                    <cx-footer-dropdown title.bind="'CX_SUPPORT' | siteStrings"
                        links.bind="supportSection"></cx-footer-dropdown>
                    <cx-footer-dropdown title.bind="'CX_LEGAL' | siteStrings"
                        links.bind="legalSection"></cx-footer-dropdown>
                </div>
                <div class="links-section-tablet-desktop">
                    <cx-footer-section title.bind="'ChicksX'" links.bind="chicksxSection"></cx-footer-section>
                    <cx-footer-section title.bind="'CX_SUPPORT' | siteStrings"
                        links.bind="supportSection"></cx-footer-section>
                    <cx-footer-section title.bind="'CX_LEGAL' | siteStrings"
                        links.bind="legalSection"></cx-footer-section>
                    <div class="trustpilot-reviews trustpilot-reviews-tablet">
                        <compose view="./trustpilot-content.html"></compose>
                    </div>
                </div>
            </div>
            <div class="right-section">
                <div class="social-media">
                    <span class="d-none d-lg-block">\${'CX_SOCIAL' | siteStrings}</span>
                    <div class="social-media-circles">
                        <cds-social-circles 
                            facebook-href="https://www.facebook.com/chicksxcrypto"
                            instagram-href="https://www.instagram.com/chicksxcrypto/"
                            twitter-href="https://twitter.com/ChicksXcrypto"
                            discord-href="https://discord.com/invite/chicksx-com">
                        </cds-social-circles>
                    </div>
                </div>
                <div class="trustpilot-reviews">
                    <compose view="./trustpilot-content.html"></compose>
                </div>
            </div>
        </div>
        <div class="bottom-section text-center w-100">
            Copyright © 2021, ChicksX.com. \${'CX_RIGHTS_RESERVED' | siteStrings}
        </div>
    </div>
</template>
`;
// Exports
export default code;