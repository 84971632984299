// Module
var code = `<template>
    <div id="subscription-manage-membership">
        <cx-card class.bind="'main-container-subscriptions'">
            <cds-back-button click.trigger="router.navigateToRoute(backRoute)" page-loading.bind="pageLoading"></cds-back-button>
            <div class="manage-membership-header">
                <h1 class="subscription__title" skeleton-loading="is-loading.bind:pageLoading;">
                    \${'CX_MANAGE_MEMBERSHIP' | siteStrings}
                </h1>
                <p show.bind="pageLoading" skeleton-loading="is-loading.bind:pageLoading;width:255px;height:16px;"></p>
                <div if.bind="!pageLoading">
                    <p if.bind="!user.renewal" class="subscription__desc subscription__notice">
                        \${'CX_SUBSCRIPTION_CANCELED_ON' | siteStrings:'params':[dateFormatterValueConverter.toView(userSubscriptionInfo.unsubscribedAt, 'format', 'MMMM Do, yyyy'),dateFormatterValueConverter.toView(userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy')]}
                        <span class="subscription__link" click.delegate="setRenewalFunction()">\${'CX_ENABLE_AUTO_RENEWAL' | siteStrings}</span>
                    </p>
                    <p else class="subscription__desc">
                        \${'CX_RENEWS_ON_MANAGE' | siteStrings:null:[dateFormatterValueConverter.toView(userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy'),priceFormatted]}
                    </p>
                </div>
            </div>
            <div class="manage-membership-boxes">
                <cds-large-button
                    title.bind="sectionData[sections.Details].titleKey|siteStrings:sectionData[sections.Details].title"
                    description.bind="sectionData[sections.Details].descriptionKey|siteStrings:sectionData[sections.Details].description"
                    onclick.call="setSection(sections.Details)"
                    icon="currency_exchange"
                    skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                ></cds-large-button>
                <cds-large-button
                    class="manage-membership-boxes__plan"
                    title.bind="sectionData[sections.ChangePlan].titleKey|siteStrings:sectionData[sections.ChangePlan].title"
                    description.bind="sectionData[sections.ChangePlan].descriptionKey|siteStrings:null:[dateFormatterValueConverter.toView(userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy')]"
                    icon="diamond"
                    skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                    is-clickable.bind="false"
                    show.bind="isDesktop"
                >                    
                    <div slot="content">
                        <div class="options-container">
                            <cds-tabs
                                tabs.bind="tabs"
                                selected.trigger="setTab(\$event)"
                                ref="tabsElement"
                            ></cds-tabs>
                        </div>
                    </div>
                </cds-large-button>
                <cds-large-button
                    title.bind="sectionData[sections.LearnMore].titleKey|siteStrings:sectionData[sections.LearnMore].title"
                    description.bind="sectionData[sections.LearnMore].descriptionKey|siteStrings:sectionData[sections.LearnMore].description"
                    onclick.call="redirectToFaq()"
                    icon="help"
                    skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                ></cds-large-button>
                <cds-large-button
                    title.bind="sectionData[sections.Cancel].optionTitleKey|siteStrings:sectionData[sections.Cancel].optionTitle"
                    description.bind="sectionData[sections.Cancel].descriptionKey|siteStrings:sectionData[sections.Cancel].description"
                    onclick.call="setSection(sections.Cancel)"
                    icon="person_cancel"
                    skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                ></cds-large-button>
            </div>
        </cx-card>
    </div>
</template>
`;
// Exports
export default code;