import './purchase-flow.scss';
import {
    CashInMailDetail,
    CashInPersonDetail,
    Currency,
    Exchange,
    NuveiAddCreditCard,
    NuveiPaymentDataRequest,
    OperationType,
    OrderCryptoExchanges,
    OrderDetail, PaymentMethodCurrencyFeeValuesResponse,
    PaymentMethodThreshold,
    PaymentMethodWebsite,
    PylonPaymentDataRequest,
    ThresholdOperationType,
    VerificationType,
    OrderType, CurrencyType
} from 'services/models/purchase-flow/exchange';
import { PageContentAreaService } from 'services/page-content-area-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, BindingEngine, computedFrom, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DeliveryMethodService } from 'services/delivery-method-service';
import { SessionService } from 'services/session-service';
import { CoinbaseService } from 'services/coinbase-service';
import { CurrencyService } from 'services/currency-service';
import { OrderService } from 'services/order-service';
import { ToastService } from 'services/toast-service';
import { TotalSpentByUser, User } from 'services/models/user/user';
import { ImageService } from 'services/image-service';
import { Country, ICountry, IState, State } from 'country-state-city';
import { baseUrl, getAWSBucketEndpoint, websiteShortCode } from 'environment';
import { WebsiteService } from 'services/website-service';
import { TextStartsWithValueConverter } from 'resources/value-converters/text-starts-with';
import { DeliveryMethod } from 'services/models/purchase-flow/delivery-method';
import { NuveiService } from 'services/nuvei-service';
import { BillingAddress } from 'services/models/user/billingAddress';
import { PageByWebsite } from 'services/models/page/pageByWebsite';
import { Order, OrderRequest } from 'services/models/order';
import { Helper } from 'resources/helpers/helper';
import { DeliveryDateConverter } from 'resources/value-converters/delivery-date';
import { CustomerService } from 'services/customer-service';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { isPointWithinRadius } from 'geolib';
import { SiteSettingService } from 'services/site-setting-service';
import { CurrencyTypes } from 'resources/helpers/enums';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';

@autoinject()
export class PurchaseFlow {
    constructor(
        private pageContentAreaService: PageContentAreaService,
        private coinbaseService: CoinbaseService,
        private orderService: OrderService,
        private toastService: ToastService,
        private currencyService: CurrencyService,
        private eventAggregator: EventAggregator,
        private bindingEngine: BindingEngine,
        private router: Router,
        private sessionService: SessionService,
        private imageService: ImageService,
        private websiteService: WebsiteService,
        private textStartsWithValueConverter: TextStartsWithValueConverter,
        private helper: Helper,
        private deliveryMethodService: DeliveryMethodService,
        private deliveryDateConverter: DeliveryDateConverter,
        private customerService: CustomerService,
        private nuveiService: NuveiService,
        private paymentMethodWebsiteService: PaymentMethodWebsiteService,
        private siteSettingService: SiteSettingService,
        private siteStringsValueConverter: SiteStringsValueConverter
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('payment-methods');
        this.disabledPayButton = false;
    }

    @bindable exchange: Exchange;
    @bindable walletInput;
    @bindable timeLeft: number;
    @bindable loggingIn: boolean;
    @observable selectedOption;
    @observable trackingNumberValid: boolean;
    baseAwsEndpoint: string;
    userSubscriber: Subscription;
    veriffSubscriber: Subscription;
    user: User;
    totalSpentByUser: TotalSpentByUser;
    timer;
    addressInputRef;
    meetupInputRef;
    phoneInputRef;
    paymentInformationRef;
    documentUploadRef;
    cashInPersonRef;
    cashInMailRef;
    @observable isWalletAddressValid = false;
    myPaymentSelector;
    paymentMethods: PaymentMethodWebsite[];
    feePercentageToUse?: PaymentMethodCurrencyFeeValuesResponse;
    usdRates: { [key: string]: number; };
    forceCurrencySubscriber: Subscription;
    selectedPaymentMethodObject: PaymentMethodWebsite;
    preferredCurrency;
    toastSent = false;
    isCryptoPayValid = false;
    deliveryAddressValid = false;
    @observable meetupAddressValid = false;
    deliveryOptionsValidate = false;
    amountRequiresVeriff: boolean;
    amountRequiresPhoneVeriff: boolean;
    pages: PageByWebsite[];
    termsOfServiceRoute: string;
    privacyPolicyRoute: string;
    homePageRoute: string;
    address: string;
    country: string;
    state: string;
    city: string;
    zip: string;
    methodFlow: 'delivery' | 'meetup' | 'tracking-number';
    shortWalletAddress: string;
    userDocumentList: File[] = [];
    userProxy;
    vpnDetectionSubscriber: Subscription;
    phoneSubscriber: Subscription;

    countries: ICountry[];
    allCountries: ICountry[];
    states: IState[];
    userPhone: User;

    location: string;
    date: string;
    time: string;

    stateSpinner: string;
    activeCurrencies: Currency[];
    cryptoList: Currency[];
    coinpaymentList: Currency[];
    cryptoMethodsList: Currency[];
    currencyList: Currency[];

    disabledPayButton: boolean;
    isSelling: boolean = false;
    deliveryMethods: DeliveryMethod[];
    selectedDelivery: DeliveryMethod;
    loading = true;
    autoRestart: boolean = true;
    mostPopularGiveCurrencies: Currency[] = [];
    otherGiveCurrencies: Currency[] = [];
    mostPopularReceiveCurrencies: Currency[] = [];
    otherReceiveCurrencies: Currency[] = [];
    userVaultedCards = [];
    billing: BillingAddress = {};
    cardNumber: string;
    cardType: string;
    cardCvv: string;
    ccRecentlySaved;
    thresholds: PaymentMethodThreshold[] = [];
    geoCoderLatitude: number;
    geoCoderLongitude: number;
    startOrderAfterVeriff = false;
    siteSettingsKeys;
    discountAmount: number;
    couponInputViewModel;
    useCashback: boolean = true; // Default is set to true temporarily, pending the addition of the VIP section to the cart page.

    async activate() {
        await this.loadInitialData();

        if (!this.exchange) {
            this.router.navigate('');
        }
    }

    initializeRoutes() {
        this.termsOfServiceRoute = this.pages.find(x => x.name === 'Terms Of Service')?.routeName ?? 'terms-of-service';
        this.privacyPolicyRoute = this.pages.find(x => x.name === 'Privacy Policy')?.routeName ?? 'privacy-policy';
        this.homePageRoute = this.pages.find(x => x.name === 'Home')?.routeName ?? '';
    }

    async loadInitialData() {
        [this.user, this.exchange, this.pages, this.activeCurrencies, this.usdRates, this.userProxy, this.cryptoMethodsList, this.siteSettingsKeys] = await Promise.all([
            this.sessionService.refreshProfile(),
            this.sessionService.getExchange(),
            this.websiteService.getPagesByWebsiteShortcode(),
            this.currencyService.getActiveCurrenciesByWebsite(),
            this.coinbaseService.getJustExchangesRates('USD'),
            this.sessionService.getUserProxy(),
            this.currencyService.getCryptoForSelector(),
            this.siteSettingService.getSiteSettings()
        ]);
        await this.pageContentAreaService.getByPageId(this.pages.find(x => x.name === 'Purchase Flow')?.id);
        this.currencyList = this.activeCurrencies.filter(e => e.type === 'F');
        this.cryptoList = this.activeCurrencies.filter(e => e.type === 'C');

        [this.preferredCurrency, this.thresholds] = await Promise.all([
            this.sessionService.getPreviousCurrency(),
            this.paymentMethodWebsiteService.getThresholds(),
            this.assignThresholds(),
            this.validateCryptoPayButton(),
            this.setPopularAndOtherCurrencies()
        ]);

    }


    async attached() {
        this.initializeRoutes();
        await this.setFee(null, null, null);
        if (this.user) this.totalSpentByUser = await this.customerService.getTotalSpentByUser(this.user.id);
        this.isSelling = this.exchange.giveSelectedCurrency.type === 'C';

        this.bindExchangeProperty();
        this.overrideMdc();
        this.timeLeft = this.exchange.timeLeft;

        if ((this.userProxy?.vpn || this.userProxy?.tor) && this.userProxy.ip !== '127.0.0.1' && this.userProxy !== 'localhost') {
            this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Please ensure you\'re using a genuine internet connection before making a purchase. The use of a VPN or TOR may result in failed attempts or a refund' });
        }
        this.handleEventSubscriptions();
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-mail' && this.exchange.selectedPaymentMethod.paymentMethod.reference !== 'cash-in-mail') {
            this.allCountries = Country.getAllCountries();
            this.countries = this.allCountries.filter(country => {
                return this.currentDeliverableCountries.includes(country.isoCode);
            });
            this.buildStatesFromCountry('US');
        }

        this.handleRequiresDelivery();
        this.deliveryOptionsValidate = this.validateDeliveryOptions();
        this.toggleActiveStep(false, true);
        await this.getDeliveryMethods();
        this.isSelling = this.exchange.giveSelectedCurrency.type === 'C';

        /**
         * If the user is sending Crypto to us, we don't capture a wallet address, this validation was breaking some flows.
         * Rather than checking everywhere to remove the validation for these cases, we autovalidate Address from top level.
         */

        if (this.exchange.giveCurrencyOptions[0].type === 'C') {
            this.isWalletAddressValid = true;
        }

        if (this.isSelling && this.exchange.receiveSelectedCurrency.type === 'C') {
            this.isWalletAddressValid = false;
        }

        this.loading = false;
    }

    toggleActiveStep(shortTimeout?: boolean, initialLoad: boolean = false) {
        if (
            !this.user
            || (this.amountRequiresPhoneVeriff && !this.user.phoneNumberConfirmed)
            || (this.methodFlow === 'tracking-number' && this.amountRequiresVeriff && !this.user.idVerified && this.isWalletAddressValid)
        ) return;
        setTimeout(() => {
            this.overrideMdc();
            if (this.exchange.receiveSelectedCurrency.type === 'C' && !this.isWalletAddressValid && (this.methodFlow !== 'meetup' || this.meetupAddressValid)) {
                if (!initialLoad) this.scrollToStep('cx-wallet-input');
                if (!this.walletInput.expandableOpen) this.walletInput?.onToggle();
            }
            if (this.methodFlow === 'delivery' && !this.deliveryAddressValid) {
                this.scrollToStep('cx-address-input');
                if (!this.addressInputRef.expandableOpen) this.addressInputRef?.onToggle();
            }
            if (this.isWalletAddressValid && this.methodFlow === 'tracking-number' && !this.trackingNumberValid) {
                this.scrollToStep('tracking-number');
                if (!this.cashInMailRef.expandableOpen) this.cashInMailRef?.onToggle();
            }
            if (this.methodFlow === 'meetup' && !this.meetupAddressValid) {
                this.scrollToStep('cash-in-person');
                if (!this.cashInPersonRef.expandableOpen) this.cashInPersonRef?.onToggle();
            }
            const isVeriffNotRequiredOrUserVerified = !this.amountRequiresVeriff || this.user.idVerified;
            const isCashInPersonWithValidAddress = this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-person' && this.meetupAddressValid;
            const isNonCashAndHasPaymentNotes = !['cash-in-mail', 'cash-in-person'].includes(this.exchange.receivingPaymentMethod.paymentMethod.reference) && this.exchange.receivingPaymentMethod.paymentStepNotes;
            const isValidWalletAddressAndFiatSelected = this.isWalletAddressValid && this.exchange.giveSelectedCurrency.type === 'F';
            const isCashInCurrencyTypeC = (isCashInPersonWithValidAddress || isNonCashAndHasPaymentNotes) && this.exchange.giveSelectedCurrency.type === 'C';
            const isNotInteracWithWalletAddressValid = this.exchange.selectedPaymentMethod.paymentMethod.reference !== 'interac' && isValidWalletAddressAndFiatSelected;
            if (isVeriffNotRequiredOrUserVerified && (isCashInCurrencyTypeC || isNotInteracWithWalletAddressValid)) {
                this.scrollToStep('payment-information');
                if (!this.paymentInformationRef.expandableOpen) {
                    this.paymentInformationRef?.onToggle();
                }
            }
            if (this.exchange.selectedPaymentMethod.paymentMethod.reference === 'interac' && isValidWalletAddressAndFiatSelected) {
                this.scrollToStep('cx-wallet-input');
                if (!this.walletInput.expandableOpen) this.walletInput?.onToggle();
            }
        }, shortTimeout ? 1 : 500);
    }

    buildStatesFromCountry(country: string) {
        this.states = State.getStatesOfCountry(country);
    }

    detached() {
        this.exchange = null;
        this.userSubscriber?.dispose();
        this.veriffSubscriber?.dispose();
        this.forceCurrencySubscriber?.dispose();
        this.vpnDetectionSubscriber?.dispose();
        this.phoneSubscriber?.dispose();
        this.timer.au.controller.viewModel.stop();
        this.helper.removeGtagEvent();
        this.helper.removeTwitterEvent();
    }

    handleEventSubscriptions() {
        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async (payload: { user: User }) => {
            this.user = payload.user;
            if (this.user) this.totalSpentByUser = await this.customerService.getTotalSpentByUser(this.user.id);
            await this.validateCryptoPayButton();
            this.toggleActiveStep();
        });

        this.veriffSubscriber = this.eventAggregator.subscribe('veriff-verification', async payload => {
            if (payload?.user.idVerified) {
                this.user.idVerified = payload.user.idVerified;
                this.toggleActiveStep();
                await this.validateCryptoPayButton();
                if (this.startOrderAfterVeriff) {
                    this.startOrderAfterVeriff = false;
                    this.startOrder();
                }
            }
        });

        this.forceCurrencySubscriber = this.eventAggregator.subscribe('force-currency', async payload => {
            if (payload.currentPaymentMethodSelected !== null && this.selectedPaymentMethodObject !== payload.currentPaymentMethodSelected) {
                if (this.exchange.receiveSelectedCurrency.type === 'C') {
                    this.exchange.receivingPaymentMethod = payload.currentPaymentMethodSelected;
                    this.sessionService.saveExchange(this.exchange);
                }
                this.selectedPaymentMethodObject = payload.currentPaymentMethodSelected;
                const intent = this.exchange.giveCurrencyOptions.every(c => c.type === 'F') ? 'give' : 'receive';
                const currencyOption = this.activeCurrencies.find(currency => currency.code === payload.currency);
                this.setCurrency(currencyOption.code, payload.oldCurrency, intent);
            }
        });

        this.vpnDetectionSubscriber = this.eventAggregator.subscribe('vpn-detection', async(payload) => {
            if ((payload.vpn || payload.tor) && payload.ip !== '127.0.0.1' && payload.host !== 'localhost') {
                this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Please ensure you\'re using a genuine internet connection before making a purchase. The use of a VPN or TOR may result in failed attempts or a refund' });
            }
        });

        this.phoneSubscriber = this.eventAggregator.subscribe('phone-updated', payload => {
            if (payload.successful) {
                this.user.phoneNumberConfirmed = true;
            }
        });
    }

    async setCurrenciesBasedPaymentMethod() {
        const isAllCurrencyFiat = this.exchange.giveCurrencyOptions.every(currency => currency.type === 'F');
        const exchange = await this.sessionService.getExchange();
        const receiveCurrency = exchange.receiveSelectedCurrency.code;
        const giveCurrency = exchange.giveSelectedCurrency.code;
        if (this.exchange.selectedPaymentMethod.paymentMethod.reference.includes('cash-in-person') && this.selectedPaymentMethodObject) {
            if (isAllCurrencyFiat) {
                this.exchange.giveCurrencyOptions = this.currencyList;
                this.exchange.receiveCurrencyOptions = this.cryptoList.filter(c => c.code !== this.exchange.giveSelectedCurrency.code);
                this.exchange.receiveSelectedCurrency = this.cryptoList.find(c => c.code === receiveCurrency);
                this.exchange.giveSelectedCurrency = this.cryptoList.find(c => c.code === giveCurrency);
            }
        } else {
            if (isAllCurrencyFiat) {
                this.exchange.giveCurrencyOptions = this.currencyList;
                this.exchange.receiveCurrencyOptions = this.cryptoList.filter(currency => !currency.hasFiatToFiat);
                this.exchange.receiveSelectedCurrency = this.cryptoList.find(c => c.code === receiveCurrency);
                this.exchange.giveSelectedCurrency = this.cryptoList.find(c => c.code === giveCurrency);
                if (this.exchange.receiveSelectedCurrency?.hasFiatToFiat) {
                    this.exchange.receiveSelectedCurrency = this.exchange.receiveCurrencyOptions[0];
                }
            }
        }
        this.sessionService.saveExchange(this.exchange);
    }

    async removeDuplicateCurrency(operationType: OperationType) {
        this.setCurrenciesBasedOperationType(operationType);
        this.exchange.receiveCurrencyOptions = this.exchange.receiveCurrencyOptions.filter(c => c.code !== this.exchange.giveSelectedCurrency?.code);
        this.exchange.giveCurrencyOptions = this.exchange.giveCurrencyOptions.filter(c => c.code !== this.exchange.receiveSelectedCurrency?.code);
        this.sessionService.saveExchange(this.exchange);
    }

    setCurrenciesBasedOperationType(operationType: OperationType) {
        if (operationType === OperationType.Buy) {
            this.exchange.giveCurrencyOptions = [...this.currencyList];
            this.exchange.receiveCurrencyOptions = [...this.cryptoList];
        } else if (operationType === 'S') {
            this.exchange.giveCurrencyOptions = [...this.cryptoMethodsList];
            this.exchange.receiveCurrencyOptions = [...this.currencyList];
        } else if (operationType === 'FF') {
            this.exchange.giveCurrencyOptions = [...this.currencyList];
            this.exchange.receiveCurrencyOptions = [...this.currencyList];
        } else if (operationType === 'CC') {
            this.exchange.giveCurrencyOptions = [...this.cryptoList];
            this.exchange.receiveCurrencyOptions = [...this.cryptoList];
        }
    }

    async setCurrency(currency: string, oldCurrency: Currency | string, intent: string) {
        let correctCurrency = '';
        oldCurrency && typeof oldCurrency === 'object' ? oldCurrency = oldCurrency.code : oldCurrency;
        if (this.selectedPaymentMethodObject.supportedCurrencies?.length) {
            const currencies = this.selectedPaymentMethodObject.supportedCurrencies.map(c => this.activeCurrencies.find(ac => ac.id === c.currencyId)?.code);
            if (!currencies.includes(oldCurrency as string)) {
                currency = currencies[0];
                correctCurrency = currencies.join(', ');
            } else {
                currency = currencies.includes(this.preferredCurrency.code) ? this.preferredCurrency.code : oldCurrency as string;
            }
        }
        if (correctCurrency !== '' && !this.toastSent) {
            this.toastSent = true;
            await this.toastService.showToast('Info', `${this.selectedPaymentMethodObject.paymentMethod.name} can only be paid in ${correctCurrency}. We've changed the checkout currency to reflect your payment method selection.`, 'info');
            if (intent.includes('give')) {
                this.exchange.giveSelectedCurrency = this.exchange.giveCurrencyOptions?.find(currencyItem => currencyItem.code === currency);
            } else {
                this.exchange.receiveSelectedCurrency = this.exchange.receiveCurrencyOptions?.find(currencyItem => currencyItem.code === currency);
            }
            setTimeout(() => {
                this.toastSent = false;
            }, 1000);
        }
    }

    /**
     * Handles the calculations when the one of the select is changed
     * @param intent intent of the input select activated
     */
    async updateAmountInSelect(intent: 'give' | 'receive', currencySelected: Currency) {
        const operationType = this.getOperationType();
        if (currencySelected.type === 'F') {
            let currencyToSent = '';
            const previousCurrency = intent === 'give' ? this.exchange.giveSelectedCurrency.code : this.exchange.receiveSelectedCurrency.code;
            this.selectedPaymentMethodObject = await this.helper.getCategoryByIdAndName(this.exchange.selectedPaymentMethod.paymentMethod.paymentMethodCategoryId, 'Manual') ? this.exchange.selectedPaymentMethod : this.exchange.receivingPaymentMethod;

            if (this.selectedPaymentMethodObject?.supportedCurrencies?.length) {
                const currencies = this.selectedPaymentMethodObject.supportedCurrencies.map(c => this.activeCurrencies.find(ac => ac.id === c.currencyId)?.code);
                currencyToSent = this.preferredCurrency;
                if (!currencies.includes(currencyToSent)) {
                    currencyToSent = currencies[0];
                }
            } else {
                if (this.selectedPaymentMethodObject) {
                    currencyToSent = previousCurrency;
                } else {
                    currencyToSent = currencySelected.code;
                }
            }
            await this.setCurrency(currencyToSent, currencySelected.code, intent);
        }

        if (operationType === OperationType.Fiat2Fiat || operationType === OperationType.Crypto2Crypto) {
            await this.removeDuplicateCurrency(operationType);
        }

        if (intent === 'give') {
            if (!this.exchange.youGiveRates[currencySelected.code]) {
                this.exchange.youGiveRates = await this.coinbaseService.getJustExchangesRatesWithInvertedDesiredCurrency(this.exchange.receiveSelectedCurrency.code, currencySelected.code);
            }
            this.exchange.youGiveRates = await this.coinbaseService.getJustExchangesRates(currencySelected.code);
            await this.setCurrentRate();
            await this.setFee(currencySelected, null, null);
            this.setFeeAndReceiveAmount();
        } else {
            if (!this.exchange.youReceiveRates[currencySelected.code]) {
                this.exchange.youReceiveRates = await this.coinbaseService.getJustExchangesRatesWithInvertedDesiredCurrency(this.exchange.giveSelectedCurrency.code, currencySelected.code);
            }
            this.exchange.youReceiveRates = await this.coinbaseService.getJustExchangesRates(currencySelected.code);
            await this.setCurrentRate();
            await this.setFee(null, currencySelected, (this.exchange.amountReceived / this.exchange.currentRate));
            this.setFeeAndReceiveAmount();
        }
        this.setFeeAndGiftAmount();
        //Handle checkout payment method
        if (this.exchange.giveSelectedCurrency.type === 'C' && currencySelected.type === 'C') {
            this.myPaymentSelector.au.controller.viewModel.changeCryptoPaymentSelected(currencySelected.symbol);
        }

        this.validateDeliveryOptions();
    }

    /**
     * function executed when amount changed on inputs
     * @param intent intent of the input select activated
     */
    async updateContraryAmount(intent: 'give' | 'receive') {
        if (intent === 'give') {
            await this.setFee(null, null, null);
            await this.couponInputViewModel.calculateCouponDiscount();
            this.setFeeAndReceiveAmount();
        } else {
            await this.setFee(null, null, (this.exchange.amountReceived / this.exchange.currentRate));
            this.setFeeAndGiftAmount();
        }
        await this.assignThresholds();
    }

    /**
     * Function that gets the fee based on website / payment method / currency selected
     * @returns Fee
     */
    async setFee(giveSelectedCurrency: Currency, receiveSelectedCurrency: Currency, amount: number) {
        const dataForFee = {
            giveSelectedCurrency: giveSelectedCurrency ?? this.exchange.giveSelectedCurrency,
            receiveSelectedCurrency: receiveSelectedCurrency ?? this.exchange.receiveSelectedCurrency,
            selectedPaymentMethod: this.exchange.selectedPaymentMethod,
            receivingPaymentMethod: this.exchange.receivingPaymentMethod,
            amount: await this.getAmountInCurrency(amount ?? this.exchange.amountGifted),
        };
        this.helper.clearServiceQueueState('PaymentMethodCurrencyFeeService');
        this.feePercentageToUse = await this.currencyService.getFee(dataForFee);
    }

    /**
     * If Amount is USD returns the amountGifted if not it will get the equivalent of that amount in USD
     * @returns amountInUsd
     */
    async getAmountInCurrency(amount: number, currency?: string) {
        if ((currency && currency !== 'USD') || this.exchange.giveSelectedCurrency.code !== 'USD') {
            let auxRate = this.exchange.youGiveRates[currency || 'USD'];
            if (!auxRate) {
                //Request the exchange rates with extra param
                const auxGiveRates = await this.coinbaseService.getJustExchangesRatesWithInvertedDesiredCurrency('USD', this.exchange.giveSelectedCurrency.code);
                auxRate = auxGiveRates[this.exchange.giveSelectedCurrency.code];
            }
            return amount * auxRate;
        } else {
            return amount;
        }
    }


    /**
     * Calculates the total amount and fees
     */
    setFeeAndReceiveAmount() {
        const baseFee = (this.feePercentageToUse?.baseFee ?? 0);
        let orderBaseFee: number;

        if (this.exchange.receiveSelectedCurrency.type === CurrencyType.Crypto && this.exchange.giveSelectedCurrency.type === CurrencyType.Fiat) {
            orderBaseFee = baseFee;
        } else {
            orderBaseFee = baseFee * (1 / this.exchange.currentRate);
        }

        this.exchange.transactionFeeBeforeRateApplied = ((this.feePercentageToUse?.percentage ?? 0) * this.exchange.amountGifted) + orderBaseFee;
        this.exchange.transactionFee = this.exchange.transactionFeeBeforeRateApplied * this.exchange.currentRate;
        this.exchange.cryptoFee = this.exchange.receiveSelectedCurrency.type === 'C' ? this.exchange.transactionFee : this.exchange.transactionFeeBeforeRateApplied;
        this.exchange.deliveryFeeBeforeRateApplied = this.exchange.deliveryFeePercentage / 100 * this.exchange.amountGifted;
        this.exchange.deliveryFee = this.exchange.deliveryFeeBeforeRateApplied * this.exchange.currentRate;
        let amountReceived = (this.exchange.amountGifted * this.exchange.currentRate) - this.exchange.transactionFee - this.exchange.deliveryFee;

        if (this.exchange.validCoupon) {
            const couponPercentage = this.exchange.validCoupon.value / 100;
            const discountPercentageValue = amountReceived * couponPercentage;
            const usdExchangeRate = 1 / this.exchange.youReceiveRates['USD'];
            const discount = this.exchange.validCoupon.type === 'Percent' ? discountPercentageValue : this.exchange.validCoupon.value * usdExchangeRate;
            amountReceived += discount;
        }

        this.exchange.amountReceived = parseFloat(amountReceived.toFixed(this.exchange.receiveSelectedCurrency.isStable ? 2 : 6));
    }


    /**
     * Calculates the gifted amount and fees
     */
    async setFeeAndGiftAmount() {
        if (!this.usdRates) this.usdRates = await this.coinbaseService.getJustExchangesRates('USD');
        let discount = 0;
        if (this.exchange.validCoupon) {
            const couponPercentage = this.exchange.validCoupon.value / 100;
            const discountPercentageValue = (this.exchange.amountReceived / (1 + couponPercentage)) * couponPercentage;
            discount = this.exchange.validCoupon.type === 'Percent' ? discountPercentageValue : this.exchange.validCoupon.value;
        }

        const feePercentage = this.feePercentageToUse?.percentage ?? 0;
        this.exchange.amountGifted = (this.exchange.amountReceived - discount) / (1 - (feePercentage + this.exchange.deliveryFeePercentage / 100)) / this.exchange.currentRate;

        if (this.exchange.validCoupon) {
            await this.couponInputViewModel.calculateCouponDiscount(this.exchange.amountGifted);
            if (!this.exchange.validCoupon) {
                this.exchange.amountGifted = this.exchange.amountReceived / (1 - (feePercentage + this.exchange.deliveryFeePercentage / 100)) / this.exchange.currentRate;
            }
        }

        this.exchange.transactionFeeBeforeRateApplied = feePercentage * this.exchange.amountGifted;
        this.exchange.transactionFee = this.exchange.transactionFeeBeforeRateApplied * this.exchange.currentRate;
        this.exchange.cryptoFee = this.exchange.receiveSelectedCurrency.type === 'C' ? this.exchange.transactionFee : this.exchange.transactionFeeBeforeRateApplied;
        this.exchange.deliveryFeeBeforeRateApplied = this.exchange.deliveryFeePercentage / 100 * this.exchange.amountGifted;
        this.exchange.deliveryFee = this.exchange.deliveryFeeBeforeRateApplied * this.exchange.currentRate;
        const amountInUsd = await this.getAmountInCurrency(this.exchange.amountGifted);
        const toFixedAmount = this.exchange.giveSelectedCurrency.type === 'F' || this.exchange.giveSelectedCurrency.isStable ? 2 : 6;

        if (amountInUsd < this.paymentMethodToUse.minimum) {
            const auxAmount = (this.paymentMethodToUse.minimum * this.usdRates[this.exchange.giveSelectedCurrency.code]).toFixed(toFixedAmount);
            this.exchange.amountGifted = parseFloat(auxAmount);
            await this.toastService.showToast('Minimum amount not met', `The minimum amount for ${this.paymentMethodToUse.paymentMethod.name} is ${auxAmount}${this.exchange.giveSelectedCurrency.symbol} `, 'error');
            await this.setFee(null, null, null);
            this.setFeeAndReceiveAmount();
        }
        if (amountInUsd > this.paymentMethodToUse.maximum) {
            const auxAmount = (this.paymentMethodToUse.maximum * this.usdRates[this.exchange.giveSelectedCurrency.code]).toFixed(toFixedAmount);
            this.exchange.amountGifted = parseFloat(auxAmount);
            await this.toastService.showToast('Maximum amount not met', `The maximum amount for ${this.paymentMethodToUse.paymentMethod.name} is ${auxAmount}${this.exchange.giveSelectedCurrency.symbol} `, 'error');
            await this.setFee(null, null, null);
            this.setFeeAndReceiveAmount();
        }
        this.exchange.amountGifted = parseFloat(parseFloat(this.exchange.amountGifted.toString()).toFixed(this.exchange.giveSelectedCurrency.isStable ? 2 : 6));
    }

    /**
     * Function Executed when timer finishes
     */
    async countdownFinished() {
        try {
            this.exchange.youGiveRates = await this.coinbaseService.getJustExchangesRates(this.exchange?.giveSelectedCurrency.code);
            this.exchange.youReceiveRates = await this.coinbaseService.getJustExchangesRates(this.exchange?.receiveSelectedCurrency.code);
            await this.setCurrentRate();
            await this.setFee(null, null, null);
            this.setFeeAndReceiveAmount();
        } catch (e) {
            this.autoRestart = false;
        }
    }

    /**
     * Set Current rate, in case it doesnt exist it will use the contrary rate and if not it will trow an error
     */
    async setCurrentRate() {
        this.exchange.currentRate = this.exchange.youGiveRates[this.exchange?.receiveSelectedCurrency?.code?.split(' ')?.join('-')];
        if (!this.exchange.currentRate) {
            //Request the exchange rates with extra param
            this.exchange.youGiveRates = await this.coinbaseService.getJustExchangesRatesWithInvertedDesiredCurrency(this.exchange?.giveSelectedCurrency.code, this.exchange.receiveSelectedCurrency.code);
            this.exchange.currentRate = this.exchange.youGiveRates[this.exchange.receiveSelectedCurrency.code?.split(' ').join('-')];
        }

        if ((this.exchange.giveSelectedCurrency?.type === 'F' && this.exchange.receiveSelectedCurrency?.type === 'C') || (this.exchange.giveSelectedCurrency?.type === 'C' && this.exchange.receiveSelectedCurrency?.type === 'F')) {
            const fiatToSpreadFee = this.exchange.giveSelectedCurrency?.type === 'F' ? this.exchange.giveSelectedCurrency.code : this.exchange.receiveSelectedCurrency.code;
            const cryptoToSpreadFee = this.exchange.giveSelectedCurrency?.type === 'F' ? this.exchange.receiveSelectedCurrency.reference : this.exchange.giveSelectedCurrency.reference;

            const fiatSpreadFee = (await this.currencyService.getSpreadFeeByCurrencyCodeAndWebsite(fiatToSpreadFee, 'CX'))?.spreadFee ?? 0;
            const cryptoSpreadFee = (await this.paymentMethodWebsiteService.getByReference(cryptoToSpreadFee))?.spreadFee ?? 0;
            const totalSpreadFee = fiatSpreadFee + cryptoSpreadFee;
            this.exchange.currentRate = this.helper.calculateSpreadFee(totalSpreadFee, this.exchange.currentRate);
        }
    }

    /**
     * Construct the total of the order.
     * @returns Composed total
     */
    getTotalAmount() {
        return `${this.exchange.giveSelectedCurrency.code} ${this.exchange.amountGifted}`;
    }

    /**
     * Function that returns the correct symbol depending on the intent you sent to it
     * @param intent which type of currency will be the symbol be used
     * @returns Currency symbol
     */
    getSymbol(intent: string) {
        if (intent.includes('give')) {
            return this.exchange.giveSelectedCurrency.symbol;
        } else {
            return this.exchange.receiveSelectedCurrency.symbol;
        }
    }

    /**
     * Validates depending on type of verification
     * By default invalidates payment when switching method
     * @returns true if valid
     */

    async validateCryptoPayButton() {
        this.isCryptoPayValid = false;
        if (!this.user) return;
        await this.assignThresholds();
        if (this.amountRequiresPhoneVeriff && !this.user.phoneNumberConfirmed) return;
        if (
            !['cash-in-mail', 'cash-in-person', 'interac'].includes(this.exchange.receivingPaymentMethod.paymentMethod.reference) &&
            (await this.helper.getCategoryByIdAndName(this.exchange.receivingPaymentMethod.paymentMethod.paymentMethodCategoryId, 'Manual') || await this.helper.getCategoryByIdAndName(this.exchange.receivingPaymentMethod.paymentMethod.paymentMethodCategoryId, 'Crypto Swap'))
        ) {
            this.isCryptoPayValid = this.exchange.receiveSelectedCurrency.type === 'C' ? this.isWalletAddressValid : true;
        }
        // Buying Crypto using FIAT CIM
        if (this.exchange.selectedPaymentMethod.paymentMethod?.reference === 'cash-in-mail' && this.isWalletAddressValid) {
            this.isCryptoPayValid = this.trackingNumberValid;
        }

        // Buying FIAT using Crypto CIM
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-mail' && this.deliveryAddressValid) {
            this.isCryptoPayValid = true;
        }

        // Buying Crypto using FIAT Interac
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'interac' && this.exchange.giveSelectedCurrency.type === 'F' && this.isWalletAddressValid) {
            this.isCryptoPayValid = true;
        }

        // Buying FIAT using crypto Interac
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'interac' && this.exchange.giveSelectedCurrency.type === 'C') {
            this.isCryptoPayValid = true;
        }

        // Buying Crypto using FIAT Cash In Person
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-person' && this.meetupAddressValid && this.isWalletAddressValid) {
            this.isCryptoPayValid = true;
        }

        // Buying FIAT using crypto Cash In Person
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-person' && this.meetupAddressValid && this.exchange.giveSelectedCurrency.type === 'F' && this.exchange.receiveSelectedCurrency.type === 'F') {
            this.isCryptoPayValid = true;
        }

        // Buying Crypto using Pylon
        if (this.exchange.selectedPaymentMethod.paymentMethod.reference === 'pylon' && this.isWalletAddressValid) {
            this.isCryptoPayValid = true;
        }

        // Buying Crypto using Nuvei
        if (this.exchange.selectedPaymentMethod.paymentMethod.reference === 'nuvei' && this.isWalletAddressValid) {
            this.isCryptoPayValid = !!this.exchange.selectedPaymentMethod?.paymentMethod?.cardInfo?.cvv;
        }
    }

    /**
     * Validates and builds delivery options based on payment method
     * @returns true if valid
     */

    validateDeliveryOptions() {
        this.deliveryOptionsValidate = false;
        // Payment method is Cash In Person and user receives Cash
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-person' && this.exchange.receiveSelectedCurrency.type === 'F') {
            this.deliveryOptionsValidate = true;
        }

        // Payment method is Cash In Mail and user receives Cash
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-mail' && this.exchange.receiveSelectedCurrency.type === 'F') {
            this.deliveryOptionsValidate = true;
        }
        return this.deliveryOptionsValidate;
    }

    // Buy = Crypto x Fiat
    // Sell = Fiat x Crypto
    async assignThresholds() {
        const frequencyToAmountMap = {
            0: this.totalSpentByUser?.totalSpent,
            1: this.totalSpentByUser?.total1DaySpent,
            7: this.totalSpentByUser?.total7DaySpent,
            30: this.totalSpentByUser?.total30DaySpent,
        };
        for (const t of this.thresholds) {
            if (t.paymentMethodId === this.exchange.receivingPaymentMethod.paymentMethodId) {
                const amountSpent = frequencyToAmountMap[t.frequency] || 0;
                const currencyCode = (await this.currencyService.getCurrencyById(t.currencyId))?.code;
                const amountInCurrency = await this.getAmountInCurrency(this.exchange.amountGifted, currencyCode);
                const threshold = (amountSpent + amountInCurrency) >= t.threshold;
                if (
                    (t.operationTypeList.includes(ThresholdOperationType.Sell) && this.exchange.giveSelectedCurrency.type === 'F' && this.exchange.receiveSelectedCurrency.type === 'C') ||
                    (t.operationTypeList.includes(ThresholdOperationType.Buy) && this.exchange.giveSelectedCurrency.type === 'C' && this.exchange.receiveSelectedCurrency.type === 'F')
                ) {
                    t.verificationTypeList.forEach(v => {
                        switch (v) {
                            case VerificationType.ID: this.amountRequiresVeriff = threshold; break;
                            case VerificationType.Phone: this.amountRequiresPhoneVeriff = threshold; break;
                        }
                    });
                }
            }
        }
    }

    /**
     * Captures internal paymentmethod override and ensures revalidation for everything but crypto address which remains the same.
     */

    handlePaymentMethodSwitch() {
        this.trackingNumberValid = false;
        this.deliveryAddressValid = false;
        this.meetupAddressValid = false;
        this.validateCryptoPayButton();
    }

    /**
     * Goes back to the previous step
     */
    backToPreviousStep() {
        if (this.loggingIn) {
            this.loggingIn = false;
            return;
        }
        if (this.user && !this.user.phoneNumberConfirmed && this.amountRequiresPhoneVeriff && this.phoneInputRef.au.controller.viewModel.verificationStage === 2) {
            this.phoneInputRef.au.controller.viewModel.setPhoneNumberNotInReview();
            return;
        }
        if (this.user && this.methodFlow === 'delivery' && this.deliveryAddressValid) {
            this.addressInputRef.reset();
            this.toggleActiveStep(true);
            return;
        }
        if (this.user && this.methodFlow === 'tracking-number' && this.trackingNumberValid) {
            this.trackingNumberValid = false;
            this.toggleActiveStep(true);
            return;
        }
        if (this.user && !this.isWalletAddressValid && this.methodFlow === 'meetup' && this.meetupAddressValid) {
            this.meetupAddressValid = false;
            this.cashInPersonRef.reset();
            this.toggleActiveStep(true);
            return;
        }
        if (this.user && (this.amountRequiresPhoneVeriff ? this.user.phoneNumberConfirmed : true) && this.exchange.receiveSelectedCurrency.type === 'C' && this.exchange.walletAddress) {
            this.walletInput?.resetValidation();
            this.toggleActiveStep(true);
            return;
        }
        this.router.navigate('/trade');
    }

    //: C: crypto
    //: F: fiat

    /**
     * Function that Starts to process the order
     * @returns null if there is error
     */
    async startOrder() {
        if (this.disabledPayButton) return;

        const checkVeriffLocation = this.siteSettingsKeys?.find(x => x.key === 'CheckVeriffLocation');
        const userSuspiciousForVeriff = await this.checkIfUserSuspiciousForVeriff();
        await this.assignThresholds();
        if (
            (checkVeriffLocation && parseInt(checkVeriffLocation?.value) > 0 && userSuspiciousForVeriff && !this.user.idVerified) ||
            (this.amountRequiresVeriff && !this.user.idVerified) ||
            (this.sessionService.checkVeriffBlacklistUser() && !this.user.idVerified)
        ) {
            this.startOrderAfterVeriff = true;
            this.documentUploadRef.openVeriff();
            return;
        }

        //PreparePayload
        const data = await this.buildOrder();
        this.stateSpinner = 'processing';
        //StartProcess
        this.helper.handleGtagEvent('begin_checkout', { name: data.orderCryptoExchanges?.[0].currencyTarget }, 'USD', await this.getAmountInCurrency(data.orderCryptoExchanges?.[0].amountBase), this.exchange.validCoupon?.code);
        this.helper.handleFacebookPixelEvent('InitiateCheckout', { id: data.orderCryptoExchanges?.[0].currencyTarget }, 'USD', await this.getAmountInCurrency(data.orderCryptoExchanges?.[0].amountBase));
        this.helper.handleTwitterEvent(this.user);
        const order = await this.orderService.startOrder(data);
        if (!order) {
            this.stateSpinner = 'completed';
            return;
        }

        await this.uploadAttachments(this.userDocumentList, order.id);
        this.finish(order);
        this.stateSpinner = 'completed';
        if (window?.Intercom && await this.helper.getCategoryByIdAndName(order?.receivingMethod?.paymentMethodCategoryId, 'Manual')) {
            const exchange = order.orderCryptoExchanges[0];
            const { baseCurrency, targetCurrency, amountBase, amountTarget } = exchange;
            const amountFrom = baseCurrency?.type === 'C'
                ? `${amountBase.toFixed(8)} ${baseCurrency?.symbol ?? ''}`
                : `${baseCurrency?.symbol ?? '$'}${amountBase.toFixed(2)}`;
            const amountTo = targetCurrency?.type === 'C'
                ? `${amountTarget.toFixed(8)} ${targetCurrency?.symbol ?? ''}`
                : `${targetCurrency?.symbol ?? ''}${amountTarget.toFixed(2)}`;
            window.Intercom(
                'showNewMessage',
                `Hi, I would like to trade ${amountFrom} to ${amountTo} via ${order.receivingMethod.name}`
            );
        }
    }

    /**
     *  Finish process of ordering
     * @param order
     */
    finish(order: Order) {
        this.sessionService.savePurchased(true);
        this.sessionService.setGiveCurrency(this.exchange.giveSelectedCurrency.code);
        this.sessionService.setReceiveCurrency(this.exchange.receiveSelectedCurrency.code);
        this.sessionService.savePreviousPaymentMethod(this.exchange.giveSelectedCurrency.type === 'C' ? this.exchange.receivingPaymentMethod.paymentMethod.reference : this.exchange.selectedPaymentMethod.paymentMethod.reference);

        if (order.externalTransactionId && order.externalTransactionId.trim() !== '') {
            return this.processExternalPaymentProviderOrder(order);
        }
        if (order.orderDetail?.pylonOrderDetail?.redirectUrl)
        {
            return this.router.navigate(order.orderDetail?.pylonOrderDetail?.redirectUrl);
        }
        if (order.hostedUrl) {
            this.router.navigate(`order-completed/${order.id}${!this.checkOpenWindow(order.hostedUrl) ? '?blocked=true' : ''}`);
        } else {
            this.router.navigate(`order-completed/${order.id}`);
        }
    }

    checkOpenWindow(url: string) {
        const checkOutWindow = window.open(url);
        if (!checkOutWindow || checkOutWindow.closed || typeof checkOutWindow.closed === 'undefined') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Builds the data of the order.
     * @returns Data of the order
     */
    async buildOrder(): Promise<OrderRequest> {
        let cashInMailOrderDetail: CashInMailDetail;
        let cashInPersonOrderDetail: CashInPersonDetail;
        let pylonPaymentData: PylonPaymentDataRequest;
        let nuveiPaymentData: NuveiPaymentDataRequest;
        const cvv = this.exchange.selectedPaymentMethod?.paymentMethod?.cardInfo?.cvv;
        const orderCryptoExchanges: OrderCryptoExchanges[] = [
            {
                currencyBase: this.exchange.giveSelectedCurrency.originalCode,
                currencyTarget: this.exchange.receiveSelectedCurrency.originalCode,
                walletAddress: this.exchange.walletAddress,
                rate: this.exchange.currentRate,
                amountBase: this.exchange.amountGifted,
                amountTarget: this.exchange.amountReceived,
                cryptoFee: this.exchange.cryptoFee,
                paymentFee: this.exchange.transactionFee,
                deliveryFee: this.exchange.deliveryFee,
                deliveryMethodId: this.selectedDelivery?.id,
                operationType: this.getOperationType(),
                targetCurrencyReference: this.exchange.receiveSelectedCurrency.reference,
                baseCurrencyReference: this.exchange.giveSelectedCurrency.reference
            }
        ];
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-mail') {
            cashInMailOrderDetail = {
                address: this.address,
                country: this.country,
                state: this.state,
                city: this.city,
                zipCode: this.zip,
                deliveryType: this.getDeliveryDate(this.selectedDelivery)
            };
        }
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-person') {
            cashInPersonOrderDetail = {
                location: this.location,
                date: this.date,
                time: this.time,
                meetingType: this.getDeliveryDate(this.selectedDelivery)
            };
        }
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'pylon') {
            pylonPaymentData = {
                cardReferenceID: null,
                baseUrl: baseUrl(),
            };
        }
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'nuvei') {
            let nuveiCardSaved = null;
            if (this.ccRecentlySaved && !this.exchange.selectedPaymentMethod.paymentMethod.cardInfo.cardId) {
                const { countryCode, state } = this.ccRecentlySaved.billingAddress;
                this.ccRecentlySaved.billingAddress.state = this.getNuveiStateSupported(countryCode, state);
                nuveiCardSaved = await this.nuveiService.addCreditCard(this.ccRecentlySaved);
            }
            nuveiPaymentData = {
                cvv,
                userPaymentOptionId: nuveiCardSaved ? nuveiCardSaved.userPaymentOptionId : this.exchange.selectedPaymentMethod.paymentMethod.cardInfo.cardId
            };
        }
        const orderDetail: OrderDetail = {
            cashInMailDetail: cashInMailOrderDetail,
            cashInPersonDetail: cashInPersonOrderDetail
        };
        return {
            websiteShortCode: websiteShortCode(),
            paymentMethodId: this.exchange.selectedPaymentMethod.paymentMethod?.id,
            receivingMethodId: this.exchange.receivingPaymentMethod.paymentMethod?.id,
            status: this.exchange.selectedPaymentMethod.paymentMethod.reference + ':created',
            firstName: this.user?.firstName,
            lastName: this.user?.lastName,
            orderCryptoExchanges: orderCryptoExchanges,
            currencyUsed: this.exchange.giveSelectedCurrency.code,
            userDocumentIds: [],
            easyPostTrackingId: this.exchange.trackingNumber,
            cashInMailOrderDetail: cashInMailOrderDetail,
            orderDetail: orderDetail,
            referralLinkUrl: this.sessionService.getReferralLink(),
            referrerLinkUrl: this.sessionService.getReferrerLink(),
            orderAdClicks: this.createOrderAdClicksList(),
            googleAnalyticsClientId: this.getGoogleTagManagerClientId(),
            facebookClickId: this.helper.getCookieValue('_fbc'),
            facebookBrowserId: this.helper.getCookieValue('_fbp'),
            redditUuid: this.helper.getCookieValue('_rdt_uuid'),
            screenWidth: window.screen.width.toString(),
            screenHeight: window.screen.height.toString(),
            pylonPaymentData,
            nuveiPaymentData,
            couponCodeString: this.exchange.validCoupon?.code,
            address: this.billing?.street,
            city: this.billing?.city,
            state: this.billing?.state,
            country: this.billing?.countryCode,
            zip: this.billing?.zip,
            cardLastFour: this.exchange.selectedPaymentMethod.paymentMethod?.cardInfo?.lastDigits,
            cardType: this.exchange.selectedPaymentMethod.paymentMethod?.cardInfo?.type,
            cardCvv: cvv,
            usdExchangeRate: this.exchange.validCoupon ? 1 / this.exchange.youReceiveRates['USD'] : undefined,
            useCashback: !this.exchange.validCoupon ? this.useCashback : null,
            orderTypeId: this.getOrderType(),
        };
    }

    getOrderType() {
        if (this.useCashback) {
            return OrderType.Cashback;
        }

        return null;
    }

    getNuveiStateSupported(countryCode: string, state: string) {
        const countriesSupported = ['AU', 'CA', 'IN', 'NZ', 'GB', 'US'];
        return countriesSupported.includes(countryCode) ? state : null;
    }

    getOperationType(): OperationType {
        if (this.exchange.giveSelectedCurrency.type === 'F' && this.exchange.receiveSelectedCurrency.type === 'F') {
            return OperationType.Fiat2Fiat;
        }

        if (this.exchange.giveSelectedCurrency.type === 'C' && this.exchange.receiveSelectedCurrency.type === 'C') {
            return OperationType.Crypto2Crypto;
        }

        return this.exchange.giveSelectedCurrency.type === 'F' ? OperationType.Sell : OperationType.Buy;
    }

    /**
     * Shapes the tooltip value for recipient
     * @returns Text for Recipient tooltip
     */
    getRecipientText() {
        const userFullName = this.user?.firstName + ' ' + (this.user?.lastName ? this.user?.lastName : '');
        return `Your recipient name and address are currently registered on your profile. To make any changes, please go to settings.\nName: ${userFullName}\nEmail: ${this.user?.email}`;
    }

    /**
     * Function that binds the properties to the exchange so when a
     * change is applied to them is saved on local storage in case user reloads page
     */
    bindExchangeProperty() {
        this.bindingEngine
            .propertyObserver(this.exchange, 'giveSelectedCurrency')
            .subscribe(() => this.sessionService.saveExchange(this.exchange));
        this.bindingEngine
            .propertyObserver(this.exchange, 'receiveSelectedCurrency')
            .subscribe(() => this.receiveSelectChanged());
        this.bindingEngine
            .propertyObserver(this.exchange, 'amountGifted')
            .subscribe(() => this.sessionService.saveExchange(this.exchange));
        this.bindingEngine
            .propertyObserver(this.exchange, 'amountReceived')
            .subscribe(() => this.sessionService.saveExchange(this.exchange));
        this.bindingEngine
            .propertyObserver(this.exchange, 'selectedPaymentMethod')
            .subscribe(async () => await this.selectedPaymentMethodChanged());
        this.bindingEngine
            .propertyObserver(this.exchange, 'currentRate')
            .subscribe(() => this.sessionService.saveExchange(this.exchange));
        this.bindingEngine
            .propertyObserver(this.exchange, 'walletAddress')
            .subscribe(() => {
                this.sessionService.saveExchange(this.exchange);
                this.getShortWalletAddress();
            });
        this.bindingEngine
            .propertyObserver(this.exchange, 'extraField')
            .subscribe(() => this.sessionService.saveExchange(this.exchange));
        this.bindingEngine
            .propertyObserver(this.exchange, 'trackingNumber')
            .subscribe(() => this.sessionService.saveExchange(this.exchange));
        //PENDING to add time left on timer
    }

    /**
     * Function executed when the receiveAmount is changed
     */
    async receiveSelectChanged() {
        //we need to apply this when is inverse transaction
        this.walletInput?.resetValidation();
        this.sessionService.saveExchange(this.exchange);
        if (this.exchange.receiveSelectedCurrency.type === 'F' && !this.deliveryMethods) {
            this.deliveryOptionsValidate = this.validateDeliveryOptions();
            await this.getDeliveryMethods();
        } else if (this.exchange.receiveSelectedCurrency.type === 'C' && this.deliveryMethods) {
            this.selectedDelivery = null;
            this.exchange.deliveryFee = 0;
            this.exchange.deliveryFeeBeforeRateApplied = 0;
            this.exchange.deliveryFeePercentage = 0;
        }
        await this.validateCryptoPayButton();
        this.toggleActiveStep();
    }

    /**
     * Function executed when the selectedPaymentMethod is changed
     */
    async selectedPaymentMethodChanged() {
        if (this.exchange.giveSelectedCurrency.type === 'C' && this.exchange.receiveSelectedCurrency.type === 'F') {
            const cryptoAbbreviation = this.exchange.selectedPaymentMethod.paymentMethod.cryptoAbbreviation;
            if (this.textStartsWithValueConverter.toView(cryptoAbbreviation, 'USDT'))
                this.exchange.selectedPaymentMethod.paymentMethod.cryptoAbbreviation = 'USDT';

            this.exchange.giveSelectedCurrency = this.exchange.giveCurrencyOptions.find(c => c.reference === this.exchange.selectedPaymentMethod.paymentMethod.reference);
        }
        await this.setFee(null, null, null);
        this.setFeeAndReceiveAmount();
        this.sessionService.saveExchange(this.exchange);
        this.handleRequiresDelivery();
        this.toggleActiveStep(false, true);
    }

    getShortWalletAddress() {
        if (this.exchange.walletAddress) this.shortWalletAddress = this.exchange.walletAddress.slice(0, 10) + '...' + this.exchange.walletAddress.slice(-10);
    }

    getGoogleTagManagerClientId() {
        let clientId = this.helper.getCookieValue('_ga');
        return clientId ? clientId = clientId.replace('GA1.1.', '') : null;
    }

    async getDeliveryMethods() {
        if (!this.deliveryOptionsValidate) return;
        this.deliveryMethods = await this.deliveryMethodService.getByPaymentMethodId(this.exchange.receivingPaymentMethod.paymentMethod.id);
    }

    async isWalletAddressValidChanged() {
        this.validateCryptoPayButton();
        if (this.isWalletAddressValid) {
            this.getShortWalletAddress();
            this.toggleActiveStep();
            setTimeout(() => {
                if (this.walletInput?.expandableOpen) this.walletInput?.onToggle();
            }, 500);
        }
    }

    async meetupAddressValidChanged() {
        if (this.meetupAddressValid) {
            this.toggleActiveStep();
            setTimeout(() => {
                this.cashInPersonRef.onToggle();
            }, 500);
        }
    }

    async trackingNumberValidChanged() {
        if (this.trackingNumberValid) {
            this.cashInMailRef.onToggle();
        }
    }

    scrollToStep(elementId: string) {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    /**
     * Will dynamically trigger the Physical Address component based on the future payment methods instead of hardcoding conditionals
     */
    handleRequiresDelivery() {
        // Buying FIAT using Crypto CIM
        if (this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-mail' && this.exchange.selectedPaymentMethod.paymentMethod.reference !== 'cash-in-mail') {
            this.methodFlow = 'delivery';
            return;
        }

        // Buying Crypto using FIAT CIM
        if (this.exchange.selectedPaymentMethod.paymentMethod.reference === 'cash-in-mail') {
            this.methodFlow = 'tracking-number';
            return;
        }

        // Any cash in person trade
        if (this.exchange.selectedPaymentMethod.paymentMethod.reference === 'cash-in-person' || this.exchange.receivingPaymentMethod.paymentMethod.reference === 'cash-in-person') {
            this.methodFlow = 'meetup';
            return;
        }
        this.methodFlow = undefined;
    }

    async uploadAttachments(fileList: File[], orderId) {
        for (const file of fileList) {
            const formData = this.imageService.buildFormData([file]);
            await this.imageService.postClientDocument(formData, null, 4, orderId);
        }
    }

    mouseOverOnTooltips() {
        this.eventAggregator.publish('tooltip-shown');
    }

    overrideMdc() {
        const purchaseFlow = document.getElementById('purchase-flow');
        const selectArrow = purchaseFlow.getElementsByClassName('mdc-expandable__dropdown-icon');
        const newArrowIcon = '<span class="expandable-item-arrow material-icons">expand_more</span>';
        if (selectArrow) {
            for (const arrow of Array.from(selectArrow)) {
                arrow.innerHTML = newArrowIcon;
            }
        }
    }

    getDeliveryDate(value: DeliveryMethod) {
        return this.deliveryDateConverter.toView(value);
    }

    currentDeliverableCountries = ['US', 'CA', 'RU', 'DE', 'GB', 'FR', 'IT', 'ES', 'UK', 'PL', 'RO', 'NL', 'BE', 'CZ', 'GR', 'PT', 'SE', 'HU', 'BY',
        'AT', 'RS', 'CH', 'BG', 'DK', 'FI', 'SK', 'NO', 'IE', 'HR', 'MD', 'BA', 'AL', 'LT', 'MK', 'SI', 'LV', 'EE', 'ME', 'LU', 'MT', 'AD', 'MC', 'LI'];

    createOrderAdClicksList() {
        const orderAdClicks = [];
        if (this.sessionService.getPlatformLinkCookie('gclid')) {
            orderAdClicks.push({
                service: 'Google',
                clickId: this.sessionService.getPlatformLinkCookie('gclid')
            });
        }
        if (this.sessionService.getPlatformLinkCookie('msclkid')) {
            orderAdClicks.push({
                service: 'Bing',
                clickId: this.sessionService.getPlatformLinkCookie('msclkid')
            });
        }
        if (this.sessionService.getPlatformLinkCookie('rdt_cid')) {
            orderAdClicks.push({
                service: 'Reddit',
                clickId: this.sessionService.getPlatformLinkCookie('rdt_cid')
            });
        }
        return orderAdClicks;
    }

    async setPopularAndOtherCurrencies() {
        const giveCurrencyList = this.exchange.giveCurrencyOptions;
        const receiveCurrencyList = this.exchange.receiveCurrencyOptions;

        [this.mostPopularGiveCurrencies, this.mostPopularReceiveCurrencies] = await Promise.all([
            await this.getTopCurrencies(giveCurrencyList),
            await this.getTopCurrencies(receiveCurrencyList)
        ]);
        this.otherGiveCurrencies = this.getOtherCurrencies(giveCurrencyList, this.mostPopularGiveCurrencies);
        this.otherReceiveCurrencies = this.getOtherCurrencies(receiveCurrencyList, this.mostPopularReceiveCurrencies);
    }

    async getTopCurrencies(currencyList: Currency[]) {
        const currenciesToTake = 3;
        const currenciesToSearch = currencyList.filter(c => c.type === CurrencyTypes.Crypto);
        if (!currenciesToSearch.length) return currencyList.slice(0, currenciesToTake);

        const currencyMap = currenciesToSearch.map(c => c.code);
        let mostPopularCurrencies: Currency[] = await this.currencyService.getMultipleCurrencyStatsByCode(currencyMap);

        if (!mostPopularCurrencies.length) mostPopularCurrencies = currencyList;
        mostPopularCurrencies = mostPopularCurrencies.slice(0, currenciesToTake);
        if (mostPopularCurrencies.length > 0)
            return currencyList.filter(c => mostPopularCurrencies.find(mc => mc.code === c.code));

        return currencyList.slice(0, currenciesToTake);
    }

    getOtherCurrencies(currencyList: Currency[], topCurrencies: Currency[]) {
        if (!topCurrencies?.length) return currencyList;
        currencyList = currencyList.filter(c => !topCurrencies.find(tc => tc.code === c.code));
        return currencyList.sort((a, b) => a.code.localeCompare(b.code));
    }

    async saveNuveiCreditCard(data: NuveiAddCreditCard) {
        return await this.nuveiService.addCreditCard(data);
    }

    checkIfUserSuspiciousForVeriff = async() => {
        if (this.helper.includesSome(this.exchange?.selectedPaymentMethod?.paymentMethod?.reference, ['crypto', 'coinpayments', 'btcpay', 'bitcart'])) return;
        if (this.billing?.street) {
            const geoCoder = new google.maps.Geocoder();
            let geoCoderAddress;
            try {
                geoCoderAddress = await geoCoder.geocode({ address: this.billing.street });
            } catch (e) {
                geoCoderAddress = await geoCoder.geocode({ address: `${this.billing.street} ${this.billing.zip}` });
            }
            this.geoCoderLatitude = geoCoderAddress.results[0].geometry.location.lat() ?? 0;
            this.geoCoderLongitude = geoCoderAddress.results[0].geometry.location.lng() ?? 0;
        }
        return (this.user?.phoneCountryFlag !== null && this.user?.phoneCountryFlag !== this.billing?.countryCode?.toLowerCase())
            || (this.billing?.street ? !isPointWithinRadius(
                {
                    latitude: this.sessionService?.geolocation?.latitude ?? 0,
                    longitude: this.sessionService?.geolocation?.longitude ?? 0
                },
                {
                    latitude: this.geoCoderLatitude,
                    longitude: this.geoCoderLongitude
                },
                200000
            ) : false);
    };

    couponTooltip() {
        return this.siteStringsValueConverter.toView('CX_COUPON_TOOLTIP', '', [this.exchange.validCoupon.code, this.exchange.validCoupon.value, this.exchange.validCoupon.type === 'Percent' ? '%' : '', this.exchange.validCoupon.minimumPurchase]);
    }

    @computedFrom('exchange.validCoupon', 'exchange.receiveSelectedCurrency', 'exchange.amountGifted', 'exchange.amountReceived')
    get couponDiscount() {
        if (!this.exchange.validCoupon) return '';

        const amountReceived = (this.exchange.amountGifted * this.exchange.currentRate) - this.exchange.transactionFee - this.exchange.deliveryFee;
        const couponPercentage = this.exchange.validCoupon.value / 100;
        let discount = amountReceived * couponPercentage;

        if (this.exchange.validCoupon.type === 'Value') {
            const usdExchangeRate = 1 / this.exchange.youReceiveRates['USD'];
            discount = this.exchange.validCoupon.value * usdExchangeRate;
        }

        if (this.exchange.receiveSelectedCurrency.type === CurrencyTypes.Crypto)
            return ` +${discount.toFixed(6)} ${this.exchange.receiveSelectedCurrency.symbol}`;
        else
            return ` + ${this.exchange.receiveSelectedCurrency.symbol}${discount.toFixed(2)}`;

    }

    get showStepNotes() {
        const { receivingPaymentMethod, selectedPaymentMethod } = this.exchange;
        return Boolean(this.helper.sanitizeHtml(receivingPaymentMethod.paymentStepNotes || selectedPaymentMethod.paymentStepNotes, true).length);
    }

    private processExternalPaymentProviderOrder(order: Order): void {
        const paymentTab = window.open(order.hostedUrl, '_blank');

        if (!paymentTab) {
            this.toastService.showToast('Error', 'Unable to open the payment window. Please check your browser settings.', 'error');
            return;
        }

        const navigateToOrderComplete = () => {
            this.router.navigate(`order-completed/${order.id}`);
        };

        const maxWaitTime = 10000;
        const navigationTimeout = setTimeout(() => {
            navigateToOrderComplete();
        }, maxWaitTime);

        const checkTabClosed = () => {
            if (paymentTab.closed) {
                clearTimeout(navigationTimeout);
                navigateToOrderComplete();
            } else {
                setTimeout(checkTabClosed, 500);
            }
        };

        // Start checking if the tab is closed
        checkTabClosed();
    }

    @computedFrom('exchange.selectedPaymentMethod', 'exchange.receivingPaymentMethod', 'exchange.giveSelectedCurrency', 'exchange.receiveSelectedCurrency')
    get paymentMethodToUse() {
        return this.exchange.giveSelectedCurrency.type === 'C' ? this.exchange.receivingPaymentMethod : this.exchange.selectedPaymentMethod;
    }
}
