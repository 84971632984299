import { computedFrom } from 'aurelia-framework';
import './balance.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { User } from 'services/models/user/user';
import { SessionService } from 'services/session-service';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { AdminViewUpdatedEvent, CurrencyChangedEvent } from 'resources/constants';
import { Helper } from 'resources/helpers/helper';
import { PageContentAreaService } from 'services/page-content-area-service';


@autoinject()
export class Balance {
    user: User;
    currencySubscription: Subscription;
    convertedBalance: string;
    contentLoading: boolean = true;
    pageLoadedSubscriber: Subscription;
    viewingAsAdmin: boolean = false;

    constructor(
        private helper: Helper,
        private sessionService: SessionService,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private eventAggregator: EventAggregator,
        private router: Router,
        private pageContentAreaService: PageContentAreaService
    ) { }

    async activate() {
        this.user = await this.sessionService.getProfile();
        this.convertedBalance = await this.currencyFormatValueConverter.toViewFiatOnly(this.user.balance);
    }

    attached() {
        try {
            this.helper.addLoadingComponent('balance', this);
            this.handleEventSubscriptions();
        } finally {
            this.helper.validateLoading('balance');
        }
    }

    redirectTo = (route: string) => {
        this.router.navigate(`balance/${route}`);
    };

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    handleEventSubscriptions() {
        const events = {
            'page-loaded': () => this.contentLoading = false,
            [AdminViewUpdatedEvent]: payload => this.viewingAsAdmin = payload.bool,
            [CurrencyChangedEvent]: async() => this.convertedBalance = await this.currencyFormatValueConverter.toViewFiatOnly(this.user.balance)
        };

        this.helper.subscribeEvents(this, events);
    }

    @computedFrom('convertedBalance', 'viewingAsAdmin')
    get historyDescription() {
        return `${this.pageContentAreaService.displaySiteString('CX_CURRENT_BALANCE')} ${this.convertedBalance}`;
    }
}
