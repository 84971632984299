import { PageContentAreaService } from 'services/page-content-area-service';
import './cx-form-get-started.scss';
import { SessionService } from 'services/session-service';
import { CoinbaseService } from 'services/coinbase-service';
import { ToastService } from 'services/toast-service';
import { CurrencyService } from 'services/currency-service';
import { autoinject, bindable, computedFrom, observable, TaskQueue } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    CouponCode,
    Currency,
    CurrencyDirection,
    CurrencyType,
    Exchange,
    PaymentMethodCurrencyFeeValuesResponse,
    PaymentMethodWebsite
} from 'services/models/purchase-flow/exchange';
import { CustomerService } from 'services/customer-service';
import { DefaultCurrencyValueConverter } from 'resources/value-converters/default-currency';
import { RateCurrencyFormatValueConverter } from 'resources/value-converters/rate-currency-formatter';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import numeral from 'numeral';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { Helper } from 'resources/helpers/helper';
import { DeliveryMethodService } from 'services/delivery-method-service';
import { DeliveryMethod } from 'services/models/purchase-flow/delivery-method';
import { ProductCategoryService } from 'services/product-category-service';
import { GameForNav } from 'services/models/game/productCategory';
import { DesktopBreakpoint, ForcePaymentMethodEvent } from 'resources/constants';
import { User } from 'services/models/user/user';
import { AuthenticationExtension } from 'resources/extensions/sso_extension';

@autoinject()
export class CxFormGetStarted extends AuthenticationExtension {
    constructor(
        sessionService: SessionService,
        private coinbaseService: CoinbaseService,
        private router: Router,
        private paymentMethodWebsiteService: PaymentMethodWebsiteService,
        private currencyService: CurrencyService,
        toastService: ToastService,
        private customerService: CustomerService,
        private defaultCurrencyValueConverter: DefaultCurrencyValueConverter,
        private rateCurrencyValueConverter: RateCurrencyFormatValueConverter,
        private eventAggregator: EventAggregator,
        private helper: Helper,
        private deliveryMethodService: DeliveryMethodService,
        private taskQueue: TaskQueue,
        private productCategoryService: ProductCategoryService,
        private pageContentAreaService: PageContentAreaService
    ) {
        super(toastService, sessionService);
    }

    @bindable timeLeft: number;
    @bindable selectedPaymentMethod: PaymentMethodWebsite;
    @bindable preSelectedGiveCurrency: string;
    @bindable preSelectedReceiveCurrency: string;
    @bindable exchangeType: 'B' | 'S' | 'FF' | 'CC' = 'B';

    user: User;
    giveCurrencyOptions: Currency[];
    receiveCurrencyOptions: Currency[];
    @observable giveSelectedCurrency: Currency;
    @observable receiveSelectedCurrency: Currency;
    youGiveRates: { [key: string]: number; };
    youReceiveRates: { [key: string]: number; };
    currentRate: number;
    usdRates: { [key: string]: number; };
    amountGifted = 0;
    amountGiftedDefault = 0;
    amountReceived = 0;
    amountReceivedDefault = 0;
    limiter: number;
    transactionFeeBeforeRateApplied: number;
    deliveryFeeBeforeRateApplied: number;
    feePercentageToUse?: PaymentMethodCurrencyFeeValuesResponse;
    transactionFee: number;
    deliveryFee: number;
    currencyList: Currency[];
    cryptoList: Currency[];
    coinpaymentList: Currency[];
    cryptoMethodsList: Currency[];
    maximumAmount: number;
    minimumAmount: number;
    amountTimeout: NodeJS.Timeout;
    timer;
    tooltipTimeout: NodeJS.Timeout;
    receivingPaymentMethod: PaymentMethodWebsite;
    customerCountry: string;
    forceCurrencySubscriber;
    selectedPaymentMethodObject: PaymentMethodWebsite;
    activeCurrencies: Currency[];
    preferredCurrency: Currency;
    toastSent = false;
    isAttached = false;
    exchangeBtnEnable = false;
    skeletonIsShown = true;
    parent;
    forcePaymentMethodSubscriber: Subscription;
    cxAmountPaymentSelectorEl;
    deliveryMethods: DeliveryMethod[];
    autoRestart: boolean = true;
    tradeDataType: string;
    game: GameForNav;
    tradeParamsSubscriber;
    routeChangeSubscriber;
    pageLoadedSubscriber;

    exchangeRate: string;
    exchangeSubType: 'FF' | 'CC' = 'FF';
    showExchangeSubOptions: boolean = false;
    exchangeTypeClicked = false;

    validCoupon: CouponCode;
    couponOpen = false;
    appliedDiscount: string;
    couponInputViewModel;

    previousGiveCurrency: Currency;
    previousReceiveCurrency: Currency;
    isTimerRestarting: boolean = false;

    currentIntent: 'give' | 'receive' = 'give';

    exchangeOptions = [
        { value: 'B', label: 'Buy', icon: 'shopping_cart' },
        { value: 'S', label: 'Sell', icon: 'sell' },
        { value: 'FF', label: 'Swap', icon: 'swap_horizontal_circle' }
    ];

    exchangeOptionsConverter = {
        B: 'Buy',
        S: 'Sell',
        FF: 'Swap'
    };

    exchangeSubOptions = [
        { value: 'FF', label: 'Fiat' },
        { value: 'CC', label: 'Crypto' }
    ];

    exchangeOptionsForTabletAndMobile = [
        { value: 'B', label: 'Buy Crypto', icon: 'shopping_cart' },
        { value: 'S', label: 'Sell Crypto', icon: 'sell' },
        { value: 'CC', label: 'Swap Crypto', icon: 'swap_horizontal_circle' },
        { value: 'FF', label: 'Swap Fiat', icon: 'swap_horizontal_circle' }
    ];

    exchangeBtnAnimated: HTMLDivElement;
    animationRunning: boolean = false;

    baseUrl: string;
    isLoading: boolean = true;
    width: number;
    sizeChangeSubcription: Subscription;
    currentRouteFragment: 'buy' | 'sell' | 'swap/fiat' | 'swap/crypto' | string = 'buy';
    currentLanguage;
    isBusy: boolean;
    isChangingCurrency: boolean;
    orderFromNavbarExecuting: boolean;

    bind(bindingContext) {
        this.parent = bindingContext;
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.sizeChangeSubcription = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
        });
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('form-get-started');
            this.user = await this.sessionService.getProfile();
            await this.sessionService.getIpInformation();
            this.activeCurrencies = await this.currencyService.getActiveCurrenciesByWebsite();
            this.usdRates = await this.coinbaseService.getJustExchangesRates('USD');
            this.currencyList = this.activeCurrencies.filter(e => e.type === 'F');
            this.cryptoList = this.activeCurrencies.filter(e => e.type === 'C');
            this.cryptoMethodsList = await this.currencyService.getCryptoList();
            this.deliveryMethods = await this.deliveryMethodService.getAllDeliveryMethods();
            this.currentLanguage = await this.sessionService.getLanguage();

            const routeSlug = window.location.pathname.split('/').pop();
            const games = (await this.productCategoryService.getNavCategory('trade'))?.gameForNav;
            this.game = games?.find((game) => game.slug?.includes(routeSlug));
            const preSelectedGiveCurrency = this.getCurrency(this.game?.giveExchangeCurrencyId);
            const preSelectedReceiveCurrency = this.getCurrency(this.game?.currencyId) || this.getCurrency(this.game?.receiveExchangeCurrencyId);

            const { give, receive } = await this.getGiveAndReceiveCurrenciesFromUrl();
            const exchangeType = this.getExchangeTypeFromUrl();
            const method = this.sessionService.getSelectedPaymentMethod();

            if (exchangeType) this.exchangeType = exchangeType;
            const preSelectedRepeated = give === receive;
            const preSelectedGive = !preSelectedRepeated ? this.getCurrency(give?.code ?? '') : null;
            const preSelectedReceive = !preSelectedRepeated ? this.getCurrency(receive?.code ?? '') : null;

            const previousGiveCurrency = this.activeCurrencies.find(c => c.code === this.sessionService.getGiveCurrency());
            const previousReceiveCurrency = this.activeCurrencies.find(c => c.code === this.sessionService.getReceiveCurrency());

            this.customerCountry = this.sessionService.getCountry();
            this.preferredCurrency = this.activeCurrencies.find(c => c.code === this.defaultCurrencyValueConverter.toView(this.customerCountry));
            this.cryptoMethodsList = this.removeRestrictedCurrencies(this.cryptoMethodsList, this.customerCountry);
            this.cryptoList = this.removeRestrictedCurrencies(this.cryptoList, this.customerCountry);
            this.activeCurrencies = this.removeRestrictedCurrencies(this.activeCurrencies, this.customerCountry);

            if (exchangeType) {
                this.setCurrenciesBasedPaymentMethod();

                this.giveSelectedCurrency = this.giveCurrencyOptions.some(o => o.code === preSelectedGive?.code) ? preSelectedGive : undefined;
                this.receiveSelectedCurrency = this.receiveCurrencyOptions.some(o => o.code === preSelectedReceive?.code) ? preSelectedReceive : undefined;

                this.showExchangeSubOptions = ['FF', 'CC'].includes(this.exchangeType);
            } else {
                this.giveSelectedCurrency = preSelectedGive || preSelectedGiveCurrency || previousGiveCurrency || undefined;
                this.receiveSelectedCurrency = preSelectedReceive || preSelectedReceiveCurrency || previousReceiveCurrency || undefined;

                if (!preSelectedGive && (preSelectedReceive || preSelectedReceiveCurrency || previousReceiveCurrency)?.type === 'C' && this.giveSelectedCurrency?.type === 'C' && (!previousGiveCurrency && preSelectedGive)) {
                    this.giveSelectedCurrency = this.preferredCurrency;
                } else if (!preSelectedReceive && (preSelectedGive || preSelectedGiveCurrency || previousGiveCurrency)?.type === 'C' && this.receiveSelectedCurrency?.type === 'C' && (!previousReceiveCurrency && preSelectedReceive)) {
                    this.receiveSelectedCurrency = this.preferredCurrency;
                }

                if (this.game?.name.startsWith('Sell')) {
                    const auxGiveCurrency = this.giveSelectedCurrency;
                    this.giveSelectedCurrency = this.receiveSelectedCurrency;
                    this.receiveSelectedCurrency = auxGiveCurrency;
                }

                this.exchangeType = this.getExchangeType(this.giveSelectedCurrency?.type, this.receiveSelectedCurrency?.type);
                this.showExchangeSubOptions = ['FF', 'CC'].includes(this.exchangeType);
                this.setCurrenciesBasedPaymentMethod();
            }

            this.handleEventSubscriptions();

            //TODO update the logic so USD is readed to the list when CAD is selected as reveive currency
            // this.receiveCurrencyOptions = this.cryptoList.filter(e => e.id !== this.preferredCurrency.id);
            this.taskQueue.queueTask(async () => {
                await this.fetchPairRates();
                await this.setCurrentRate();
                await this.setCharacterLimiter();
                this.isAttached = true;
                this.amountGiftedDefault = 100;
                if (this.selectedPaymentMethod) {
                    if (this.giveSelectedCurrency?.code !== 'USD') {
                        this.amountGifted = this.amountGiftedDefault = 100 * this.usdRates[this.giveSelectedCurrency?.code];
                    }
                    if (this.giveSelectedCurrency?.code !== 'USD') {
                        this.maximumAmount = +(this.selectedPaymentMethod?.maximum * this.usdRates[this.giveSelectedCurrency?.code]).toFixed(2);
                        this.minimumAmount = +(this.selectedPaymentMethod?.minimum * this.usdRates[this.giveSelectedCurrency?.code]).toFixed(2);
                    } else {
                        this.maximumAmount = this.selectedPaymentMethod?.maximum;
                        this.minimumAmount = this.selectedPaymentMethod?.minimum;
                    }
                }

                await this.focusOutCheckValid();

                const currentFragmentHasParams = ['/buy', '/sell', '/swap/crypto', '/swap/fiat'].every(slug => this.router.baseUrl !== slug);
                if (!this.giveSelectedCurrency && currentFragmentHasParams && this.exchangeType === 'B')
                    this.giveSelectedCurrency = this.preferredCurrency || this.giveCurrencyOptions[0];
                if (!this.receiveSelectedCurrency && currentFragmentHasParams && this.exchangeType === 'S')
                    this.receiveSelectedCurrency = this.preferredCurrency || this.receiveCurrencyOptions[0];

                const preSelectedPaymentMethod = (await this.sessionService.getExchange())?.receivingPaymentMethod?.paymentMethod?.reference;
                const forcedPaymentMethod = (exchangeType === 'FF' || (this.giveSelectedCurrency?.type === 'F' && this.receiveSelectedCurrency?.type === 'F')) ? 'cash-in-person' :
                    (exchangeType === 'CC' || (this.giveSelectedCurrency?.type === 'C' && this.receiveSelectedCurrency?.type === 'C')) ? 'crypto-to-crypto' : method;
                if (forcedPaymentMethod) {
                    this.selectedPaymentMethod = await this.paymentMethodWebsiteService.getByReference(forcedPaymentMethod);
                    this.eventAggregator.publish(ForcePaymentMethodEvent, { paymentMethodReference: forcedPaymentMethod });
                } else if (preSelectedPaymentMethod) {
                    this.selectedPaymentMethod = await this.paymentMethodWebsiteService.getByReference(preSelectedPaymentMethod);
                    if (this.selectedPaymentMethod.paymentMethod.reference === 'crypto-to-crypto')
                        await this.changeExchangeType('CC');
                }
                this.isAttached = false;
            });

            const resizeObserver = new ResizeObserver(() => {
                this.moveButtonExchangeSelector(this.exchangeType, false);
            });
            resizeObserver.observe(document.getElementById('id-exchange-selector'));
        } finally {
            this.helper.validateLoading('form-get-started');
            this.moveButtonExchangeSelector(this.exchangeType, false);
            this.sessionService.destroySelectedPaymentMethod();
        }

    }

    async getGiveAndReceiveCurrenciesFromUrl() {
        const fragment = this.removeLanguageFragment(this.router.currentInstruction.parentInstruction.fragment);
        const regex = /([a-zA-Z]+)-to-([a-zA-Z]+)/;
        const match = fragment.match(regex);
        if (this.router.currentInstruction.queryString.length) {
            let give;
            let receive;
            if (this.router.currentInstruction.queryParams.give)
                give = this.activeCurrencies.find(c => c.code.toLowerCase() === this.router.currentInstruction.queryParams.give.toLowerCase());
            if (this.router.currentInstruction.queryParams.receive)
                receive = this.activeCurrencies.find(c => c.code.toLowerCase() === this.router.currentInstruction.queryParams.receive.toLowerCase());
            if (this.router.currentInstruction.queryParams.method)
                this.selectedPaymentMethod = await this.paymentMethodWebsiteService.getByReference(this.router.currentInstruction.queryParams.method);
            return { give: give ?? null, receive: receive ?? null };
        }
        if (match) {
            const give = this.activeCurrencies.find(c => c.code.toLowerCase() === match[1]);
            const receive = this.activeCurrencies.find(c => c.code.toLowerCase() === match[2]);
            return { give: give ?? null, receive: receive ?? null };
        }
        else if (fragment.startsWith('/buy/')) {
            const receive = this.activeCurrencies.find(c => c.code.toLowerCase() === fragment.split('/')[2]);
            return { give: null, receive: receive ?? null };
        }
        else if (fragment.startsWith('/sell/')) {
            const give = this.activeCurrencies.find(c => c.code.toLowerCase() === fragment.split('/')[2]);
            return { give: give ?? null, receive: null };
        } else if (fragment.startsWith('/swap/')) {
            const give = this.activeCurrencies.find(c => c.code.toLowerCase() === fragment.split('/')[3]);
            return { give: give || null, receive: null };
        } else if (fragment.startsWith('/trade/')) {
            let give;
            let receive;
            const buyRegex = /buy-([a-zA-Z0-9]+)/;
            const sellRegex = /sell-([a-zA-Z0-9]+)/;
            const buyRegexMatch = fragment.match(buyRegex);
            const sellRegexMatch = fragment.match(sellRegex);
            if (buyRegexMatch) {
                receive = this.activeCurrencies.find(c => c.code.toLowerCase() === fragment.split('-')[1].toLowerCase());
                if (fragment.toLowerCase().includes('canada')) {
                    give = this.activeCurrencies.find(c => c.code.toLowerCase() === 'cad');
                }
            }
            if (sellRegexMatch) {
                give = this.activeCurrencies.find(c => c.code.toLowerCase() === fragment.split('-')[1].toLowerCase());
                if (fragment.toLowerCase().includes('canada')) {
                    receive = this.activeCurrencies.find(c => c.code.toLowerCase() === 'cad');
                }
            }
            return { give: give ?? null, receive: receive ?? null };
        }
        return { give: null, receive: null };
    }

    private removeLanguageFragment(url) {
        const match = url.match(/^\/([a-z]{2})\/(.+)/);
        if (match)
            return '/' + match[2];
        return url;
    }

    getExchangeTypeFromUrl(): 'B' | 'S' | 'FF' | 'CC' {
        const fragment = this.router.currentInstruction.parentInstruction.fragment;
        if (fragment.includes('buy'))
            return 'B';
        else if (fragment.includes('sell'))
            return 'S';
        else if (fragment.includes('swap/crypto'))
            return 'CC';
        else if (fragment.includes('swap/fiat'))
            return 'FF';
    }

    handleEventSubscriptions() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.isLoading = false;
        });

        this.forceCurrencySubscriber = this.eventAggregator.subscribe('force-currency', async payload => {
            if (payload.currentPaymentMethodSelected !== null && this.selectedPaymentMethodObject !== payload.currentPaymentMethodSelected) {
                this.selectedPaymentMethodObject = payload.currentPaymentMethodSelected;
                this.setCurrenciesBasedPaymentMethod();
                const currencyOption = this.activeCurrencies.find(currency => currency.code === payload.currency);
                await this.setCurrency(currencyOption?.code, payload.oldCurrency, this.giveCurrencyOptions.every(c => c.type === CurrencyType.Fiat) ? 'give' : 'receive');
            }
        });

        this.forcePaymentMethodSubscriber = this.eventAggregator.subscribe(ForcePaymentMethodEvent, async payload => {
            if (payload.paymentMethodReference) {
                await this.focusOutCheckValid();
            }
        });

        this.tradeParamsSubscriber = this.eventAggregator.subscribe('new-order-from-navbar', payload => {
            this.orderFromNavbarExecuting = true;
            this.exchangeType = payload.exchangeType;
            const giveCurrency = this.getCurrency(payload.give);
            const receiveCurrency = this.getCurrency(payload.receive);

            this.showExchangeSubOptions = payload.give && payload.receive;

            if (payload.give && payload.receive) {
                this.exchangeType = this.getExchangeType(giveCurrency.type, receiveCurrency.type);
                this.eventAggregator.publish(ForcePaymentMethodEvent, { paymentMethodReference: this.exchangeType === 'FF' ? 'cash-in-person' : 'crypto-to-crypto' });
            }

            if (payload.give) this.giveSelectedCurrency = giveCurrency;
            if (payload.receive) this.receiveSelectedCurrency = receiveCurrency;

            if (this.exchangeType !== 'CC' && this.exchangeType !== 'FF') {
                if (receiveCurrency?.type === 'C' && this.giveSelectedCurrency?.type === 'C') this.giveSelectedCurrency = this.preferredCurrency;
                if (giveCurrency?.type === 'C' && this.receiveSelectedCurrency?.type === 'C') this.receiveSelectedCurrency = this.preferredCurrency;
            }

            this.exchangeType = this.getExchangeType(this.giveSelectedCurrency?.type, this.receiveSelectedCurrency?.type);
            this.changeExchangeType(this.exchangeType);
            if (payload.method) this.eventAggregator.publish(ForcePaymentMethodEvent, { paymentMethodReference: payload.method });
            this.setCurrenciesBasedPaymentMethod();
            this.orderFromNavbarExecuting = false;
        });

        this.routeChangeSubscriber = this.eventAggregator.subscribe('router:navigation:success', async () => {
            const { give, receive } = await this.getGiveAndReceiveCurrenciesFromUrl();
            const exchangeType = this.getExchangeTypeFromUrl();
            if (give && receive) {
                this.giveSelectedCurrency = this.getCurrency(give?.code ?? '');
                this.receiveSelectedCurrency = this.getCurrency(receive?.code ?? '');
                await this.focusOutCheckValid();
            } else if (exchangeType === 'B' && !give && receive && !this.orderFromNavbarExecuting) {
                this.giveSelectedCurrency = this.giveSelectedCurrency || this.preferredCurrency || this.giveCurrencyOptions[0];
                this.receiveSelectedCurrency = this.getCurrency(receive?.code || '');
            } else if (give && !receive && !this.orderFromNavbarExecuting) {
                this.receiveSelectedCurrency = exchangeType !== 'CC' ? this.preferredCurrency :
                    this.receiveSelectedCurrency?.type === 'C' ? this.receiveSelectedCurrency : null;
                this.giveSelectedCurrency = this.getCurrency(give?.code || '');
                if (!this.receiveSelectedCurrency) this.amountReceived = this.amountReceivedDefault = 0;
                await this.focusOutCheckValid();
            }
            if (exchangeType) this.exchangeType = exchangeType;
            this.changeExchangeType(this.exchangeType);
            this.sessionService.destroySelectedPaymentMethod();
        });
    }

    setCurrenciesBasedPaymentMethod() {
        if (this.exchangeType === 'B') {
            this.giveCurrencyOptions = [...this.currencyList];
            this.receiveCurrencyOptions = [...this.cryptoList];
        } else if (this.exchangeType === 'S') {
            this.giveCurrencyOptions = [...this.cryptoMethodsList];
            this.receiveCurrencyOptions = [...this.currencyList];
        } else if (this.exchangeType === 'FF') {
            this.giveCurrencyOptions = [...this.currencyList];
            this.receiveCurrencyOptions = [...this.currencyList];
        } else if (this.exchangeType === 'CC') {
            this.giveCurrencyOptions = [...this.cryptoList];
            this.receiveCurrencyOptions = [...this.cryptoList];
        }
    }

    async setCurrency(currency: string, oldCurrency: string | Currency, intent: 'give' | 'receive'): Promise<string> {
        let correctCurrency = '';
        if (oldCurrency && typeof oldCurrency === 'object') oldCurrency = oldCurrency.code;
        if (this.selectedPaymentMethodObject?.paymentMethod) {
            if (this.selectedPaymentMethodObject.supportedCurrencies?.length) {
                const currencies = this.selectedPaymentMethodObject.supportedCurrencies.map(c => this.activeCurrencies.find(ac => ac.id === c.currencyId)?.code);
                if (!currencies.includes(oldCurrency as string)) {
                    currency = currencies[0];
                    correctCurrency = currencies.join(', ');
                } else {
                    currency = currencies.includes(this.preferredCurrency.code) ? this.preferredCurrency.code : oldCurrency as string;
                }
            }
        }
        if (correctCurrency !== '' && !this.toastSent && correctCurrency !== oldCurrency) {
            this.toastSent = true;
            await this.toastService.showToast('Info', `${this.selectedPaymentMethodObject?.paymentMethod.name} can only be paid in ${correctCurrency}. We've changed the checkout currency to reflect your payment method selection.`, 'info');
            if (intent.includes('give')) {
                this.previousGiveCurrency = { ...this.giveSelectedCurrency };
                this.giveSelectedCurrency = this.giveCurrencyOptions?.find(currencyItem => currencyItem.code === currency);
            } else {
                this.previousReceiveCurrency = { ...this.receiveSelectedCurrency };
                this.receiveSelectedCurrency = this.receiveCurrencyOptions?.find(currencyItem => currencyItem.code === currency);
            }
            setTimeout(() => {
                this.toastSent = false;
            }, 500);
        } else {
            if (this.timer && correctCurrency === '') {
                this.timer.au.controller.viewModel.resetCountdown();
            }
        }
        if (intent === 'give') return this.giveSelectedCurrency?.code;
        return this.receiveSelectedCurrency?.code;
    }

    async fetchPairRates() {
        const parsedReceiveSelectedCurrencyCode = this.receiveSelectedCurrency?.code.split(' ').join('-');
        const parsedGiveSelectedCurrencyCode = this.giveSelectedCurrency?.code.split(' ').join('-');
        if (this.giveSelectedCurrency && this.receiveSelectedCurrency) {
            this.youGiveRates = await this.coinbaseService.getJustExchangesRates(parsedGiveSelectedCurrencyCode);
            this.youReceiveRates = await this.coinbaseService.getJustExchangesRates(parsedReceiveSelectedCurrencyCode);
        }

        if ((this.giveSelectedCurrency?.type === 'F' && this.receiveSelectedCurrency?.type === 'C') || (this.giveSelectedCurrency?.type === 'C' && this.receiveSelectedCurrency?.type === 'F')) {
            const fiatToSpreadFee = this.giveSelectedCurrency?.type === 'F' ? parsedGiveSelectedCurrencyCode : parsedReceiveSelectedCurrencyCode;
            const cryptoToSpreadFee = this.giveSelectedCurrency?.type === 'F' ? this.receiveSelectedCurrency.reference : this.giveSelectedCurrency.reference;

            const fiatSpreadFee = (await this.currencyService.getSpreadFeeByCurrencyCodeAndWebsite(fiatToSpreadFee, 'CX'))?.spreadFee ?? 0;
            const cryptoSpreadFee = (await this.paymentMethodWebsiteService.getByReference(cryptoToSpreadFee))?.spreadFee ?? 0;
            const totalSpreadFee = fiatSpreadFee + cryptoSpreadFee;
            this.currentRate = this.helper.calculateSpreadFee(totalSpreadFee, this.youGiveRates[parsedReceiveSelectedCurrencyCode.split(' ').join('-')]);
        }

        this.setExchangeRates();
        setTimeout(() => {
            this.skeletonIsShown = false;
            this.enableBtnExchageNow();
        }, 500);
    }

    setExchangeRates() {
        if (this.receiveSelectedCurrency?.type === 'F') {
            this.exchangeRate = `1 ${this.giveSelectedCurrency?.code} = ${this.receiveSelectedCurrency?.symbol}${numeral(this.currentRate).format('(0,0.00)')}`;
        }
        if (this.receiveSelectedCurrency?.type === 'C') {
            this.exchangeRate = `${this.giveSelectedCurrency?.symbol}${numeral(1 / this.currentRate).format('(0,0.00)')} = 1 ${this.receiveSelectedCurrency.code}`;
        }

        if (this.giveSelectedCurrency?.type === 'C' && this.receiveSelectedCurrency?.type === 'C') {
            this.exchangeRate = `${numeral(1 / this.currentRate).format('(0,0.00[0000])')} ${this.giveSelectedCurrency.symbol} = 1 ${this.receiveSelectedCurrency.code}`;
        }
    }

    transformUsdToReceiveCurrency(usdAmount: number) {
        if (!this.youReceiveRates) {
            return usdAmount;
        }

        const exchangeRate = 1 / this.youReceiveRates['USD'];
        return usdAmount * exchangeRate;
    }

    /**
     * Validates if there is amount set and if it is in line with the maximum and minimum of payment method
     */
    async focusOutCheckValid(intent: 'give' | 'receive' = 'give') {
        if (!this.giveSelectedCurrency && !this.receiveSelectedCurrency) return;
        if (this.amountGifted || this.amountReceived) await this.setExchangeValues(intent, true);
        await this.validateAmount();

        if (this.validCoupon) {
            setTimeout(() => {
                this.couponInputViewModel.calculateCouponDiscount();
            }, 0);
        }

        // Manual save of the receiving method before switching it to crypto
        this.receivingPaymentMethod = this.selectedPaymentMethod;
    }

    activeSkeleton() {
        this.skeletonIsShown = true;
    }

    /**
     * Validates amount and toast based on minimum and maximum of selected payment method
     */
    async validateAmount() {
        if ((!this.selectedPaymentMethod?.minimum && !this.selectedPaymentMethod?.maximum) || (!this.giveSelectedCurrency || !this.receiveSelectedCurrency)) {
            return;
        }
        const toFixedAmount = this.giveSelectedCurrency.type === 'F' || this.giveSelectedCurrency.isStable ? 2 : 6;
        const delta = this.giveSelectedCurrency.type === 'C' ? 0.1 : 0;
        clearTimeout(this.amountTimeout);
        this.amountTimeout = setTimeout(async () => {
            const amountInUsd = await this.getAmountInCurrency(this.amountGifted);
            if (this.selectedPaymentMethod?.minimum > 0 && +amountInUsd + delta < this.selectedPaymentMethod.minimum) {
                const auxAmount = +(this.selectedPaymentMethod.minimum * this.usdRates[this.giveSelectedCurrency.code]).toFixed(toFixedAmount);
                this.amountGifted = auxAmount;
                await this.setExchangeValues('give', true);
            }
            if (this.selectedPaymentMethod?.maximum && +amountInUsd > this.selectedPaymentMethod.maximum) {
                const auxAmount = +(this.selectedPaymentMethod.maximum * this.usdRates[this.giveSelectedCurrency.code]).toFixed(toFixedAmount);
                this.amountGifted = auxAmount;
                await this.setExchangeValues('give', true);
            }
            this.enableBtnExchageNow();
        }, 1000);
    }

    async changeExchangeType(type: 'B' | 'S' | 'FF' | 'CC') {
        this.exchangeBtnEnable = false;
        if (this.exchangeTypeClicked)
            return;
        this.exchangeTypeClicked = true;
        const oldExchangeType = this.exchangeType;
        this.exchangeType = type;
        if ((oldExchangeType === 'B' && type === 'S' && this.giveSelectedCurrency?.type === 'F') || (oldExchangeType === 'S' && type === 'B' && this.giveSelectedCurrency?.type === 'C')) {
            await this.invertCurrencies(true);
        }
        if (['FF', 'CC'].includes(oldExchangeType) && ['B', 'S'].includes(type)) {
            this.cxAmountPaymentSelectorEl.au.controller.viewModel.resetPaymentMethod();
        }
        if (type === 'FF') {
            this.showExchangeSubOptions = true;
            this.giveCurrencyOptions = [...this.currencyList];
            this.receiveCurrencyOptions = [...this.currencyList];
            this.eventAggregator.publish(ForcePaymentMethodEvent, { paymentMethodReference: 'cash-in-person' });
            setTimeout(async () => {
                if (this.giveSelectedCurrency?.type !== 'F') {
                    this.giveCurrencyOptions = this.giveCurrencyOptions.filter(c => c.code !== this.receiveSelectedCurrency?.code);
                    this.previousGiveCurrency = { ...this.giveSelectedCurrency };
                    this.giveSelectedCurrency = this.giveCurrencyOptions[0];
                    this.setAmount(this.amountGifted, 'give', this.previousGiveCurrency, this.giveSelectedCurrency);
                }
                if (this.receiveSelectedCurrency?.type !== 'F') {
                    this.receiveCurrencyOptions = this.receiveCurrencyOptions.filter(c => c.code !== this.giveSelectedCurrency.code);
                    this.previousReceiveCurrency = { ...this.receiveSelectedCurrency };
                    this.receiveSelectedCurrency = this.receiveCurrencyOptions[0];
                    this.setAmount(this.amountReceived, 'receive', this.previousReceiveCurrency, this.receiveSelectedCurrency);
                }
            }, 0);
        } else if (type === 'CC') {
            this.showExchangeSubOptions = true;
            this.giveCurrencyOptions = [...this.cryptoList];
            this.receiveCurrencyOptions = [...this.cryptoList];
            this.eventAggregator.publish(ForcePaymentMethodEvent, { paymentMethodReference: 'crypto-to-crypto' });
            setTimeout(async () => {
                if (this.giveSelectedCurrency?.type !== 'C') {
                    this.giveCurrencyOptions = this.giveCurrencyOptions.filter(c => c.code !== this.receiveSelectedCurrency?.code);
                    this.previousGiveCurrency = { ...this.giveSelectedCurrency };
                    this.giveSelectedCurrency = this.giveCurrencyOptions[0];
                    this.setAmount(this.amountGifted, 'give', this.previousGiveCurrency, this.giveSelectedCurrency);
                }
                if (this.receiveSelectedCurrency?.type !== 'C') {
                    this.receiveCurrencyOptions = this.receiveCurrencyOptions.filter(c => c.code !== this.giveSelectedCurrency?.code);
                    this.previousReceiveCurrency = { ...this.receiveSelectedCurrency };
                    this.receiveSelectedCurrency = this.receiveCurrencyOptions[0];
                    this.setAmount(this.amountReceived, 'receive', this.previousReceiveCurrency, this.receiveSelectedCurrency);
                }
            }, 0);
        } else {
            this.showExchangeSubOptions = false;
            if (type === 'B') {
                this.giveCurrencyOptions = [...this.currencyList];
                this.receiveCurrencyOptions = [...this.cryptoList];
            } else if (type === 'S') {
                this.giveCurrencyOptions = [...this.cryptoList];
                this.receiveCurrencyOptions = [...this.currencyList];
            }
            if (this.giveSelectedCurrency?.type !== this.giveCurrencyOptions[0]?.type) {
                this.previousGiveCurrency = { ...this.giveSelectedCurrency };
                this.giveSelectedCurrency = this.giveCurrencyOptions[0];
                this.setAmount(this.amountGifted, 'give', this.previousGiveCurrency, this.giveSelectedCurrency);
            }
            if (this.receiveSelectedCurrency?.type !== this.receiveCurrencyOptions[0]?.type) {
                this.previousReceiveCurrency = { ...this.receiveSelectedCurrency };
                this.receiveSelectedCurrency = this.receiveCurrencyOptions[0];
                this.setAmount(this.amountReceived, 'receive', this.previousReceiveCurrency, this.receiveSelectedCurrency);
            }
        }

        this.moveButtonExchangeSelector(type || 'B');
        setTimeout(async () => {
            await this.countdownFinished();
            this.exchangeTypeClicked = false;
        }, 1000);
    }

    /**
     * Invert the Currencies
     */
    async invertCurrencies(ignoreSwap = false) {
        if (!this.giveSelectedCurrency || !this.receiveSelectedCurrency) return;
        this.activeSkeleton();

        if (!ignoreSwap && this.exchangeType === 'B') {
            this.exchangeType = 'S';
        } else if (!ignoreSwap && this.exchangeType === 'S') {
            this.exchangeType = 'B';
        }

        if (['S', 'B'].includes(this.exchangeType)) this.moveButtonExchangeSelector(this.exchangeType);

        //Invert currencies lists
        const auxgiveCurrencyOptions = this.giveCurrencyOptions;
        this.giveCurrencyOptions = this.receiveCurrencyOptions;
        this.receiveCurrencyOptions = auxgiveCurrencyOptions;

        //Invert Selected Values
        const auxGiveSelected = this.giveSelectedCurrency;
        this.giveSelectedCurrency = this.receiveSelectedCurrency;
        this.receiveSelectedCurrency = auxGiveSelected;

        //Invert Rates
        const auxYouGiveRates = this.youGiveRates;
        this.youGiveRates = this.youReceiveRates;
        this.youReceiveRates = auxYouGiveRates;

        await this.setCurrentRate();

        if (this.giveCurrencyOptions.every(c => c.type === 'C')) {
            this.giveCurrencyOptions = [...this.cryptoMethodsList];
            this.previousGiveCurrency = { ...this.giveSelectedCurrency };
            const option = this.giveCurrencyOptions.find(currency => currency.code === this.giveSelectedCurrency.code);
            if (option) {
                this.giveSelectedCurrency = option;
            } else {
                this.giveSelectedCurrency = this.giveCurrencyOptions[0];
            }
        }

        if (this.receiveCurrencyOptions.every(c => c.type === 'C')) {
            this.receiveCurrencyOptions = this.cryptoList;
            this.previousReceiveCurrency = { ...this.receiveSelectedCurrency };
            const option = this.receiveCurrencyOptions.find(currency => currency.code === this.receiveSelectedCurrency.code);
            if (option) {
                this.receiveSelectedCurrency = option;
            } else {
                this.receiveSelectedCurrency = this.receiveCurrencyOptions[0];
            }
            this.setAmount(this.amountReceived, 'receive', this.previousReceiveCurrency, this.receiveSelectedCurrency);
        }

        //Invert Totals taking in consideration the transaction
        this.setAmount(this.amountGifted, 'give', this.receiveSelectedCurrency, this.giveSelectedCurrency);

        this.timer?.au.controller.viewModel.resetCountdown();

        if (this.selectedPaymentMethod &&
            ((this.giveSelectedCurrency.type === 'C' && !this.selectedPaymentMethod.payoutable) ||
                (this.giveSelectedCurrency.type === 'F' && !this.selectedPaymentMethod.live))
        ) {
            this.cxAmountPaymentSelectorEl.au.controller.viewModel.resetPaymentMethod();
        }
    }

    async handlePaymentMethodSwitch() {
        await this.focusOutCheckValid();
    }

    /**
     * Set Current rate, in case it doesnt exist it will use the contrary rate and if not it will trow an error
     */
    async setCurrentRate() {
        if (!this.giveSelectedCurrency || !this.receiveSelectedCurrency) return;
        const parsedReceiveSelectedCurrencyCode = this.receiveSelectedCurrency.code.split(' ').join('-');
        const parsedGiveSelectedCurrencyCode = this.giveSelectedCurrency.code.split(' ').join('-');
        this.currentRate = this.youGiveRates[parsedReceiveSelectedCurrencyCode];
        if (!this.currentRate) {
            //Request the exchange rates with extra param
            this.youGiveRates = await this.coinbaseService.getJustExchangesRatesWithInvertedDesiredCurrency(parsedGiveSelectedCurrencyCode, parsedReceiveSelectedCurrencyCode);
            // Validate that there are rates for the coin
            if (!this.youGiveRates) {
                if (!this.usdRates[this.giveSelectedCurrency.code]) {
                    await this.resetCurrencyNotAvailable(CurrencyDirection.Give, this.giveSelectedCurrency);
                }
                if (!this.usdRates[this.receiveSelectedCurrency.code]) {
                    await this.resetCurrencyNotAvailable(CurrencyDirection.Receive, this.receiveSelectedCurrency);
                }
                return;
            }
            this.currentRate = this.youGiveRates[parsedReceiveSelectedCurrencyCode];
        }

        if ((this.giveSelectedCurrency?.type === 'F' && this.receiveSelectedCurrency?.type === 'C') || (this.giveSelectedCurrency?.type === 'C' && this.receiveSelectedCurrency?.type === 'F')) {
            const fiatToSpreadFee = this.giveSelectedCurrency?.type === 'F' ? this.giveSelectedCurrency.code : this.receiveSelectedCurrency.code;
            const cryptoToSpreadFee = this.giveSelectedCurrency?.type === 'F' ? this.receiveSelectedCurrency.reference : this.giveSelectedCurrency.reference;

            const fiatSpreadFee = (await this.currencyService.getSpreadFeeByCurrencyCodeAndWebsite(fiatToSpreadFee, 'CX'))?.spreadFee ?? 0;
            const cryptoSpreadFee = (await this.paymentMethodWebsiteService.getByReference(cryptoToSpreadFee))?.spreadFee ?? 0;
            const totalSpreadFee = fiatSpreadFee + cryptoSpreadFee;
            this.currentRate = this.helper.calculateSpreadFee(totalSpreadFee, this.youGiveRates[this.receiveSelectedCurrency.code]);
        }
    }

    private async resetCurrencyNotAvailable(direction: CurrencyDirection, currency: Currency) {
        await this.toastService.showToast('Currency not available', 'The currency selected is not available at the moment', 'error');
        const isFiat = currency.type === CurrencyType.Fiat;

        if (!isFiat) this.cryptoList = this.cryptoList.filter(c => c.code !== currency.code);
        const preferredCurrency = isFiat ? this.preferredCurrency : this.cryptoList[0];

        if (direction === CurrencyDirection.Give) this.giveSelectedCurrency = preferredCurrency;
        else this.receiveSelectedCurrency = preferredCurrency;
    }

    async setCharacterLimiter() {
        const windowWidth = window.innerWidth;
        if (windowWidth < 400) {
            this.limiter = 10;
        } else {
            this.limiter = 17;
        }
    }

    async tryAddExchange() {
        this.timer?.au.controller.viewModel.resetCountdown();
        await this.focusOutCheckValid();
        await this.validateAmount();
        setTimeout(async () => {
            if (await this.isExchangeValid())
                await this.addExchange();
        }, 1200);
    }

    async isExchangeValid(): Promise<boolean> {
        this.isBusy = true;
        if (!this.giveSelectedCurrency || !this.receiveSelectedCurrency) {
            this.showValidationToastMsg('Please select both Spend and Receive currencies to proceed.');
            this.isBusy = false;
            return false;
        }
        else if (!this.selectedPaymentMethod) {
            this.showValidationToastMsg('Please select a payment method to proceed.');
            this.isBusy = false;
            return false;
        }
        else
            return await this.waitUntilCalculationFinishes();
    }

    showValidationToastMsg(msg: string) {
        this.toastService.showToast('Info', `${msg}`, 'Info', 'info');
    }

    waitUntilCalculationFinishes(): Promise<boolean> {
        return new Promise(resolve => {
            const checkIfCalcFinished = () => {
                if (this.exchangeBtnEnable && !this.exchangeTypeClicked && !this.isChangingCurrency)
                    resolve(true);
                else
                    setTimeout(checkIfCalcFinished, 2000);
                this.isBusy = false;
            };
            checkIfCalcFinished();
        });
    }

    /**
     * Builds the exchange object and saves it in local storage and navigates to purchase-flow page
     */
    async addExchange() {
        if (this.giveSelectedCurrency.type === 'C' && this.receiveSelectedCurrency.type !== 'C') {
            this.selectedPaymentMethod = await this.paymentMethodWebsiteService.getByReference(this.giveSelectedCurrency.reference);
        }

        const exchangePayload: Exchange = this.createExchangePayload();

        this.sessionService.saveExchange(exchangePayload);

        if (!this.user) {
            this.handleAuthRedirection('cart');
            return;
        }

        this.router.navigate('/cart');
    }

    private createExchangePayload(): Exchange {
        let deliveryFeePercentage = 0;
        let discount = 0;

        if (this.selectedPaymentMethod) {
            const deliveryMethod = this.deliveryMethods.find(x => x.paymentMethodId === this.selectedPaymentMethod.paymentMethod.id && x.name === 'regular');
            deliveryFeePercentage = (this.receiveSelectedCurrency?.type === 'F' && ((deliveryMethod?.markupPercent || 0) + (deliveryMethod?.baseFee || 0))) || 0;
        }

        if (this.validCoupon?.type === 'Percent') {
            discount = this.amountReceived * (this.validCoupon.value / 100);
        } else if (this.validCoupon) {
            const usdRate = 1 / this.youReceiveRates['USD'];
            discount = this.validCoupon.value * usdRate;
        }

        return {
            giveSelectedCurrency: this.giveSelectedCurrency,
            giveCurrencyOptions: this.giveCurrencyOptions,
            receiveSelectedCurrency: this.receiveSelectedCurrency,
            receiveCurrencyOptions: this.receiveCurrencyOptions,
            selectedPaymentMethod: this.selectedPaymentMethod,
            receivingPaymentMethod: this.receivingPaymentMethod,
            amountGifted: parseFloat(parseFloat(this.amountGifted.toString()).toFixed(this.giveSelectedCurrency.isStable ? 2 : 6)),
            amountReceived: parseFloat(parseFloat((+this.amountReceived + discount).toString()).toFixed(this.receiveSelectedCurrency.isStable ? 2 : 6)),
            currentRate: this.currentRate,
            transactionFeeBeforeRateApplied: this.transactionFeeBeforeRateApplied,
            transactionFee: this.transactionFee,
            cryptoFee: this.receiveSelectedCurrency.type === 'C' ? this.transactionFee : this.transactionFeeBeforeRateApplied,
            deliveryFeeBeforeRateApplied: this.deliveryFeeBeforeRateApplied,
            deliveryFee: this.deliveryFee,
            deliveryFeePercentage,
            youGiveRates: this.youGiveRates,
            youReceiveRates: this.youReceiveRates,
            walletAddress: null,
            extraField: null,
            timeLeft: this.timeLeft,
            trackingNumber: null,
            validCoupon: this.validCoupon ? { ...this.validCoupon, discount: parseFloat(discount.toFixed(6)) } : null
        };
    }

    async setExchangeValues(intent: 'give' | 'receive', ignoreValidation?: boolean) {
        if (!this.giveSelectedCurrency || !this.receiveSelectedCurrency || !this.currentRate) {
            return;
        }

        this.setExchangeRates();
        const deliveryFeeToUse = this.getDeliveryFee();

        await this.setFee(null, null, intent === 'give' ? null : this.amountReceived / this.currentRate);

        const feePercentage = this.feePercentageToUse?.percentage ?? 0;
        const baseFee = (this.feePercentageToUse?.baseFee ?? 0);

        let orderBaseFee: number;

        if (this.receiveSelectedCurrency?.type === CurrencyType.Crypto && this.giveSelectedCurrency?.type === CurrencyType.Fiat) {
            orderBaseFee = baseFee;
        } else {
            orderBaseFee = baseFee * (1 / this.currentRate);
        }

        if (intent === 'receive') {
            this.amountGifted = this.amountGiftedDefault = this.amountReceived / (1 - (feePercentage + deliveryFeeToUse)) / this.currentRate + orderBaseFee;
        }

        if (!ignoreValidation) {
            await this.validateAmount();
        }

        this.transactionFeeBeforeRateApplied = (feePercentage * this.amountGifted) + orderBaseFee;
        this.transactionFee = this.transactionFeeBeforeRateApplied * this.currentRate;
        this.deliveryFeeBeforeRateApplied = deliveryFeeToUse * +this.amountGifted;
        this.deliveryFee = this.deliveryFeeBeforeRateApplied * this.currentRate;

        if (intent === 'give') {
            const amountReceived = (this.amountGifted * this.currentRate) - this.transactionFee - this.deliveryFee;
            this.amountReceived = this.amountReceivedDefault = parseFloat(amountReceived.toFixed(this.receiveSelectedCurrency?.isStable ? 2 : 6));
        }

        this.amountGifted = parseFloat(this.amountGifted ? (+this.amountGifted).toFixed(this.giveSelectedCurrency?.isStable ? 2 : 6) : '0');
    }

    async setFee(giveSelectedCurrency: Currency, receiveSelectedCurrency: Currency, amount: number) {
        const dataForFee = {
            giveSelectedCurrency: giveSelectedCurrency ?? this.giveSelectedCurrency,
            receiveSelectedCurrency: receiveSelectedCurrency ?? this.receiveSelectedCurrency,
            selectedPaymentMethod: this.selectedPaymentMethod,
            receivingPaymentMethod: this.receivingPaymentMethod,
            amount: await this.getAmountInCurrency(amount ?? this.amountGifted),
        };

        this.helper.clearServiceQueueState('PaymentMethodCurrencyFeeService');
        this.feePercentageToUse = await this.currencyService.getFee(dataForFee);
    }

    getDeliveryFee() {
        if (!this.selectedPaymentMethod || this.receiveSelectedCurrency?.type !== 'F') {
            return 0;
        }

        const deliveryMethod = this.deliveryMethods?.find(x => x.paymentMethodId === this.selectedPaymentMethod.paymentMethod.id && x.name === 'regular');
        return ((deliveryMethod?.markupPercent || 0) + (deliveryMethod?.baseFee || 0)) / 100;
    }

    /**
     * If Amount is USD returns the amountGifted if not it will get the equivalent of that amount in USD
     * @returns amountInUsd
     */
    async getAmountInCurrency(amount: number, currency?: string) {
        if (currency === 'USD' || this.giveSelectedCurrency.code === 'USD') return amount;
        let auxRate = this.youGiveRates[currency || 'USD'];
        if (!auxRate) {
            //Request the exchange rates with extra param
            const auxGiveRates = await this.coinbaseService.getJustExchangesRatesWithInvertedDesiredCurrency(currency || 'USD', this.giveSelectedCurrency.code);
            auxRate = auxGiveRates[this.giveSelectedCurrency.code];
        }
        return amount * auxRate;
    }

    /**
     * Function that triggers when timer ends, the timer is the one to invoque this function.
     */
    async countdownFinished() {
        try {
            if (this.giveSelectedCurrency && this.receiveSelectedCurrency) {
                await this.fetchPairRates();
                await this.setCurrentRate();
                await this.validateAmount();
                await this.setExchangeValues('give');
                if (this.validCoupon) {
                    setTimeout(() => {
                        this.couponInputViewModel?.calculateCouponDiscount();
                    }, 0);
                }
            }
        } catch (e) {
            this.autoRestart = false;
        }
    }

    /**
     * Function executed on one of the currency select changed
     */
    async selectChanged(selectedCurrency: Currency, intent: 'give' | 'receive', isClicked: boolean = false) {
        this.exchangeBtnEnable = false;
        this.isChangingCurrency = true;
        if (!selectedCurrency) return;
        if (isClicked) {
            intent === 'give' ? this.previousGiveCurrency = this.giveSelectedCurrency : this.previousReceiveCurrency = this.receiveSelectedCurrency;
        }
        this.activeSkeleton();
        if (selectedCurrency.type === 'F') {
            let currencyToSent = '';
            this.selectedPaymentMethodObject = this.selectedPaymentMethod;
            if (this.selectedPaymentMethodObject?.supportedCurrencies?.length) {
                const currencies = this.selectedPaymentMethodObject.supportedCurrencies.map(c => this.activeCurrencies.find(ac => ac.id === c.currencyId)?.code);
                currencyToSent = this.defaultCurrencyValueConverter.toView(this.customerCountry);
                if (!currencies.includes(currencyToSent)) {
                    currencyToSent = currencies[0];
                }
            } else {
                if (this.selectedPaymentMethod && (this.giveSelectedCurrency || this.receiveSelectedCurrency)) {
                    currencyToSent = intent === 'give' ? this.giveSelectedCurrency?.code : this.receiveSelectedCurrency?.code;
                } else {
                    currencyToSent = selectedCurrency.code;
                }
            }
            if (currencyToSent !== selectedCurrency.code) {
                await this.setCurrency(currencyToSent, selectedCurrency.code, intent);
            }
        }

        if (intent.includes('give')) {
            this.youGiveRates = await this.coinbaseService.getJustExchangesRates(selectedCurrency.code);

            if (this.exchangeType === 'CC') {
                const currenciesToInclude = Object.keys(this.youGiveRates);
                this.receiveCurrencyOptions = this.cryptoList.filter(c => currenciesToInclude.includes(c.code));
            }
        } else {
            this.youReceiveRates = await this.coinbaseService.getJustExchangesRates(selectedCurrency.code);

            if (this.exchangeType === 'CC') {
                const currenciesToInclude = Object.keys(this.youReceiveRates);
                this.giveCurrencyOptions = this.cryptoList.filter(c => currenciesToInclude.includes(c.code));
            }
        }

        if (isClicked || this.isAttached) {
            if (intent !== 'give')
                return;
            const fromCurrency = this.previousGiveCurrency || this.giveSelectedCurrency;
            this.setAmount(this.amountGifted, intent, fromCurrency, selectedCurrency);
            this.timer?.au.controller.viewModel.resetCountdown();
        }

        this.setCurrenciesBasedPaymentMethod();
        this.isChangingCurrency = false;
    }

    mouseOver(id: string) {
        this.tooltipTimeout = setTimeout(() => {
            const el = document.querySelector('.mdc-tooltip.mdc-tooltip--shown > div');
            if (el) {
                switch (id) {
                    case 'timer':
                        el.innerHTML = 'The live mid-market rates will update every 15 seconds until you checkout.';
                        break;
                }
            }
        }, 50);
    }

    currencyFormat(value: number | string, symbol: string, type: string, isStable: boolean) {
        const format = isStable ? '(0,0.00)' : '(0,0.000000)';
        return type === 'F' ? `${symbol}${numeral(value).format(format)}` : `${numeral(value).format(format)} ${symbol}`;
    }

    detached() {
        this.timer?.au.controller.viewModel.stop();
        this.forceCurrencySubscriber?.dispose();
        this.forcePaymentMethodSubscriber?.dispose();
        this.tradeParamsSubscriber?.dispose();
        this.routeChangeSubscriber?.dispose();
        this.pageLoadedSubscriber?.dispose();
        this.sizeChangeSubcription?.dispose();
    }

    enableBtnExchageNow() {
        if (this.giveSelectedCurrency?.code !== 'USD') {
            const toFixedAmount = this.giveSelectedCurrency?.type === 'F' || this.giveSelectedCurrency?.isStable ? 2 : 6;
            this.maximumAmount = Number((this.selectedPaymentMethod?.maximum * this.usdRates[this.giveSelectedCurrency?.code])?.toFixed(toFixedAmount));
            this.minimumAmount = Number((this.selectedPaymentMethod?.minimum * this.usdRates[this.giveSelectedCurrency?.code])?.toFixed(toFixedAmount));
        } else if (this.selectedPaymentMethod) {
            this.maximumAmount = Number((this.selectedPaymentMethod?.maximum)?.toFixed(2));
            this.minimumAmount = Number((this.selectedPaymentMethod?.minimum)?.toFixed(2));
        }

        this.exchangeBtnEnable = this.amountGifted && this.selectedPaymentMethod && this.giveSelectedCurrency?.type === 'C' ||
            (this.amountGifted >= this.minimumAmount && this.amountGifted <= this.maximumAmount);

        this.publishExchangeUpdatedEvent();
    }

    amountConversion(amount: number, fromCurrency: string, toCurrency: string): number {
        if (fromCurrency === toCurrency) {
            return amount;
        }

        const toCurrencyRate = this.usdRates[toCurrency] || 1;
        const fromCurrencyRate = this.usdRates[fromCurrency] || 1;
        return amount * toCurrencyRate / fromCurrencyRate;
    }

    getCurrency(value: string | number) {
        if (!value) return;
        return this.activeCurrencies.find(currency => currency.code === value || currency.reference === value || currency.id === value);
    }

    getExchangeType(giveType: string, receiveType: string) {
        if (giveType === 'F' && receiveType === 'F') {
            return 'FF';
        } else if (giveType === 'C' && receiveType === 'C') {
            return 'CC';
        } else if (giveType === 'C') {
            return 'S';
        } else if (receiveType === 'C') {
            return 'B';
        } else
            return 'B';
    }

    moveButtonExchangeSelector(exchangeType: 'B' | 'S' | 'FF' | 'CC', withTransition: boolean = true) {
        this.animationRunning = true;
        const _exchangeType = exchangeType === 'CC' ? 'FF' : exchangeType;
        const elementToMove = document.getElementById(_exchangeType);
        if (!elementToMove) return;
        const elementToMoveTarget = elementToMove.getBoundingClientRect();
        const parentElement = this.exchangeBtnAnimated.parentElement;

        this.exchangeBtnAnimated.style.transition = withTransition ? 'all 0.5s' : '';
        this.exchangeBtnAnimated.style.width = elementToMove.clientWidth + 'px';
        this.exchangeBtnAnimated.style.left = _exchangeType !== 'B' ? `${elementToMoveTarget.left - parentElement.getBoundingClientRect().left}px` : '0px';

        setTimeout(() => this.animationRunning = false, 500);
    }

    removeRestrictedCurrencies(activeCurrencies: Currency[], currentCountryCode: string) {
        if (!currentCountryCode) return activeCurrencies;
        return activeCurrencies.filter(c => this.countryIsNotRestrictedInCurrency(c, currentCountryCode));
    }

    countryIsNotRestrictedInCurrency(currency: Currency, countryCode: string) {
        return !currency.restrictedCountries?.some(c => c.country?.alpha2 === countryCode);
    }

    exchangeSelectorIsActive(option: 'B' | 'S' | 'FF' | 'CC', type: 'B' | 'S' | 'FF' | 'CC') {
        if (option === type || option === 'FF' && type === 'CC') {
            return 'active';
        }
        return '';
    }

    setAmount(amount: number, intent: 'give' | 'receive' = 'give', fromCurrency: Currency, toCurrency: Currency) {
        if (!fromCurrency || !toCurrency)
            return;
        let newAmount = this.amountConversion(amount, fromCurrency.code, toCurrency.code);
        newAmount = +newAmount.toFixed(toCurrency.isStable ? 2 : 6);
        if (intent === 'give') {
            this.amountGifted = newAmount;
        } else {
            this.amountReceived = newAmount;
        }
    }

    changeCurrentIntent() {
        this.currentIntent = this.currentIntent === 'give' ? 'receive' : 'give';
        this.eventAggregator.publish('trade-intent-changed');
    }

    private async publishExchangeUpdatedEvent() {
        if (!this.giveSelectedCurrency || !this.receiveSelectedCurrency)
            return;
        const exchangePayload: Exchange = await this.createExchangePayload();
        this.eventAggregator.publish('exchange-updated', { exchange: exchangePayload });
    }

    @computedFrom('width')
    get isDesktop() {
        return this.width > DesktopBreakpoint;
    }

    exchangeTypeChanged() {
        if (!this.exchangeType)
            return;
        switch (this.exchangeType) {
            case 'B':
                this.currentRouteFragment = 'buy';
                break;
            case 'S':
                this.currentRouteFragment = 'sell';
                break;
            case 'CC':
                this.currentRouteFragment = 'swap/crypto';
                break;
            case 'FF':
                this.currentRouteFragment = 'swap/fiat';
                break;
        }
        this.updateUrl();
    }

    giveSelectedCurrencyChanged(newValue: Currency, oldValue: Currency) {
        if (!this.giveSelectedCurrency || this.exchangeTypeClicked)
            return;
        if (this.giveSelectedCurrency?.code === this.receiveSelectedCurrency?.code)
            this.receiveSelectedCurrency = oldValue ?? this.receiveCurrencyOptions.find(c => c.code !== newValue.code);
        this.updateUrl();
    }

    receiveSelectedCurrencyChanged(newValue: Currency, oldValue: Currency) {
        if (!this.receiveSelectedCurrency || this.exchangeTypeClicked)
            return;
        if (this.giveSelectedCurrency?.code === this.receiveSelectedCurrency?.code)
            this.giveSelectedCurrency = oldValue ?? this.giveCurrencyOptions.find(c => c.code !== newValue.code);
        this.updateUrl();
    }

    updateUrl() {
        if (this.isBusy)
            return;
        this.isBusy = true;
        const hrefLang = ['en', 'en-US'].includes(this.currentLanguage.hrefLang) ? '' : `/${this.currentLanguage.hrefLang}`;

        setTimeout(() => {
            if (!this.giveSelectedCurrency || !this.receiveSelectedCurrency) {
                this.router.navigate(`${hrefLang}/${this.currentRouteFragment}`, { replace: true, trigger: false });
                this.isBusy = false;
                this.eventAggregator.publish('trade-url-updated');
                return;
            } else {
                this.router.navigate(
                    `${hrefLang}/${this.currentRouteFragment}/${this.giveSelectedCurrency?.code.toLowerCase()}-to-${this.receiveSelectedCurrency?.code.toLowerCase()}`, { replace: true, trigger: false });
                this.isBusy = false;
                this.eventAggregator.publish('trade-url-updated');
            }
        }, 600);
    }

    @computedFrom('maximumAmount', 'transactionFee', 'deliveryFee')
    get maximumReceiveAmount() {
        if (!this.receiveSelectedCurrency?.code)
            return;
        return this.getAmountThreshold(this.maximumAmount);
    }

    private getAmountThreshold(amount: number) {
        return parseFloat((amount * this.currentRate - this.transactionFee - this.deliveryFee).toFixed(this.receiveSelectedCurrency.isStable ? 2 : 6));
    }
}
