import { bindable, inject, customAttribute } from 'aurelia-framework';

@customAttribute('skeleton-loading')
@inject(Element)
export class SkeletonLoadingAttribute {
    @bindable isLoading : boolean = false;
    @bindable width: string;
    @bindable height: string;
    @bindable borderRadius: string;
    @bindable forceDiv = false;

    private isSkeletonDisplayed: boolean = false;
    private skeletonElement: HTMLElement;

    constructor(private element: Element) {}

    isLoadingChanged() {
        if (this.isLoading) {
            this.turnOnSkeleton();
            return;
        }
        if (this.isSkeletonDisplayed) this.turnOffSkeleton();
    }

    private turnOnSkeleton() {
        if (this.forceDiv || this.element.tagName === 'IMG') {
            this.skeletonElement = document.createElement('div');
            this.skeletonElement.innerHTML = this.element.innerHTML;
            this.copyAttrs(this.skeletonElement, this.element);
        }
        else {
            this.skeletonElement = this.element.cloneNode(true) as HTMLElement;
        }
        this.skeletonElement.classList.add('skeleton-loading');
        this.skeletonElement.style.setProperty('color', 'transparent', 'important');

        this.addDynamicClass('skeletonWidth', this.width);
        this.addDynamicClass('skeletonHeight', this.height);
        if (this.borderRadius) this.skeletonElement.style.borderRadius = this.borderRadius;

        this.element.classList.add('hidden-skeleton');
        this.element.parentNode.insertBefore(this.skeletonElement, this.element);
        this.isSkeletonDisplayed = true;
    }

    private turnOffSkeleton() {
        this.element.parentNode.removeChild(this.skeletonElement);
        this.element.classList.remove('hidden-skeleton');
        this.isSkeletonDisplayed = false;
    }

    addDynamicClass(varName: string, value: string | number) {
        if (!this.skeletonElement || !value) return;
        this.skeletonElement.style.setProperty(`--${varName}`, `${value}`);
        this.skeletonElement.classList.add(varName);
    }

    copyAttrs(target, source) {
        [...source.attributes].forEach(attr => {
            target.setAttribute(attr.nodeName, attr.nodeValue);
        });
    }
}
