import { Subscription, EventAggregator } from 'aurelia-event-aggregator';
import { User } from 'services/models/user/user';
import './cx-document-upload.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { VeriffPerson } from 'services/models/veriff/startVerificationResponse';
import { DateFormatterValueConverter } from 'resources/value-converters/date-formatter';
import { CustomerService } from 'services/customer-service';
import { PageContentAreaService } from 'services/page-content-area-service';

@autoinject()
export class CxDocumentUpload {
    @bindable user: User;
    userVeriffData: VeriffPerson;
    userBirth: string;
    userSubscriber: Subscription;
    veriffSubscriber: Subscription;
    expandableOpen = false;

    constructor(
        private customerService: CustomerService,
        private dateFormatterValueConverter: DateFormatterValueConverter,
        private eventAggregator: EventAggregator,
        private pageContentAreaService: PageContentAreaService
    ) {}

    async attached() {
        await this.getVeriffData();
        this.handleEventSubscriptions();
    }

    detached() {
        this.userSubscriber?.dispose();
        this.veriffSubscriber?.dispose();
    }

    openVeriff() {
        const veriffTrigger = document.getElementById('start-veriff');
        veriffTrigger.click();
    }

    async getVeriffData() {
        if (!this.user) return;
        this.userVeriffData = await this.customerService.getVeriffUserData(this.user.id);
        if (this.userVeriffData?.dateOfBirth) this.userBirth = this.dateFormatterValueConverter.toView(this.userVeriffData.dateOfBirth, 'format', 'DD/MM/yyyy', null, true);
    }

    checkUserBillingAddress() {
        return this.user?.address && this.user?.city && this.user?.state && this.user?.country;
    }

    getUserBillingAddress() {
        return `${this.user?.address}, ${this.user?.city}, ${this.user?.state}, ${this.user?.country.toUpperCase()}`;
    }

    handleEventSubscriptions() {
        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async (payload: { user: User }) => {
            this.user = payload.user;
            await this.getVeriffData();
        });

        this.veriffSubscriber = this.eventAggregator.subscribe('veriff-verification', async payload => {
            if (payload?.user.idVerified) {
                this.user = {
                    ...this.user,
                    idVerified: payload.user.idVerified,
                    firstName: payload.user.firstName || this.user.firstName,
                    lastName: payload.user.lastName || this.user.lastName
                };
                this.userBirth = payload?.dateOfBirth ? this.dateFormatterValueConverter.toView(payload?.dateOfBirth, 'format', 'DD/MM/yyyy', null, true) : '-';
                await this.getVeriffData();
            }
        });
    }
}
