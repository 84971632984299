import { Router } from 'aurelia-router';
import './404.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { Helper } from 'resources/helpers/helper';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class UnknownRoute {
    constructor(private websiteService: WebsiteService, private helper: Helper, private router: Router) { }
    message = 'We apologize for the inconvenience, but the page you are attempting to access is unavailable. It may have been relocated or deleted, or the URL may have been entered incorrectly. Please confirm the accuracy of the URL. In the meantime, we invite you to explore other sections of our website. Should you require further assistance, please do not hesitate to contact our support team.';
    homePageRoute;

    async attached() {
        this.helper.handlePrerender404(this.router);
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.homePageRoute = pages.find(x => x.name === 'Home')?.routeName ?? '';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).prerenderReady = true;
    }
}
