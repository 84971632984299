import './trade.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject } from 'aurelia-dependency-injection';
import { WebsiteService } from 'services/website-service';
import { ProductCategoryService } from 'services/product-category-service';
import { PLATFORM } from 'aurelia-framework';
import { GameForNav, ProductCatWithGame } from 'services/models/game/productCategory';
import { PageByWebsite } from 'services/models/page/pageByWebsite';
import { Router } from 'aurelia-router';
import { PageContentArea } from 'services/models/page/pageContentArea';

@autoinject()
export class Trade {
    constructor(
        private router: Router,
        private pageContentAreaService: PageContentAreaService,
        private websiteService: WebsiteService,
        private productCategoryService: ProductCategoryService
    ) {}

    pageConfig: PageByWebsite;
    navCategory: ProductCatWithGame;
    faqRoute: string;
    tradesForNav: GameForNav[];
    pageContentArea: PageContentArea[];
    redirectUserToHomePage = () => {};

    async attached() {
        await this.loadInitialData();
    }

    detached() {
        window.removeEventListener('popstate', this.redirectUserToHomePage);
    }

    async loadInitialData() {
        const [navCategory, pages] = await Promise.all([
            this.productCategoryService.getNavCategory('trade'),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);

        this.navCategory = navCategory;
        this.tradesForNav = this.navCategory?.gameForNav || [];
        this.faqRoute = pages.find(x => x.name === 'Faq')?.routeName ?? 'faq';
        this.pageConfig = pages.find(x => x.name === 'Trade');
    }

    async configureRouter(config, router: Router) {
        this.navCategory = await this.productCategoryService.getNavCategory('trade');
        this.tradesForNav = this.navCategory?.gameForNav || [];

        config.options.pushState = true;
        config.map([
            {
                route: '',
                name: 'trade',
                moduleId: PLATFORM.moduleName('pages/trade/trade-currency/trade-currency'),
                settings: {
                    metaDescription: this.pageConfig?.metaDescription,
                },
                data: router.parent.routes.find(route => route.name === 'trade')?.data
            },
            ...this.tradesForNav.map(game => ({
                route: game.slug,
                name: game.slug[0],
                moduleId: PLATFORM.moduleName('pages/trade/trade-currency/trade-currency'),
                title: game.title,
                settings: {
                    metaDescription: game.metaDescription,
                    keyName: `TRADE_${game.shortName}`
                }
            }))
        ]);

        config.mapUnknownRoutes(() => {
            this.router.navigateToRoute('404');
        });
        this.router = router;
        this.redirectUserToHomePage = () => this.router.navigate('/');
        window.addEventListener('popstate', this.redirectUserToHomePage);
    }
}
