import { singleton, autoinject, signalBindings } from 'aurelia-framework';
import { ApiService } from './api-service';
import { UserDocumentService } from './user-document-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastService } from './toast-service';
import { debug, websiteShortCode } from 'environment';
import { BlacklistService } from './blacklist-service';
import { ReferralService } from './referral-service';
import { LoginResponse } from './models/user/loginResponse';
import { RegisterUserDto } from './models/user/registerUserDto';
import { User } from './models/user/user';
import { IpAddress } from './models/user/ipAddress';
import {
    Exchange,
    PaymentMethodThreshold,
    ThresholdOperationType,
    VerificationType
} from './models/purchase-flow/exchange';
import { jwtDecode } from 'jwt-decode';
import { JwtPayloadExtended } from './models/JwtPayloadExtended';
import { Helper } from 'resources/helpers/helper';
import { ScriptService } from './script-service';
import { SiteSettingService } from './site-setting-service';
import { SubscriptionService } from './subscription-service';
import { HttpClient, json } from 'aurelia-fetch-client';
import countryToCurrency from 'country-to-currency';
import { LanguageService } from './language-service';
import codes from 'iso-lang-codes';
import { Language } from './models/language/language';
import { LanguageChangedEvent } from 'resources/constants';

const CURRENCY_KEY = 'currency';
const EMAIL_IN_REVIEW_KEY = 'email_in_review';
const EXCHANGE_KEY = 'exchange';
const ADMIN_VIEW = 'admin_view';
const PURCHASED = 'purchased';
const PREVIOUS_CURRENCY_KEY = 'previous_currency';
const PREVIOUS_PAYMENT_METHOD_KEY = 'previous_payment_method';
const SELECTED_PAYMENT_METHOD_KEY = 'selected_payment_method';
const PREVIOUS_GIVE_CURRENCY_KEY = 'previous_give_currency';
const GIVE_CURRENCY_KEY = 'give_currency';
const RECEIVE_CURRENCY_KEY = 'receive_currency';
const PREVIOUS_RECEIVE_CURRENCY_KEY = 'previous_receive_currency';
const REFERRAL_LINK_KEY = 'referral_link';
const REFERRER_LINK_KEY = 'refferer_link';
const REFERRER_USER_ID = 'refferer_user_id';
const CURRENT_TICKETS_PAGE_VIEW = 'current_tickets_page_view';
const DRAFT_MESSAGE = 'draft_message';
const DRAFT_TITLE = 'draft_title';
const DRAFT_CREATED_DATE = 'draft_created_date';
const DRAFT_UPDATED_DATE = 'draft_updated_date';
const CURRENT_PURCHASED_ORDERS_PAGE_VIEW = 'current_purchased_orders_page_view';
const CURRENT_SOLD_ORDERS_PAGE_VIEW = 'current_sold_orders_page_view';
const CURRENT_EXCHANGE_ORDERS_PAGE_VIEW = 'current_exchange_orders_page_view';
const CURRENT_CASHBACK_PAGE_VIEW = 'current_cashback_page_view';
const LANGUAGE_KEY = 'language';
const ACTIVE_REFERRAL_CODE_KEY = 'active_referral_code';

interface FormattedData {
    email: string;
    password: string;
    token: string;
    websiteShortCode: string;
    jwtToken: string;
    signInOption: string;
    fingerprint?: string;
    baseToken?: string;
    ipv4Address?: string;
    ipv6Address?: string;
    skipChecks?: boolean
}

@singleton(true)
@autoinject()
export class SessionService {
    isAuthenticated = false;
    currentUser: User = null;
    geolocation: IpAddress;
    userProxy: IpAddress;
    intercomTimer: NodeJS.Timeout;
    private TOKEN_KEY = 'jwt_token';
    userBlacklist;
    canOpenLiveChat = true;
    intervalAttempts;
    triggeredIntercomScript;
    isGeolocationRequested = false;
    isRefreshing: boolean;
    refreshTokenPromise: Promise<string> | null;
    activeCurrencies;

    constructor(
        private apiService: ApiService,
        private eventAggregator: EventAggregator,
        private userDocumentService: UserDocumentService,
        private toastService: ToastService,
        private blacklistService: BlacklistService,
        private referralService: ReferralService,
        private helper: Helper,
        private scriptService: ScriptService,
        private siteSettingService: SiteSettingService,
        private subscriptionService: SubscriptionService,
        private http: HttpClient,
        private languageService: LanguageService
    ) {
        this.isRefreshing = false;
        this.refreshTokenPromise = null;
    }

    async login(data: RegisterUserDto): Promise<LoginResponse> {
        const formattedData: FormattedData = { email: data.email, password: data.password, token: data.token, websiteShortCode: websiteShortCode(), jwtToken: data.jwtToken, signInOption: data.signInOption, baseToken: data.baseToken, skipChecks: data.skipChecks };
        formattedData.fingerprint = await this.siteSettingService.getDeviceInfoForUser();
        const { ipv4Address, ipv6Address } = await this.helper.fetchIPsForCustomer() ?? {};
        formattedData.ipv4Address = ipv4Address;
        formattedData.ipv6Address = ipv6Address;
        const response = await this.apiService.doPost('', formattedData);
        if (response) {
            if (!response.resetPassword) {
                if (response.userBlacklist?.length > 0) {
                    this.userBlacklist = response.userBlacklist;
                    const blackListResponse = await this.verifyIsUserInBlackList(null, true);
                    if (blackListResponse) {
                        return;
                    }
                }
                this.saveToken(response.token);
                this.isAuthenticated = true;
            }
            this.helper.handleGtagEvent('login', null, null, null, null, data?.signInOption);
            return response;
        }
    }

    async getProfile(forceRefresh = false) {
        if (await this.isTokenValid()) {
            if (this.currentUser && !forceRefresh) {
                return this.currentUser;
            }

            return await this.fetchProfileData(forceRefresh);
        }
    }

    async refreshProfile() {
        if (await this.isTokenValid()) {
            return this.fetchProfileData(true);
        }
    }

    async fetchProfileData(force = false) {
        if (force) this.helper.invalidateServiceData('fetchProfileData');
        this.currentUser = await this.helper.fetchData(this.apiService, 'profile', 'fetchProfileData', force);
        this.userBlacklist = this.currentUser?.userBlacklist;
        if (!this.currentUser?.isDeleted) {
            this.saveToken(this.currentUser?.refreshToken?.token);
        } else if (this.currentUser?.isDeleted) {
            await this.toastService.showToast('Account deleted', 'As a result you have been logged out, please Sign up again to access your account or contact Support.', 'info');
            await this.logout();
        }
        if (this.userBlacklist?.length > 0) {
            const blackListResponse = await this.verifyIsUserInBlackList(null, true);
            if (blackListResponse) {
                await this.logout();
            }
        }
        return this.currentUser;
    }

    async verifyIsUserInBlackList(email, skipEvent) {
        this.userBlacklist = skipEvent ? this.userBlacklist : await this.blacklistService.getBlacklistByUserEmail(email);
        let message = 'BLlvl4';
        for (const row of this.userBlacklist) {
            if (row.level > 2) {
                this.closeLivechat();
            }
            if (row.level === 4) {
                message += row.category === 1 ? 'E' : row.category === 2 ? 'P' : row.category === 3 ? 'IP' : row.category === 4 ? 'C' : 'R';
                this.toastService.showToast('There was an unexpected error. Please contact customer support at support@chicksx.com. Ref:' + message + '.', 'Error', 'error');
                return true;
            }
        }
        return false;
    }

    initializeLiveChat() {
        let intercomSettings = {};
        if (this.currentUser) {
            intercomSettings = {
                email: debug() ? `STAGING-${this.currentUser?.email?.replace('@', '_cx@')}` : this.currentUser?.email?.replace('@', '_cx@'),
                // eslint-disable-next-line camelcase
                user_id: debug() ? `S${this.currentUser?.id}` : this.currentUser?.id,
                // eslint-disable-next-line camelcase
                user_hash: this.currentUser?.liveChatCXHash,
                name: `${this.currentUser?.firstName} ${this.currentUser?.lastName}`,
                // eslint-disable-next-line camelcase
                customer_URL: `https://admin.chicksgold.com/customers/${this.currentUser?.id}`,
                // eslint-disable-next-line camelcase
                website_FROM: 'ChicksX Inc.'
            };
        } else {
            intercomSettings = {
                // eslint-disable-next-line camelcase
                website_FROM: 'ChicksX Inc.'
            };
        }

        const globalIntercomSettings = {
            // eslint-disable-next-line camelcase
            api_base: 'https://api-iam.intercom.io',
            // eslint-disable-next-line camelcase
            app_id: 'vese6jb0',
            ...intercomSettings
        };

        window.intercomSettings = globalIntercomSettings;
        this.canOpenLiveChat = true;


        if (!this.triggeredIntercomScript) this.scriptService.injectIntercomScript();

        if (this.intercomTimer) return;

        this.intervalAttempts = 0;
        this.intercomTimer = setInterval(() => {
            if (window.Intercom && this.canOpenLiveChat) {
                window.Intercom('boot', globalIntercomSettings);
                clearInterval(this.intercomTimer);
                this.intercomTimer = null;
            } else if (this.intervalAttempts > 10) {
                clearInterval(this.intercomTimer);
                this.intercomTimer = null;
            }
            this.intervalAttempts++;
        }, 1000);
    }

    closeLivechat() {
        this.canOpenLiveChat = false;
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
        this.checkIfIntercomExistsAndRemove();
    }

    async verifyPassword(password: string) {
        if (await this.isTokenValid()) {
            return await this.apiService.doPost('VerifyPassword', password);
        }
    }

    removeCookie(name) {
        if (Array.isArray(name)) {
            name.forEach(x => document.cookie = `${x}= ; expires=0; path=/`);
            return;
        }
        document.cookie = `${name}= ; expires=0; path=/`;
    }

    async logout(user: User = null) {
        try {
            await this.apiService.doDelete('Logout');
            this.eventAggregator.publish('untrack-user', { userId: user?.id || this.currentUser.id });
            this.saveTicketsCurrentPageView(1);
        } finally {
            this.destroyToken();
            this.currentUser = null;
            this.isAuthenticated = false;
            this.eventAggregator.publish('user-updated', { logout: true });
            this.helper.clearServiceQueueState('fetchProfileData');
        }
    }

    clearSession() {
        this.destroyToken();
        this.currentUser = null;
        this.isAuthenticated = false;
        this.eventAggregator.publish('user-updated', {});
    }

    saveToken(token: string | null) {
        if (!token) return;
        window.localStorage[this.TOKEN_KEY] = token;
    }

    private getStoredToken() {
        return window.localStorage[this.TOKEN_KEY] as string | null;
    }

    async getToken() {
        const token = this.getStoredToken();
        const isValid = await this.isTokenValid(false, token, false);

        if (!isValid && token) {
            try {
                return await this.refreshAccessToken();
            } catch (e) {
                console.error(e);
                this.destroyToken();
                return null;
            }
        }

        return token;
    }

    destroyToken() {
        window.localStorage.removeItem(this.TOKEN_KEY);
    }

    async getUserProxy() {
        return this.userProxy;
    }

    getCountry() {
        return this.geolocation?.countryCode;
    }

    getState() {
        return this.geolocation?.regionName;
    }

    getCity() {
        return this.geolocation?.city;
    }

    saveAdminView(bool) {
        window.localStorage[ADMIN_VIEW] = bool;
        this.eventAggregator.publish('admin-view-updated', { bool: bool });
        signalBindings('admin-view-updated');
    }

    async getIpInformation() {
        if (!this.geolocation) {
            try {
                const response = await this.helper.fetchData(this.apiService, 'IpAddress/GetIpData', 'getGeolocation');
                this.isGeolocationRequested = true;
                if (!response) return;
                this.geolocation = response;
            } catch (error) {
                console.error('Error fetching IP information:', error);
            }
        }
    }

    getAdminView() {
        return (window.localStorage[ADMIN_VIEW] === 'true');
    }

    async checkRolesForPanelAccess() {
        try {
            const token = await this.getToken();
            if (!token) return;
            return (jwtDecode(token) as JwtPayloadExtended)?.admin;
        } catch {
            return;
        }
    }

    saveEmailInReview(bool) {
        window.localStorage[EMAIL_IN_REVIEW_KEY] = bool;
    }

    getEmailInReview() {
        const emailInReview = window.localStorage[EMAIL_IN_REVIEW_KEY];
        if (emailInReview) {
            return JSON.parse(emailInReview);
        }
    }

    async getIdVerificationInReview(id) {
        const idVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 1);
        if (idVerificationInReview) {
            return idVerificationInReview;
        }
    }

    async getAddressVerificationInReview(id) {
        const addressVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 2);
        if (addressVerificationInReview) {
            return addressVerificationInReview;
        }
    }

    async getSelfieVerificationInReview(id) {
        const selfieVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 3);
        if (selfieVerificationInReview) {
            return selfieVerificationInReview;
        }
    }

    deleteByUserIdAndCategoryId(userId, categoryId) {
        this.userDocumentService.deleteByUserIdAndCategoryId(userId, categoryId);
    }


    async getCurrency(): Promise<string> {
        if (this.checkIfUserAgentPrerender()) return 'USD';
        return window.localStorage[CURRENCY_KEY] || await this.getCurrencyByCountry();
    }

    async getCurrencyByCountry() {
        const country = await this.getCountry();
        if (country) {
            const options = this.activeCurrencies;
            return options.find(x => x.code === countryToCurrency[country])?.code ?? 'USD';
        }
        return 'USD';
    }

    checkIfUserAgentPrerender = () => navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;

    async getExchange(): Promise<Exchange> {
        const stringExchange = window.localStorage[EXCHANGE_KEY];
        if (this.isValidExchange()) {
            return JSON.parse(stringExchange);
        } else {
            await this.destroyExchange();
            return null;
        }
    }

    isValidExchange() {
        const stringExchange = window.localStorage[EXCHANGE_KEY];
        return stringExchange && stringExchange !== '[]' && stringExchange !== '';
    }

    async destroyExchange() {
        window.localStorage.removeItem(EXCHANGE_KEY);
        this.eventAggregator.publish('exchange-updated', { exchange: [] });
    }

    async saveExchange(exchange: Exchange) {
        this.eventAggregator.publish('exchange-updated', { exchange: exchange });
        window.localStorage[EXCHANGE_KEY] = JSON.stringify(exchange);
    }

    savePurchased(purchased: boolean) {
        window.localStorage[PURCHASED] = purchased;
    }

    getPurchased() {
        return window.localStorage[PURCHASED];
    }

    destroyPurchased() {
        window.localStorage.removeItem(PURCHASED);
    }

    savePreviousPaymentMethod(reference: string) {
        window.localStorage[PREVIOUS_PAYMENT_METHOD_KEY] = reference;
    }

    getPreviousPaymentMethod(): string {
        return window.localStorage[PREVIOUS_PAYMENT_METHOD_KEY];
    }

    saveSelectedPaymentMethod(reference: string) {
        window.localStorage[SELECTED_PAYMENT_METHOD_KEY] = reference;
    }

    getSelectedPaymentMethod(): string {
        return window.localStorage[SELECTED_PAYMENT_METHOD_KEY];
    }

    destroySelectedPaymentMethod() {
        window.localStorage.removeItem(SELECTED_PAYMENT_METHOD_KEY);
    }

    savePreviousCurrency(currency: string) {
        window.localStorage[PREVIOUS_CURRENCY_KEY] = currency;
    }

    getPreviousCurrency(): string {
        return window.localStorage[PREVIOUS_CURRENCY_KEY] || 'USD';
    }

    saveCurrency(currency: string) {
        window.localStorage[CURRENCY_KEY] = currency;
    }

    async checkIfUserByPaymentMethodInBlackList(threshold: PaymentMethodThreshold[]) {
        const result = {
            selectedPaymentMethod: false,
            paymentMethods: false,
            filterPaymentMethod: false
        };
        if (!this.currentUser) {
            this.currentUser = await this.getProfile();
        }
        if (this.currentUser) {
            this.userBlacklist = await this.blacklistService.getBlacklistByUserEmail(this.currentUser?.email, true);
            if (this.userBlacklist?.some(x => this.helper.includesNumber(x.level, [3, 4]))) {
                result.paymentMethods = true;
                result.selectedPaymentMethod = true;
                this.closeLivechat();
                return result;
            }
            if (this.userBlacklist?.some(x => x.level === 2 && this.helper.includesNumber(x.category, [1, 2, 3, 6, 7, 8, 9, 10]))) {
                result.filterPaymentMethod = true;
                if (
                    threshold.find(t => t.verificationTypeList.includes(VerificationType.ID) && t.operationTypeList.includes(ThresholdOperationType.Sell)) ||
                    threshold.find(t => t.verificationTypeList.includes(VerificationType.ID) && t.operationTypeList.includes(ThresholdOperationType.Buy)) ||
                    threshold.find(t => t.verificationTypeList.includes(VerificationType.Phone) && t.operationTypeList.includes(ThresholdOperationType.Sell)) ||
                    threshold.find(t => t.verificationTypeList.includes(VerificationType.Phone) && t.operationTypeList.includes(ThresholdOperationType.Buy))
                ) {
                    result.selectedPaymentMethod = true;
                }
            }
        }
        return result;
    }

    async setGiveCurrency(currency: string) {
        const currentCurrency = this.getGiveCurrency();
        if (currentCurrency !== currency)
            this.setPreviousGiveCurrency(currentCurrency);
        window.localStorage[GIVE_CURRENCY_KEY] = currency;
    }

    getGiveCurrency(): string {
        return window.localStorage[GIVE_CURRENCY_KEY];
    }

    async setPreviousGiveCurrency(currency: string) {
        window.localStorage[PREVIOUS_GIVE_CURRENCY_KEY] = currency;
    }

    getPreviousGiveCurrency(): string {
        return window.localStorage[PREVIOUS_GIVE_CURRENCY_KEY];
    }

    setReceiveCurrency(currency: string) {
        const currentCurrency = this.getReceiveCurrency();
        if (currentCurrency !== currency)
            this.setPreviousReceiveCurrency(currentCurrency);
        window.localStorage[RECEIVE_CURRENCY_KEY] = currency;
    }

    getReceiveCurrency(): string {
        return window.localStorage[RECEIVE_CURRENCY_KEY];
    }

    setPreviousReceiveCurrency(currency: string) {
        window.localStorage[PREVIOUS_RECEIVE_CURRENCY_KEY] = currency;
    }

    getPreviousReceiveCurrency(): string {
        return window.localStorage[PREVIOUS_RECEIVE_CURRENCY_KEY];
    }

    destroyReferralLink() {
        window.localStorage.removeItem(REFERRAL_LINK_KEY);
    }

    destroyReferrerLink() {
        window.localStorage.removeItem(REFERRER_LINK_KEY);
    }

    async saveReferralLink(linkName: string) {
        await this.referralService.submitReferralLink(linkName, '1');
        window.localStorage[REFERRAL_LINK_KEY] = linkName;
    }

    async saveReferrerLink(linkName: string) {
        await this.referralService.submitReferralLink(linkName, '2');
        window.localStorage[REFERRER_LINK_KEY] = linkName;
    }

    getReferralLink() {
        return window.localStorage[REFERRAL_LINK_KEY];
    }

    getReferrerLink() {
        return window.localStorage[REFERRER_LINK_KEY];
    }

    saveReferrerCode(userId) {
        if (userId) {
            localStorage.setItem(REFERRER_USER_ID, userId);
        } else {
            localStorage.removeItem(REFERRER_USER_ID);
        }
    }

    getReferrerCode() {
        return window.localStorage[REFERRER_USER_ID];
    }

    async getUserFirstName(email) {
        return await this.apiService.doGet(`GetUserFirstName/${email}`);
    }

    getTicketsCurrentPageView() {
        const currentTicketsPageView = window.localStorage[CURRENT_TICKETS_PAGE_VIEW];
        if (currentTicketsPageView) {
            return JSON.parse(currentTicketsPageView);
        }
    }

    saveDraftMessage(message) {
        window.localStorage[DRAFT_MESSAGE] = message;
    }

    getDraftMessage() {
        return window.localStorage[DRAFT_MESSAGE];
    }

    destroyDraftMessage() {
        window.localStorage.removeItem(DRAFT_MESSAGE);
    }

    saveDraftTitle(title) {
        window.localStorage[DRAFT_TITLE] = title;
    }

    getDraftTitle() {
        return window.localStorage[DRAFT_TITLE];
    }

    destroyDraftTitle() {
        window.localStorage.removeItem(DRAFT_TITLE);
    }

    saveDraftCreatedDate(date) {
        window.localStorage[DRAFT_CREATED_DATE] = date;
    }

    getDraftCreatedDate() {
        return window.localStorage[DRAFT_CREATED_DATE];
    }

    destroyDraftCreatedDate() {
        window.localStorage.removeItem(DRAFT_CREATED_DATE);
    }

    saveDraftUpdatedDate(date) {
        window.localStorage[DRAFT_UPDATED_DATE] = date;
    }

    getDraftUpdatedDate() {
        return window.localStorage[DRAFT_UPDATED_DATE];
    }

    destroyDraftUpdatedDate() {
        window.localStorage.removeItem(DRAFT_UPDATED_DATE);
    }

    async canAccessAdminPanel() {
        if (this.currentUser) {
            return await this.checkRolesForPanelAccess();
        } else {
            this.currentUser = await this.getProfile();
            if (!this.currentUser) {
                return false;
            }
            return await this.checkRolesForPanelAccess();
        }
    }

    saveTicketsCurrentPageView(page) {
        window.localStorage[CURRENT_TICKETS_PAGE_VIEW] = page;
    }

    getPlatformLinkCookie(name) {
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || undefined;
    }

    setPlatformLinkCookie(platformLink, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = '; expires=' + date.toUTCString();
        document.cookie = platformLink + '=' + value + expires + ';path=/';
    }

    savePurchasedOrdersCurrentPageView(page) {
        window.localStorage[CURRENT_PURCHASED_ORDERS_PAGE_VIEW] = page;
    }

    getPurchasedOrdersCurrentPageView() {
        const currentOrdersPageView = window.localStorage[CURRENT_PURCHASED_ORDERS_PAGE_VIEW];
        if (currentOrdersPageView) {
            return JSON.parse(currentOrdersPageView);
        }
    }

    saveSoldOrdersCurrentPageView(page) {
        window.localStorage[CURRENT_SOLD_ORDERS_PAGE_VIEW] = page;
    }

    getSoldOrdersCurrentPageView() {
        const currentOrdersPageView = window.localStorage[CURRENT_SOLD_ORDERS_PAGE_VIEW];
        if (currentOrdersPageView) {
            return JSON.parse(currentOrdersPageView);
        }
    }

    saveExchangeOrdersCurrentPageView(page: number) {
        window.localStorage[CURRENT_EXCHANGE_ORDERS_PAGE_VIEW] = page;
    }

    getExchangeOrdersCurrentPageView(): number {
        const currentOrdersPageView = window.localStorage[CURRENT_EXCHANGE_ORDERS_PAGE_VIEW];
        if (currentOrdersPageView) {
            return JSON.parse(currentOrdersPageView);
        }
    }

    saveCashbackCurrentPageView(page) {
        window.localStorage[CURRENT_CASHBACK_PAGE_VIEW] = page;
    }

    getCashbackCurrentPageView() {
        const currentCashbackPageView = window.localStorage[CURRENT_CASHBACK_PAGE_VIEW];
        if (currentCashbackPageView) return JSON.parse(currentCashbackPageView);
    }

    async checkFreeTrialAndGetUser() {
        await this.refreshProfile();
        if (!this.currentUser) return;
        if (this.currentUser.hasFreeTrial) {
            const ft = await this.subscriptionService.hasChicksVipFreeTrial(this.currentUser);
            this.currentUser.hasFreeTrial = ft;
        }
        return this.currentUser;
    }

    async validateAuthorizationCode(authorizationCode: string, clientId: string, validationCode?: string) {
        return await this.apiService.doPost('AuthorizeCode', { authorizationCode, clientId, validationCode }) as LoginResponse;
    }

    async isTokenValid(ignoreExpire = false, token: string = null, fetchToken = true) {
        if (fetchToken) {
            token ??= await this.getToken();
        }

        let expired = false;
        let requiresIpAuthorization = false;

        try {
            const validToken = jwtDecode(token) as JwtPayloadExtended;
            expired = Date.now() >= (validToken?.exp * 1000);
            requiresIpAuthorization = Boolean(validToken?.ria);
        } catch {
            return false;
        }

        return token && token !== '' && token !== undefined && token !== 'undefined' && token !== 'null' && (!expired || ignoreExpire) && !requiresIpAuthorization;
    }

    isLoggedIn() {
        return Boolean(this.currentUser);
    }

    refreshAccessToken() {
        if (this.isRefreshing) {
            // If a refresh is already in progress, wait for it to complete
            return this.refreshTokenPromise;
        }

        this.isRefreshing = true;

        // Create a promise to manage the refresh state
        this.refreshTokenPromise = new Promise((resolve, reject) => {
            const token = this.getStoredToken();

            if (!token) {
                reject('No access token to refresh');
            }

            this.http.fetch('RefreshToken', {
                method: 'POST',
                body: json({ token }),
                headers: {
                    'X-Skip-Interceptor': 'true'
                }
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Failed to refresh token');
                }

                return response.json();
            }).then(data => {
                this.saveToken(data.token);

                this.isRefreshing = false;
                this.refreshTokenPromise = null;

                resolve(data.token);
            }).catch(err => {
                this.isRefreshing = false;
                this.refreshTokenPromise = null;

                reject(err);
            });
        });

        return this.refreshTokenPromise;
    }

    checkIfIntercomExistsAndRemove = () => {
        const intercomFacadeBtn = document.querySelector('#intercom-facade-btn');
        if (intercomFacadeBtn) intercomFacadeBtn.remove();
        const intercomScript = document.querySelector('#intercom-script');
        if (intercomScript) intercomScript.remove();
    };

    checkVeriffBlacklistUser() {
        return this.userBlacklist?.some(x => this.helper.includesNumber(x.level, [1, 2]));
    }

    setLanguage(language: Language) {
        window.localStorage[LANGUAGE_KEY] = JSON.stringify(language);
        this.eventAggregator.publish(LanguageChangedEvent, { languageSelected: language });
    }

    async getLanguage() {
        if (!this.languageService.languages) await this.languageService.getLanguages();
        const stringLanguage = window.localStorage[LANGUAGE_KEY];
        if (this.isLocalStorageLanguage()) {
            return JSON.parse(stringLanguage);
        }
        return this.getLanguageFromLocale();
    }

    async getLanguageByCountry() {
        const country = await this.getCountry();
        if (!country) return 'en';
        return (codes.findCountryLanguages(country)?.find(x => x === country.toLowerCase()) ?? codes.findCountryLanguages(country)?.[0]) ?? 'en';
    }

    getLanguageFromLocale() {
        const browserLanguages = navigator.languages.map(el => el.toLowerCase());
        const languages = this.languageService.languages;
        for (let i = 0; i < languages.length; i++) {
            if (browserLanguages.indexOf(languages[i].hrefLang.toLowerCase()) !== -1) {
                return languages[i];
            }
        }
        return this.languageService.languages.find(x => x.hrefLang?.includes('en'));
    }

    isLocalStorageLanguage() {
        const stringLanguage = window.localStorage[LANGUAGE_KEY];
        return stringLanguage &&
            stringLanguage !== '[]' &&
            stringLanguage !== '' &&
            stringLanguage !== 'undefined'
            && stringLanguage !== 'null';
    }

    /**
     * Saves the active referral code to local storage.
     *
     * @param {string} code - The referral code to be saved.
     * @returns {void} This function does not return a value.
     */
    saveActiveReferralCode(code: string): void {
        window.localStorage[ACTIVE_REFERRAL_CODE_KEY] = code;
    }

    /**
     * Retrieves the active referral code from local storage.
     *
     * @returns {string | null} The active referral code if it exists in local storage, or null if it doesn't.
     */
    getActiveReferralCode(): string | null {
        return window.localStorage[ACTIVE_REFERRAL_CODE_KEY] || null;
    }
}
