// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/home/hero_img.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/logo/chicksx-circle-logo-purple.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/support.svg", import.meta.url);
// Module
var code = `<template>
    <section id="home">
        <div class="cx-background position-relative">
            <div class="container hero-container px-0">
                <cx-hero
                    first-text.bind="'CX_HERO_FIRST_TEXT'"
                    second-text.bind="'CX_HERO_SECOND_TEXT'"
                    licenses.bind="'CX_HERO_LICENSES'"
                    is-custom-class.bind="true"
                >
                    <div class="hero-img position-relative">
                        <img src="${___HTML_LOADER_IMPORT_0___}" class="img-fluid" alt="Hero img" loading="lazy">
                    </div>
                </cx-hero>
            </div>
            <div class="crypto-price-cards-container">
                <div class="d-flex flex-row w-100 mx-auto align-items-center justify-content-lg-center space-between-element position-absolute">
                    <cx-crypto-price-card
                        repeat.for="i of 4"
                        currency.bind="mostPopularCurrencies[i]"
                        is-loading.bind="isLoading"
                        learn-more-func.call="goToBuySell(mostPopularCurrencies[i].code)"
                        learn-more-route.bind="tradePageRoute | languageRoute & async"
                        data-stats-currencies.bind="dataStatsCurrencies"
                    ></cx-crypto-price-card>
                    <div class="extra-crypto-price-card" click.delegate="goToLearnMore(tradePageRoute)">
                        <div>
                            <img src="${___HTML_LOADER_IMPORT_1___}" class="currency-logo" alt="Hero img" loading="lazy" skeleton-loading="is-loading.bind:isLoading;">
                            <div class="extra-card-text text-large-plus font-regular text-start dinamyc-line-height" skeleton-loading="is-loading.bind:isLoading;">
                                \${ 'CX_VIEW_MORE_CRYPTO_HERE' | siteStrings }
                            </div>
                        </div>
                        <a href.bind="tradePageRoute | languageRoute & async" class="learn-more-link-extra-card text-medium-static font-regular d-flex align-items-center" skeleton-loading="is-loading.bind:isLoading;">
                            <span>\${ 'CX_LEARN_MORE' | siteStrings }</span>
                            <span class="material-icons ms-2">
                                arrow_forward
                            </span>
                        </a>
                    </div>
                    <div class="extra-crypto-price-card-space"></div>
                </div>
                <cx-timer
                    time-limit.bind="5"
                    auto-restart.bind="autoRestart"
                    time-left.two-way="timeLeft"
                    element.ref="timer"
                    show.bind="false"
                ></cx-timer>
            </div>
        </div>
        <div class="container-why-chicksx">
            <div class="container-cx d-flex flex-column align-items-xl-center">
                <div class="d-flex flex-column flex-xl-row align-items-xl-center justify-content-center why-chicksx">
                    <cx-page-content-area key.bind="'CX_HOME_FIRST_TITLE'" class="title p-line-height" skeleton-loading="is-loading.bind:isLoading;"></cx-page-content-area>
                    <cx-page-content-area key.bind="'CX_HOME_FIRST_TEXT'" class="description" skeleton-loading="is-loading.bind:isLoading;"></cx-page-content-area>
                </div>

                <div class="learn-more-cards-container d-flex flex-column flex-xl-row">
                    <cx-learn-more-card
                        repeat.for="card of learnMoreCards"
                        page-loading.bind="isLoading"
                        title.bind="card.title"
                        paragraph.bind="card.paragraph"
                        icon.bind="card.icon"
                        icon-alt.bind="card.iconAlt"
                        button-caption="\${card.buttonCaption | siteStrings}"
                        href.bind="card.redirectRoute|languageRoute & async"
                    ></cx-learn-more-card>
                </div>
            </div>
        </div>
        <!-- Container-security -->
        <div class="container-security" show.bind="!isLoading">
            <cx-paragraph-image
                class.bind="'security'"
                caption.bind="'CX_HOME_SECOND_TITLE'"
                text.bind="'CX_HOME_SECOND_TEXT'"
                image="/home/security2.svg"
                icon="/icons/security.svg"
                text-first=true
            >
                <cx-button
                    class.bind="'d-inline-block'"
                    button-text="\${'CX_LEARN_MORE' | siteStrings}"
                    button-style.bind="'learn'"
                    button-icon-style.bind="'text-regular'"
                    click.delegate="goToFaq()"
                ></cx-button>
            </cx-paragraph-image>
        </div>
        <!-- Container support -->
        <div class="container-support d-none d-xl-flex align-items-center d-flex justify-content-center margin-align-left">
            <div class="d-flex flex-column flex-md-row" show.bind="!isLoading">
                <img src="${___HTML_LOADER_IMPORT_2___}" loading="lazy" class="support-img" alt="Support">
                <div>
                    <cx-page-content-area key.bind="'CX_HOME_THIRD_TITLE'" class="support-title"></cx-page-content-area>
                    <cx-page-content-area key.bind="'CX_HOME_THIRD_TEXT'" class="support-title"></cx-page-content-area>
                    <cx-button
                        class="d-inline-block"
                        button-text="\${'CX_GET_IN_TOUCH' | siteStrings}"
                        button-style="support"
                        button-icon-style.bind="'text-regular'"
                        click.delegate="showIntercomMessage()"
                    ></cx-button>
                </div>
            </div>
        </div>
        <div class="container-support d-flex d-xl-none">
            <div class="d-flex flex-column justify-content-sm-between" show.bind="!isLoading">
                <div>
                    <div class="d-flex flex-row align-items-center support-header">
                        <img src="${___HTML_LOADER_IMPORT_2___}" loading="lazy" class="support-img" alt="Support">
                        <cx-page-content-area key.bind="'CX_HOME_THIRD_TITLE'" class="support-title"></cx-page-content-area>
                    </div>
                    <div>
                        <cx-page-content-area key.bind="'CX_HOME_THIRD_TEXT'" class="support-title"></cx-page-content-area>
                    </div>
                </div>
                <cx-button class="d-inline-block" button-text="\${'CX_GET_IN_TOUCH' | siteStrings}" button-style="support" button-icon-style.bind="'text-regular'" click.delegate="showIntercomMessage()"></cx-button>
            </div>
        </div>
        <div class="seo-dropdown-content" if.bind="shouldRenderContent('HOME_DROPDOWN_CONTENT') || viewingAsAdmin">
            <cx-seo-dropdown header-title.bind="'CX_ABOUT_CRYPTO_EXCHANGES' | siteStrings:'About Crypto Exchanges'" is-loading.bind="isLoading">
                <cx-page-content-area
                   key="HOME_DROPDOWN_CONTENT"
                   class="seo-dropdown-content"
                ></cx-page-content-area>
            </cx-seo-dropdown>
        </div>
    </section>
</template>
`;
// Exports
export default code;