import './cx-order-section.scss';
import { autoinject, observable, computedFrom, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { OrderService } from 'services/order-service';
import { CurrencyService } from 'services/currency-service';
import { SessionService } from 'services/session-service';
import { RateCurrencyFormatValueConverter } from 'resources/value-converters/rate-currency-formatter';
import { ToastService } from 'services/toast-service';
import { Helper } from 'resources/helpers/helper';
import { OrderChatStatus, CurrencyTypes } from 'resources/helpers/enums';
import { OrderOperationType, Order, OrderPaginationRequest, SmallOrder, OrderSectionType } from 'services/models/order';
import { Currency } from 'services/models/purchase-flow/exchange';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { FiatCurrencyFormatValueConverter } from 'resources/value-converters/fiat-currency-formatter';
import { ITableHeader } from 'resources/elements/cx-table/cx-table';
import { DateFormatterValueConverter } from 'resources/value-converters/date-formatter';
import { SizeChangedEvent } from 'resources/constants';
import { ISizeEvent } from 'types/events';
import { User } from 'services/models/user/user';
import { websiteShortCode } from 'environment';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';
import { LanguageRouteValueConverter } from 'resources/value-converters/language-route';

@autoinject()
export class CxOrderSection {
    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private orderService: OrderService,
        private currencyService: CurrencyService,
        private sessionService: SessionService,
        private rateConverter: RateCurrencyFormatValueConverter,
        private toastService: ToastService,
        private helper: Helper,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private fiatCurrencyFormatter: FiatCurrencyFormatValueConverter,
        private dateFormatterValueConverter: DateFormatterValueConverter,
        private siteStringsValueConverter: SiteStringsValueConverter,
        private languageRouteValueConverter: LanguageRouteValueConverter
    ) {
        this.helper.getResolutions(this);
    }

    @bindable search = '';
    @observable page = 1;
    @bindable count = 1;
    @bindable type: OrderSectionType = 'Purchased';
    @bindable operationType: OrderOperationType = OrderOperationType.Buy;
    @bindable title: string = 'Orders';
    @bindable childRoute: string = '';
    @bindable statusOptions: string[] = ['All', 'Complete', 'Marked sent', 'Partially delivered', 'Pending', 'Rejected', 'Refunded', 'Created'];
    @bindable createButton: boolean = true;
    @bindable createButtonText = 'Create a new order';
    @bindable createButtonFunction = () => {};
    @bindable handleBackButton = () => {};
    @bindable prefetchCount: number;
    @bindable prefetchOrders: Order[];
    @bindable handlerSetActiveOption = (_options?) => {};
    @bindable rowCallback = (_param?) => {};
    @bindable sessionName: string;
    @bindable backRoute: string;
    @bindable sortByOptions = ['Latest', 'Oldest', 'Amount: (High to Low)', 'Amount: (Low to High)'];
    @bindable sortRequest = ['recent', 'old', 'high', 'low'];
    @bindable sort = false;
    @bindable orders: Order[] = [];
    @bindable perPage: number = 5;
    @bindable pageLoading: boolean = true;
    @bindable route: string;
    @bindable iconPathRow: string = 'shopping_cart';
    @bindable iconVariant: string = 'outlined';
    @bindable tableHeaders: ITableHeader[] = [
        { title: 'id', class: 'id-header-width text-center' },
        { title: 'amount', class: 'column-header-width' },
        { title: 'message', class: 'column-header-width' },
        { title: 'date', class: 'column-header-width' },
        { title: 'activity', class: 'column-header-width' },
        { title: 'status', class: 'status-header-width' },
    ];

    user: User;
    hideRemainingBalance: boolean;
    ordersCount: number = 0;
    status: string = this.sort ? 'Latest' : 'All';
    previousSearch: string = '';
    contentLoading: boolean = true;
    searching: boolean = false;
    parent;
    width: number;
    scrollView: HTMLElement;
    shouldUpdate: boolean;
    previous: string;
    realCount: number;
    mappedOrders: SmallOrder[];
    currencies: Currency[];
    isCashback: boolean;
    mappedOrdersMobile: SmallOrder[] = [];

    orderChatRoutes = new Map([
        [OrderOperationType.Buy, 'purchased-chat'],
        [OrderOperationType.Sell, 'sold-chat'],
        [OrderOperationType.Exchange, 'exchange-chat']
    ]);

    statusByType = {
        'Purchased': ['Marked Sent'],
        'Sold': ['Disputed']
    };

    createButtonClassList = 'primary primary-style create-order-button';

    preload() {
        this[`is${this.typePascal}`] = true;
        if (this.isAnyMainType()) {
            this.handleBackButton = () => this.router.navigate('orders');
        }
    }

    async bind(bindingContext) {
        this.parent = bindingContext;
        this.user = await this.sessionService.getProfile();
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('cx-order-section');
            this.sessionName ??= this.isAnyMainType()
                ? this.typePascal + 'Orders'
                : this.typePascal;
            this.page = await this.sessionService[`get${this.sessionName}CurrentPageView`]?.(this.page) ?? 1;
            this.width = this.helper.getWidth();
            this.helper.widthHandler(this);
            this.user = this.user ?? await this.sessionService.refreshProfile();

            if (!this.user) {
                this.router.navigateToRoute(await this.languageRouteValueConverter.toView('home', true));
                return;
            }

            this.currencies = await this.currencyService.getActiveCurrenciesByWebsite();

            if (this.sort) setTimeout(() => this.status = this.sortByOptions[0]);

            this.scrollView = document.querySelector('#main-page-host .simplebar-content-wrapper');
            await this.getOrders();
            await this.handleEventSubscriptions();
            this.contentLoading = false;
        } catch (error) {
            this.toastService.showToast('Error', 'Could not load orders. Please try again in a few minutes', 'error');
            throw new Error(error);
        } finally {
            this.helper.validateLoading('cx-order-section');
        }
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
        this.helper.clearServiceQueueState('getOrdersCountByWebsiteByUserId');
    }

    async handleFetchingOrderAndCurrencies() {
        this.mappedOrders = this.orders.map(e => {
            const isExchangeOrder = Order.isExchangeOrder(e);
            const isSubscriptionOrder = e.products[0]?.product.game.shortName === 'SUBSCRIPTION';

            return {
                id: e.id,
                isExchangeOrder,
                isSubscriptionOrder,
                coinFrom: Order.getOrderCoinFrom(e, isExchangeOrder),
                coinFromSymbol: Order.getOrderCoinFromSymbol(e, this.currencies, isExchangeOrder),
                coinTo: Order.getOrderCoinTo(e, isExchangeOrder),
                targetCurrency: Order.getOrderTargetCurrency(e, isExchangeOrder),
                baseCurrency: Order.getOrderBaseCurrency(e, this.currencies, isExchangeOrder),
                coinToSymbol: Order.getOrderCoinToSymbol(e, this.currencies, isExchangeOrder),
                fee: Order.getOrderFee(e, isExchangeOrder),
                quantity: isSubscriptionOrder || this.isCashback ? e.convertedCurrencyTotal : Order.getOrderQuantity(e, isExchangeOrder),
                price: Order.getOrderPrice(e, isExchangeOrder),
                rate: this.isCashback ? e.currencyRateUsed : Order.getOrderExchangeRate(e, isExchangeOrder),
                status: e.status.split(':')[1],
                fulfilled: e.fulfilled,
                date: this.dateFormatterValueConverter.toView(e.createdDate, 'format', 'MM/DD/YYYY'),
                websiteShortCode: e.websiteShortCode,
                subscriptionCashback: e.subscriptionCashback,
                transactionType: e.transactionType,
                currencyUsed: e.currencyUsed
            };
        });
    }

    handleRowClick(order) {
        if (typeof this.rowCallback === 'function') {
            this.rowCallback({ order });
        }
    }

    handleEventSubscriptions() {
        const events = {
            'page-loaded': () => this.pageLoading = false,
            [SizeChangedEvent]: (payload: ISizeEvent) => {
                this.width = payload.width;
                this.helper.widthHandler(this);
            },
        };

        this.helper.subscribeEvents(this, events);
    }

    isAnyMainType = () => ['Sold', 'Purchased', 'Swap', 'Exchange'].includes(this.typePascal);

    async getOrders() {
        this.contentLoading = true;
        if (this.user) await this.getOrdersAndCount();
        this.hideRemainingBalance ||= this.orders.filter(x => x.balanceAmount)?.length === null && !this.user.balance;
        if (!this.prefetchOrders) this.contentLoading = false;
    }

    isOrderComplete = (order) => this.helper.checkEntityStatus(order.status, order.fulfilled) === 'Complete' && order?.orderChat?.status === OrderChatStatus.Closed;

    async pageChanged(page: number) {
        if (this.prefetchOrders) this.parent?.pageChanged?.(page);
        if (!this.orders) return;
        this.count = Math.ceil(this.orders.length / this.perPage);
        this.scrollView?.scrollTo({ top: 0, behavior: 'smooth' });

        if (page) {
            await this.sessionService[`save${this.sessionName}CurrentPageView`]?.(this.page);
        }

        if (this.shouldUpdate) {
            await this.getCount();
            this.shouldUpdate = false;
        }
        await this.getOrders();
    }

    getStatus = () => this.status !== 'All' ? this.status.toLowerCase() : null;

    getOption = () => this.sort ? this.getSort() : this.getStatus();

    getSort() {
        return this.sortRequest[this.sortByOptions.findIndex(x => x === this.status)];
    }

    handleStatusUpdate = async(event: CustomEvent) => {
        this.previous = this.status;
        this.status = event?.detail?.value;
        if (this.previous !== this.status) {
            this.helper.clearServiceQueueState('getOrdersCountByWebsiteByUserId');
            await this.getOrdersOrPageChanged();
        }
    };

    async getOrdersAndCount() {
        await this.getCount();
        if (this.prefetchOrders) this.orders = this.prefetchOrders;
        else {
            let request = new OrderPaginationRequest();
            request = {
                page: this.page,
                recordQuantity: this.perPage,
                status: this.getOption(),
                search: this.helper.singleSeparator(this.search),
                orderOperationType: this.operationType,
                isCashback: this.isCashback,
                websiteShortCode: this.isCashback ? null : websiteShortCode()
            };
            this.orders = await this.orderService.getCXListWithPagination(request);
        }
        // eslint-disable-next-line no-unsafe-optional-chaining
        this.orders = [...this.orders?.map(order => ({ ...order, route: this.defineRoute(order) }))];
        this.handleFetchingOrderAndCurrencies();
    }

    async getCount() {
        if (this.prefetchCount || this.prefetchCount === 0) {
            this.realCount = this.prefetchCount;

        } else {
            let request = new OrderPaginationRequest();
            request = {
                status: this.getOption(),
                search: this.helper.singleSeparator(this.search),
                orderOperationType: this.operationType,
                websiteShortCode: this.isCashback ? null : websiteShortCode(),
                isCashback: this.isCashback
            };
            this.realCount = await this.orderService.getOrdersCountByWebsiteByUserId(request);
        }
        this.ordersCount = this.perPage
            ? Math.ceil(this.realCount / this.perPage)
            : this.realCount;
    }

    async clearSearch() {
        this.search = '';
        await this.getOrdersOrPageChanged();
    }

    async searchFunction() {
        if (this.helper.compareBy(this.search, this.previousSearch, this.helper.singleSeparator)) return;
        this.searching = true;
        this.previousSearch = this.search;
        this.helper.clearServiceQueueState('getOrdersCountByWebsiteByUserId');
        await this.getOrdersOrPageChanged();
        this.searching = false;
    }

    searchDrawerFunction = async() => {
        await this.searchFunction();
        this.mappedOrdersMobile = [...this.mappedOrders];
    };

    async getOrdersOrPageChanged() {
        if (this.page !== 1) {
            this.page = 1;
            return;
        }
        await this.getOrders();
    }

    defineRoute(order) {
        return this.isOrderComplete(order) ? 'order-details' : this.orderChatRoutes.get(order.transactionType);
    }

    getFromToText(order: SmallOrder) {
        if (order.isExchangeOrder) {
            return `${order.coinFrom} - ${order.coinTo}`;
        }

        return order.baseCurrency?.originalCode;
    }

    getOrderFees(order: SmallOrder) {
        if (order.isExchangeOrder) {
            return this.helper.formatByCurrency(
                order.fee,
                order.targetCurrency,
                this.currencyFormatValueConverter
            );
        }

        return this.fiatCurrencyFormatter.toView(order.fee / order.rate, null, order.baseCurrency.originalCode, true);
    }

    getOrderQuantity(order: SmallOrder) {
        if (order.isExchangeOrder) {
            return this.helper.formatByCurrency(
                order.quantity,
                order.targetCurrency,
                this.currencyFormatValueConverter
            );
        }

        return this.fiatCurrencyFormatter.toView(order.quantity, null, order.baseCurrency.originalCode, true);
    }

    getOrderRate(order: SmallOrder) {
        if (order.isExchangeOrder) {
            return order.targetCurrency.type === CurrencyTypes.Crypto ?
                `${this.formatRate(order.rate, order.targetCurrency.symbol)} / ${order.baseCurrency.code}` :
                `${this.formatRate(1 / order.rate, order.baseCurrency.symbol)} / ${order.coinFromSymbol}`;
        }

        return order.rate.toString();
    }



    formatRate(value:number, symbol:string) {
        return this.rateConverter.toView(value, symbol, '');
    }

    @computedFrom('page', 'perPage', 'realCount')
    get paginationCount() {
        const page = this.page - 1;
        const perPage = this.perPage;
        const pageStart = perPage * page;
        let pageEnd = perPage + pageStart;
        const totalCount = this.realCount ?? 0;
        if (pageEnd > totalCount) pageEnd = totalCount;
        if (pageStart === 0 && pageEnd === 0) return 'No results';
        return this.siteStringsValueConverter.toView('CX_SHOWING_FROM_PAGINATED', '', [pageStart + 1, pageEnd, totalCount]);
    }

    @computedFrom('perPage', 'realCount')
    get shouldScroll() {
        return this.realCount > this.perPage;
    }

    prefetchOrdersChanged() {
        this.orders = this.prefetchOrders;
    }

    prefetchCountChanged() {
        this.getCount();
    }

    setActiveOption = async (options) => {
        this.handlerSetActiveOption?.(options);
        await this.getOrdersOrPageChanged();
        this.contentLoading = false;
    };

    infiniteScrollLoad = async(newPage: number) => {
        if (this.page === newPage) return 0;
        this.page = newPage;
        const oldList = [ ...this.mappedOrdersMobile ];

        await this.getOrders();

        this.mappedOrdersMobile = [...oldList, ...this.mappedOrders];
    };

    @computedFrom('type')
    get typePascal() {
        return this.type?.toPascal();
    }
}
