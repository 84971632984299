import { autoinject } from 'aurelia-dependency-injection';
import { ApiService } from './api-service';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { websiteShortCode } from 'environment';
import { ToastService } from './toast-service';
import { SessionService } from './session-service';
import { StartVerificationRequest } from './models/veriff/startVerificationRequest';
import { StartVerificationResponse } from './models/veriff/startVerificationResponse';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import { Helper } from 'resources/helpers/helper';
import { SiteSettingService } from './site-setting-service';
import moment from 'moment';
import { ApiErrorReason, IApiException } from './models/base-response';
import { WithdrawPasswordVerificationResponse } from './models/user/user';
import { ToastType } from './models/toast';
import { IPasswordResetRequest } from '@chicksgroup/types';

const CURRENCY_PRICE_CODE = 'currency_price_code';

@autoinject()
export class CustomerService {
    path = 'User';
    idPath = this.path + '/';

    constructor(
        private router: Router,
        private api: ApiService,
        private eventAggregator: EventAggregator,
        private toastService: ToastService,
        private sessionService: SessionService,
        private camelCaseValueConverter: CamelCaseValueConverter,
        private siteSettingService: SiteSettingService,
        private helper: Helper
    ) { }

    getRoute = (route: string) => `${this.path}${route ? '/' : ''}${route}`;

    get = async(route: string, params: object) => await this.api.doGet(this.getRoute(route), params);

    async register(data) {
        data.websiteShortCode = websiteShortCode();
        data.fingerprint = await this.siteSettingService.getDeviceInfoForUser();
        const registerResponse = await this.api.doPost(this.path, data);
        if (registerResponse) {
            if (registerResponse.alreadyRegistered) {
                await this.toastService.showToast('Welcome back!', `Account was created on ${moment.utc(registerResponse.createdDate).local().format('MM/DD/YYYY')} on ${registerResponse.website?.name ?? 'Chicks Gold'}. Please sign in instead with the same credentials.`, 'info');
                return true;
            }
        }
        this.helper.handleGtagEvent('sign_up', null, null, null, null, data?.signInOption);
        this.helper.handleFacebookPixelEvent('CompleteRegistration');
        this.helper.handleRedditEvent('SignUp');
        return registerResponse;
    }

    async updateInformation(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPut(this.path + '/updateInformation', data);
    }

    async deleteProfile(password) {
        return await this.api.doDelete(this.path + '/deleteProfile', password);
    }

    async requestEmailConfirmation() {
        return await this.api.doGet(this.path + '/RequestEmailConfirmation?websiteShortCode=' + websiteShortCode());
    }

    async sendContactEmail(from, message, topic, name, attachments = null) {
        const data = {
            from: from,
            message: message,
            topic: topic,
            name: name,
            websiteShortCode: websiteShortCode(),
            attachments: attachments ? JSON.stringify({ files: attachments }) : null
        };

        return await this.api.doPost('Contact', data);
    }

    async checkIfUserDeleted() {
        return await this.api.doGet(this.path + '/checkIfUserDeleted');
    }

    async setPaymentMethod(paymentMethodId) {
        const data = { paymentMethodId: paymentMethodId };
        return await this.api.doPut(this.path + '/updateInformation', data);
    }

    async requestTwoFactorAuthentication() {
        return await this.api.doGet(this.path + '/requestTwoFactorAuthentication');
    }

    async confirmTwoFactorActivation(token) {
        return await this.api.doPost(this.path + '/confirmTwoFactorActivation', { token: token });
    }

    async verifyTwoFactor(token) {
        return await this.api.doPost(this.path + '/VerifyTwoFactor', { token: token });
    }

    async checkIfTwoFactorEnabled() {
        return await this.api.doGet(this.path + '/checkIfTwoFactorEnabled');
    }

    async removeTwoFactorAuthentication(token: string) {
        return await this.api.doPost(this.path + '/removeTwoFactorAuthentication', { token: token });
    }

    async requestPasswordReset(data: IPasswordResetRequest) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('requestResetPassword', data) as boolean;
    }

    async updatePassword(currentPassword: string, newPassword: string, isWithdraw = false) {
        const response = await this.api.doPost(this.path + '/updatePassword', { currentPassword, newPassword, isWithdraw });
        if (response === null) throw new Error('Failed to update password');
        return response;
    }

    async resetPasswordWithToken(email: string, newPassword: string, token: string) {
        const data = { newPassword, email, accessToken: token };
        return await this.api.doPost('ResetPassword', data);
    }

    async verifyResetPasswordToken(token: string, email: string) {
        const data = { token, email };
        return await this.api.doGet('VerifyPasswordResetToken', data);
    }

    async registerPhone(countryCode: string, phone: string, countryFlag: string) {
        const data = { phoneNumber: phone, countryCode: countryCode, phoneCountryFlag: countryFlag };
        return await this.api.doPost(this.path + '/RegisterPhoneNumber', data);
    }

    async requestCall(): Promise<boolean> {
        return await this.api.doGet(this.path + '/RequestCallVerification');
    }

    async verifyPhone(token: string, usingTwilio: boolean, verfificationId: string, idToken: string) {
        const data = { token: token, usingTwilio: usingTwilio, verificationId: verfificationId, idToken: idToken };
        return await this.api.doPost(this.path + '/VerifyPhoneNumber', data);
    }

    async confirmEmail(email: string, token: string) {
        const data = { email: email, token: token };
        return await this.api.doPost(this.path + '/ConfirmEmail', data);
    }

    async requestIpAuthorization(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('RequestIpAuthorization', data);
    }

    async authorizeIp(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('AuthorizeIp', data);
    }

    async getDocumentDecision(sessionId: string): Promise<StartVerificationResponse> {
        return await this.api.doGet(`${this.path}/${sessionId}/GetDocumentDecision`);
    }

    async startVerification(userId: number, verification: StartVerificationRequest): Promise<StartVerificationResponse> {
        return await this.api.doPost(`${this.path}/${userId}/StartVeriffSession`, { verification });
    }

    async getVeriffUserData(id: number) {
        return await this.helper.fetchData(this.api, `${ this.path }/${id}/GetVeriffUserData`, 'getVeriffUserData');
    }

    async checkEmail(email: string): Promise<boolean> {
        return await this.api.doGet(`${this.path}/CheckEmail/${email?.trim()}`);
    }

    saveCurrencyPriceCode (currencyCode:string) {
        window.localStorage[CURRENCY_PRICE_CODE] = currencyCode;
    }

    getCurrencyPriceCode () {
        return window.localStorage[CURRENCY_PRICE_CODE];
    }

    async doPut(user) {
        return await this.api.doPut(this.path, user);
    }

    async unverifyPhone() {
        return await this.api.doPost(`${this.path}/UnverifyPhone`, {});
    }

    async getDiscordProfile(tokenType: string, accessToken: string) {
        return await this.api.doGet(`${this.path}/GetDiscordProfile/${tokenType}/${accessToken}`);
    }

    async getTotalSpentByUser(id: number) {
        return await this.api.doGet(`${this.path}/TotalSpentByUser/${id}`);
    }

    async linkDiscordToProfile(tokenType, accessToken) {
        return await this.api.doPost(`${this.path}/LinkDiscordToProfile`, { tokenType, accessToken });
    }

    async updateDiscordTag() {
        return await this.api.doPost(`${this.path}/UpdateDiscordTag`);
    }

    async verificationCategoryCriteriaMet(verificationCategoryName: string): Promise<boolean> {
        return await this.api.doGet(`${this.path}/${verificationCategoryName}/GetUserVerificationCategoryCriteriaMet`);
    }

    async hasWithdrawPassword() {
        return await this.api.doGet(`${this.path}/HasWithdrawPassword`) as boolean | null;
    }

    async verifyWithdrawPassword(withdrawPassword: string) {
        const response = await this.api.doPost(`${this.path}/VerifyWithdrawPassword`, withdrawPassword) as boolean | IApiException;
        if (typeof response === 'boolean') {
            if (response) return WithdrawPasswordVerificationResponse.Success;
            this.toastService.showToast('Error', 'Password is incorrect. Please try again.', 'error');
            return WithdrawPasswordVerificationResponse.Invalid;
        }

        if (response) {
            const notificationType = response.reason === ApiErrorReason.TooManyRequests ? ToastType.INFO : ToastType.ERROR;
            this.toastService.showToast(undefined, response.message, notificationType);
        }

        return response?.reason === ApiErrorReason.TooManyRequests ?
            WithdrawPasswordVerificationResponse.Blocked :
            WithdrawPasswordVerificationResponse.Invalid;
    }

    async send2FAEmail(userEmail: string, userPassword: string) : Promise<boolean> {
        return await this.api.doPost(`${this.path}/Send2FAEmail`, { userEmail, userPassword, websiteShortCode: websiteShortCode() }) as boolean;
    }

    async reset2FAByEmail(userEmail: string, token: string) : Promise<boolean> {
        return await this.get('Reset2FAByEmail', { userEmail, token, websiteShortCode: websiteShortCode() }) as boolean;
    }

    async verify2FAEmail(userEmail: string, token: string) : Promise<UserForm> {
        return await this.get('Verify2FAEmail', { userEmail, token, websiteShortCode: websiteShortCode() }) as UserForm;
    }

    async createWithdrawPassword(newWithdrawPassword: string, currentWithdrawPassword: string) {
        return await this.api.doPost(`${this.path}/CreateWithdrawPassword`, { newWithdrawPassword, currentWithdrawPassword });
    }

    async hasFailedPasswordAttempts() {
        return await this.api.doGet(`${this.path}/HasFailedPasswordAttempts`);
    }

    async sendWithdrawEmail() {
        return await this.api.doGet(this.helper.toParams(this.path + '/SendWithdrawEmail', { websiteShortCode: websiteShortCode() }));
    }

    async verify2FA(token) {
        return await this.api.doPost(`${this.path}/VerifyTwoFactor`, { token });
    }

    async resetWithdrawPasswordByEmail(userEmail : string, token: string) {
        return await this.api.doGet(this.path + '/ResetWithdrawPasswordByEmail', { userEmail, token, websiteShortCode: websiteShortCode() }) as boolean;
    }

    async verifyWithdrawEmail(userEmail, token) {
        return await this.api.doGet(this.helper.toParams(this.path + '/VerifyWithdrawEmail', { userEmail, token, websiteShortCode: websiteShortCode() }));
    }
}
