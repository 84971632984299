// Module
var code = `<template>
    <div id="subscription-cancel-membership">
        <cx-card class.bind="'main-container-subscriptions'">
            <cds-back-button click.trigger="router.navigateToRoute(backRoute)" page-loading.bind="pageLoading"></cds-back-button>
            <div class="cancel-membership-header">
                <div if.bind="!user.renewal">
                    <h2 class="cancel-membership-header__title text-extra-large no-font-bp" skeleton-loading="is-loading.bind:pageLoading;width:213px;height:32px;">
                        \${sectionData[sections.Cancel].titleKey|siteStrings:sectionData[sections.Cancel].title}
                    </h2>
                    <p class="cancel-membership-header__desc font-light m-0" skeleton-loading="is-loading.bind:pageLoading;width:251px;height:16px;">
                        \${'CX_BENEFITS_ARE_DUE' | siteStrings:'params':[dateFormatterValueConverter.toView(userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy')]}
                    </p>
                    <p
                        class="cancel-membership-header__link font-medium text-regular no-font-bp link-styling cursor-pointer"
                        click.delegate="setRenewalFunction()"
                        skeleton-loading="is-loading.bind:pageLoading;width:84px;height:19px;"
                    >
                        <cx-page-content-area
                            key.bind="'CX_CANCEL_MEMBERSHIP_RESUBSCRIBE'"
                        ></cx-page-content-area>
                    </p>
                </div>
                <div else class="cancel-membership-container">
                    <div class="cancel-membership__header">
                        <h2 class="subscription__title" skeleton-loading="is-loading.bind:pageLoading;">
                            \${'CX_CANCEL_MEMBERSHIP_PROMPT' | siteStrings:'Are you sure you want to cancel?':[dateFormatterValueConverter.toView(userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy')]}
                        </h2>
                        <p class="subscription__desc m-0" skeleton-loading="is-loading.bind:pageLoading;">
                            \${'CX_CANCEL_MEMBERSHIP_MESSAGE' | siteStrings:null:[dateFormatterValueConverter.toView(userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy')]}
                        </p>
                    </div>
                </div>
            </div>
            <div if.bind="user.renewal" class="cancel-membership-container-center">
                <div class="cancel-membership__checkboxes \${ pageLoading ? 'cancel-membership__checkboxes--loading' : '' }">
                    <div repeat.for="reason of reasonsKeys" skeleton-loading="is-loading.bind:pageLoading;width:320px;height:24px;">
                        <mdc-form-field if.bind="checkBoxes[reason]">
                            <mdc-checkbox 
                                change.delegate="checkForActive()"
                                checked.two-way="checkBoxes[reason].checked" 
                                class="customer-checkbox"
                            ></mdc-checkbox>
                            <label class="subscription__desc cursor-pointer" click.delegate="toggleReason(reason)">
                                \${ checkBoxes[reason].key|siteStrings:checkBoxes[reason].description }
                            </label> 
                        </mdc-form-field>
                    </div>
                </div>
                <div show.bind="checkBoxes[reasons.Other].checked && !pageLoading" class="w-100 cancel-membership__input input">
                    <cds-text-field
                        variant="filled"
                        type="textarea"
                        label.bind="'CX_CANCEL_MEMBERSHIP_REASONS_LABEL' | siteStrings"
                        indicator="border"                        
                        max-length="300"
                        rows="5"
                        max="300"
                        value.two-way="otherReason"
                        id="other-input"
                        ref="otherReasonInput"
                    ></cds-text-field>
                </div>
                <div class="cancel-membership-buttons">
                    <cx-button
                        class="text-medium me-0 me-sm-3 mb-3 mb-sm-0 cancel-membership-buttons__btn"
                        button-text.bind="'CX_END_MEMBERSHIP' | siteStrings"
                        button-style.bind="'secondary text-regular font-medium w-100 h-100'"
                        click.delegate="unsubscribe()"
                        button-disabled.bind="(checkBoxes[reasons.Other].checked && !otherReasonInput.value) || !anyCheckBoxActive"
                        skeleton-loading="is-loading.bind:pageLoading;width:250px;height:50px;"
                    ></cx-button>
                    <cx-button
                        class="text-medium cancel-membership-buttons__btn"
                        button-text.bind="'CX_KEEP_MEMBERSHIP' | siteStrings"
                        button-style.bind="'primary primary-style text-regular font-medium w-100 h-100'"
                        click.delegate="setSection(sections.Manage)"
                        skeleton-loading="is-loading.bind:pageLoading;width:250px;height:50px;"
                    ></cx-button>
                </div>
            </div>
        </cx-card>
    </div>
</template>
`;
// Exports
export default code;