// Module
var code = `<template>
    <div id="support" class="position-relative">
        <div
            show.bind="currentState === 'create' || currentState === 'detail' || currentState === 'suggested-title'"
            class="return text-start text-purple font-medium d-inline-flex align-items-center cursor-pointer"
            click.delegate="handleBack()"
        >
            <span class="material-icons back-button rounded-circle me-1">
                chevron_left
            </span>
            \${'CX_BACK' | siteStrings}
        </div>

        <!-- Welcome screen when no tickets added  -->
        <div class="single-container \${ currentState !== 'create' && currentState !== 'detail' ? 'mb-4' : '' } \${ currentState !== 'detail' ? '' : 'single-container__detail' }">
            <div if.bind="currentState === 'home'">
                <div class="d-flex flex-column">
                    <span class="section-title">
                        <cx-page-content-area
                            key.bind="'CX_SUPPORT_TICKETS_TITLE'"
                        ></cx-page-content-area>
                    </span>
                    <span class="section-subtitle">
                        <cx-page-content-area
                            key.bind="'CX_SUPPORT_TICKETS_MESSAGE'"
                        ></cx-page-content-area>
                    </span>
                </div>
                <div class="text-center">
                    <div class="mb-4">
                        <h1 class="inner-title">
                            <cx-page-content-area
                                key.bind="'CX_NO_ACTIVE_TICKETS_MESSAGE'"
                            ></cx-page-content-area>
                        </h1>
                        <span class="inner-subtitle">
                            <cx-page-content-area
                                key.bind="'CX_MAKE_NEW_TICKET_MESSAGE'"
                            ></cx-page-content-area>
                        </span>
                    </div>
                    <cx-button
                        class="mt-5 pt-3 text-medium "
                        button-text.bind="'CX_CREATE_NEW_TICKET' | siteStrings"
                        button-style.bind="'primary primary-style new-ticket-btn ticket-btn font-medium'"
                        click.delegate="switchToCreate()"
                        button-icon="add_circle_outline"
                        button-icon-style="me-2"
                    ></cx-button>
                </div>
            </div>

            <!-- Create new ticket -->
            <div if.bind="currentState === 'create'">
                <div class="d-flex flex-column mb-4">
                    <span class="text-extra-large font-medium support-header-create" >
                        <cx-page-content-area
                            key.bind="'CX_CREATE_SUPPORT_TICKET_TITLE'"
                        ></cx-page-content-area>
                    </span>
                    <cx-custom-title title.two-way="title" title-edit.two-way="titleEdit" sections-faq.bind="sectionsFaq"></cx-custom-title>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <div class="mb-2">
                        <span class="text-input-placeholder">
                            <cx-page-content-area
                                key.bind="'CX_CURRENT_RESPONSE_TIME'"
                            ></cx-page-content-area>
                        </span>
                    </div>
                    <div class="w-100 position-relative">
                        <cx-support-issue-input
                            file-types.bind="[]"
                            file-extensions.bind="['pdf', 'heic', 'jpg', 'jpeg', 'png', 'bmp', 'gif']"
                            issue.two-way="issue"
                            files-list.two-way="fileList"
                            is-saving-draft.bind="isSavingDraft"
                            element.ref="issueInput"
                        ></cx-support-issue-input>
                    </div>
                    <div class="btn-container align-self-end d-flex flex-column flex-sm-row">
                        <cx-button
                            class="text-regular me-0 me-sm-3 mb-3 mb-sm-0"
                            button-text.bind="'CX_CONNECT_LIVE_CHAT' | siteStrings"
                            button-style.bind="'secondary ticket-btn text-regular font-medium'"
                            click.delegate="showIntercomMessage()"
                            button-icon="connect_without_contact"
                            button-icon-style="me-3 text-large-plus"
                        ></cx-button>
                        <cx-button
                            class="text-regular mt-1 mt-sm-0 \${ triggeredSendMessage ? 'pe-none' : '' }"
                            button-text.bind="'CX_SEND_US_MESSAGE' | siteStrings"
                            button-style="primary primary-style ticket-btn send-btn text-regular font-medium \${ triggeredSendMessage ? 'disabled' : '' }"
                            button-icon-style.bind="'text-small'"
                            click.delegate="sendUsMessage()"
                            button-icon="send"
                            button-icon-style="me-2 text-small"
                        ></cx-button>
                    </div>
                </div>
            </div>

            <!-- Ticket listing -->
            <div if.bind="currentState === 'list'">
                <div class="d-flex align-items-start justify-content-between flex-column flex-sm-row">
                    <div class="d-flex flex-column support-header \${pageLoading ? 'support-header-sk' : ''}">
                        <span class="section-title" skeleton-loading="is-loading.bind:pageLoading;">
                            <cx-page-content-area
                                key.bind="'CX_SUPPORT_TICKETS_TITLE'"
                            ></cx-page-content-area>
                        </span>
                        <span class="section-subtitle section-subtitle-list" skeleton-loading="is-loading.bind:pageLoading;">
                            <cx-page-content-area
                                key.bind="'CX_SUPPORT_TICKETS_MESSAGE'"
                            ></cx-page-content-area>
                        </span>
                    </div>
                    <div class="d-flex btn-container-list align-items-center">
                        <cx-button
                            class="text-medium me-1 m-display w-50"
                            button-text.bind="'CX_CREATE_NEW_TICKET' | siteStrings"
                            button-style.bind="'primary primary-style new-ticket-btn-list new-ticket-btn ticket-btn font-medium '"
                            button-icon-style="me-2 text-small"
                            click.delegate="switchToCreate()"
                            skeleton-loading="is-loading.bind:pageLoading;"
                        ></cx-button>
                        <cx-button
                            class="text-medium me-3 dt-display"
                            button-text.bind="'CX_CREATE_NEW_TICKET' | siteStrings"
                            button-style.bind="'primary primary-style new-ticket-btn ticket-btn font-medium'"
                            click.delegate="switchToCreate()"
                            button-icon="add_circle_outline"
                            button-icon-style="me-2"
                            skeleton-loading="is-loading.bind:pageLoading;"
                        ></cx-button>
                        <cx-checkbox-select options.two-way="selectOptions" class="ms-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-checkbox-select>
                    </div>
                </div>
                <div if.bind="pageLoading" class="skeleton-container-table d-flex flex-column align-content-center align-items-center">
                    <div class="skeleton-container-rows" repeat.for="i of 7"></div>
                </div>
                <mdc-data-table show.bind="!pageLoading" busy.bind="busy" class="w-100 dt-display">
                    <table>
                        <thead>
                        <th>
                            \${'CX_TICKET_NUMBER' | siteStrings}
                            <button click.delegate="sortBy(0)" class="mdc-icon-button material-icons mdc-data-table__sort-icon-button text-regular-plus align-middle">
                                <div id="ticket-no-up" class="position-relative sort-asc">arrow_drop_up</div>
                                <div id="ticket-no-down" class="position-relative sort-desc">arrow_drop_down</div>
                            </button>
                        </th>
                        <th>
                            \${'CX_TITLE' | siteStrings}
                            <button click.delegate="sortBy(1)" class="mdc-icon-button material-icons mdc-data-table__sort-icon-button text-regular-plus align-middle">
                                <div id="title-up" class="position-relative sort-asc">arrow_drop_up</div>
                                <div id="title-down" class="position-relative sort-desc">arrow_drop_down</div>
                            </button>
                        </th>
                        <th>
                            \${'CX_MESSAGE' | siteStrings}
                            <button click.delegate="sortBy(2)" class="mdc-icon-button material-icons mdc-data-table__sort-icon-button text-regular-plus align-middle">
                                <div id="message-up" class="position-relative sort-asc">arrow_drop_up</div>
                                <div id="message-down" class="position-relative sort-desc">arrow_drop_down</div>
                            </button>
                        </th>
                        <th>
                            \${'CX_DATE' | siteStrings}
                            <button click.delegate="sortBy(3)" class="mdc-icon-button material-icons mdc-data-table__sort-icon-button text-regular-plus align-middle">
                                <div id="date-up" class="position-relative sort-asc">arrow_drop_up</div>
                                <div id="date-down" class="position-relative sort-desc">arrow_drop_down</div>
                            </button>
                        </th>
                        <th>
                            \${'CX_RECENT_ACTIVITY' | siteStrings}
                            <button click.delegate="sortBy(4)" class="mdc-icon-button material-icons mdc-data-table__sort-icon-button text-regular-plus align-middle">
                                <div id="recent-activity-up" class="position-relative sort-asc">arrow_drop_up</div>
                                <div id="recent-activity-down" class="position-relative sort-desc">arrow_drop_down</div>
                            </button>
                        </th>
                        <th>
                            \${'CX_STATUS' | siteStrings}
                            <button click.delegate="sortBy(5)" class="mdc-icon-button material-icons mdc-data-table__sort-icon-button text-regular-plus align-middle">
                                <div id="status-up" class="position-relative sort-asc">arrow_drop_up</div>
                                <div id="status-down" class="position-relative sort-desc">arrow_drop_down</div>
                            </button>
                        </th>
                        </thead>
                        <tbody>
                        <tr mdc-data-table-row repeat.for="ticket of filteredTickets" click.delegate="ticket.status === 'draft' ? switchToCreate() : switchToDetails(ticket.id)">
                            <td class="text-underline" click.delegate="switchToDetails(ticket.id)">\${ ticket.ticketKey }</td>

                            <td class="d-none d-xl-table-cell text-underline" mdc-tooltip="value.bind: ticket.title;" click.delegate="switchToDetails(ticket.id)">\${ ticket.title | textTruncate:20 }</td>
                            <td class="d-table-cell d-xl-none text-underline" mdc-tooltip="value.bind: ticket.title;" click.delegate="switchToDetails(ticket.id)">\${ ticket.title | textTruncate:10 }</td>

                            <td mdc-tooltip="value.bind: ticket.description;">\${ ticket.description | textTruncate:10 }</td>

                            <td class="d-none d-xl-table-cell" mdc-tooltip="value.bind: ticket.createdDate| dateFormatter;">\${ ticket.createdDate | dateFormatter }</td>
                            <td class="d-table-cell d-xl-none" mdc-tooltip="value.bind: ticket.createdDate| dateFormatter;">\${ ticket.createdDate | dateFormatter | textTruncate:10 }</td>

                            <td class="d-none d-xl-table-cell" mdc-tooltip="value.bind: ticket.updateDate| dateFormatter;">\${ ticket.updateDate | dateFormatter }</td>
                            <td class="d-table-cell d-xl-none" mdc-tooltip="value.bind: ticket.updateDate| dateFormatter;">\${ ticket.updateDate | dateFormatter | textTruncate:10 }</td>

                            <td>
                                <cx-status-tag
                                    status="\${ ticket.status | supportTicketStatus : ticket : user.id }"
                                    tag-styling="\${ ticket.status | entityStatus | removeWord:' Tickets':'' }"
                                ></cx-status-tag>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </mdc-data-table>
                <div show.bind="!pageLoading" repeat.for="ticket of filteredTickets" class="pseudo-table mb-2 m-display">
                    <cx-pseudo-table ticket.bind="ticket" index.bind="ticket.id" user.bind="user" click.delegate="switchToDetails(ticket.id)"></cx-pseudo-table>
                </div>
                <cx-pagination count.two-way="count" page.two-way="page"></cx-pagination>
            </div>

            <!-- Detail ticket -->
            <div if.bind="currentState === 'detail'">
                <cx-chat-view ticket-id.bind="ticketId" message-position.bind="urlParams.messagePosition"></cx-chat-view>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;