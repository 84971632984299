// Module
var code = `<template>
    <div id="cx-nav-preorder-mobile">
        <div id="selected-content">
            <mdc-deprecated-list if.bind="selectedOption.id !== 'initial'">
                <mdc-deprecated-list-item class="drawer-item drawer-back-item" click.delegate="goBack()">
                    <div class="d-flex gap-1 align-items-center">
                        <cds-icon
                            icon="arrow_left"
                            variant="filled"
                            class="arrow"
                        ></cds-icon>
                        <span if.bind="isLanguageOrCurrency(selectedOption.id)">\${selectedOption.title}</span>
                        <span if.bind="isAnInitialOption(selectedOption.id) || isAnExchangeOption(selectedOption.id)">\${selectedOption.title | siteStrings}</span>
                        <span if.bind="!isLanguageOrCurrency(selectedOption.id) && !isAnInitialOption(selectedOption.id) && !isAnExchangeOption(selectedOption.id)">\${selectedOption.title} Selected</span>
                    </div>
                </mdc-deprecated-list-item>
            </mdc-deprecated-list>

            <div class="selected-option" if.bind="selectedOption && !resultsIsEmpty">
                <mdc-deprecated-list>
                    <mdc-deprecated-list-item 
                        repeat.for="option of selectedOption.childs"
                        class="\${!['initial', 'exchange'].includes(selectedOption.id) ? \`drawer-item-displayed drawer-list-item \${isAnSeparatorOption(option.id) ? 'separator' : ''}\` : 'drawer-item drawer-list-item'} \${selectedOption.id === 'exchange' ? 'drawer-item-displayed' : ''} \${option.childs.length === 0 && !isAnSeparatorOption(option.id) && !isLanguageOrCurrency(option.category) ? 'no-border' : ''} \${option.id === 'all' && listHaveChilds() && !isAnSeparatorOption(option.id) ? 'border' : ''} \${!option.isCategory ? 'filtered-background' : ''}"
                        click.delegate="selectOption(option)"
                    >
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="icon-name-option">
                                <img
                                    if.bind="option.image"
                                    class="material-icons icon rounded rounded-5"
                                    src="\${option.image}"
                                    alt="\${option.title}"
                                    loading="lazy"
                                />
                                <span if.bind="isLanguageOrCurrency(option.id) || (!isAnInitialOption(option.id) && !isAnExchangeOption(option.id))" class="fw-medium'">\${option.title}</span>
                                <span else class="fw-medium'">\${option.title | siteStrings:option.auxname}</span>
                            </div>
                            <cds-icon
                                if.bind="(!option.isCategory && option.childs.length > 0) || (option.isCategory && (!filterInput || optionsStack.length > 0))"
                                icon="arrow_right"
                                variant="filled"
                                class="arrow"
                            ></cds-icon>
                        </div>
                    </mdc-deprecated-list-item>
                </mdc-deprecated-list>
            </div>
           
            <cx-no-results
                message="\${'CX_NO_RESULT_NAV_FILTER' | siteStrings:option.auxname}"
                show.bind="resultsIsEmpty"
            ></cx-no-results>
        </div>
    </div>
</template>
`;
// Exports
export default code;