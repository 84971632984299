// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/purchase-flow/order-completed.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/purchase-flow/summary.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/yellow_waiting.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/purchase-flow/tracking_progress.svg", import.meta.url);
// Module
var code = `<template>
    <section id="order-completed">
        <div class="container">
            <div class="box order-box purchase-flow-spacing">
                <div class="d-none d-lg-flex justify-content-between">
                    <div if.bind="isDesktop" class="d-flex breadcrumbs flex-column flex-sm-row" skeleton-loading="is-loading.bind:pageLoading;width:280px;">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <template repeat.for="breadcrumb of breadcrumbs">
                                    <li class="breadcrumb-item \${\$last ? '' : 'cursor-pointer'}" if.bind="!breadcrumb.isActive">
                                        <a href.bind="breadcrumb.href | languageRoute & async">\${breadcrumb.title}</a>
                                    </li>
                                    <li class="breadcrumb-item active text-truncate" if.bind="breadcrumb.isActive">\${breadcrumb.title}</li>
                                </template>
                            </ol>
                        </nav>
                    </div>
                    <p class="m-0 order-id text-nowrap" skeleton-loading="is-loading.bind:pageLoading;">
                        <span>\${'CX_ORDER_ID' | siteStrings}: </span>
                        \${order.id}                        
                    </p>
                </div>
                <div class="d-none d-lg-flex order-complete text-center">
                    <img src="${___HTML_LOADER_IMPORT_0___}" class="d-inline-block justify-content-center completed-icon" alt.bind="'CX_ORDER_COMPLETED' | siteStrings" loading="lazy">
                    <div class="title-container">
                        <h1 class="m-0">\${'CX_ORDER_COMPLETED' | siteStrings}</h1>
                        <p class="m-0" skeleton-loading="is-loading.bind:pageLoading;width:184px;height:14px;">\${creationDate}</p>
                    </div>
                    <div class="action-buttons-container">
                        <cx-button
                            button-text.bind="'CX_SEND_US_MESSAGE' | siteStrings"
                            button-style.bind="'purple-background font-medium text-regular text-white'"
                            click.delegate="openIntercom()"
                        ></cx-button>
                    </div>
                </div>
                <div class="d-flex d-lg-none flex-column">
                    <div class="back-button-mobile-container">
                        <cds-back-button class="back-button" skeleton-loading="is-loading.bind:pageLoading;"></cds-back-button>
                        <p class="m-0 order-id"  skeleton-loading="is-loading.bind:pageLoading;height:14px;">
                            <span>\${'CX_ORDER_ID' | siteStrings}: </span>
                            \${order.id}
                        </p>
                    </div>
                    <div class="order-complete text-center">
                        <img src="${___HTML_LOADER_IMPORT_0___}" class="d-inline-block justify-content-center completed-icon" alt.bind="'CX_ORDER_COMPLETED' | siteStrings" loading="lazy">
                        <div class="title-container">
                            <h1 class="m-0">\${'CX_ORDER_COMPLETED' | siteStrings}</h1>
                            <p class="m-0" skeleton-loading="is-loading.bind:pageLoading;width:184px;height:14px;">\${creationDate}</p>
                        </div>
                        <div class="action-buttons-container">
                            <cx-button
                                button-text.bind="'CX_SEND_US_MESSAGE' | siteStrings"
                                button-style.bind="'purple-background font-medium text-regular text-white'"
                                click.delegate="openIntercom()"
                            ></cx-button>
                        </div>
                    </div>
                </div>
                <div if.bind="isExchangeOrder" class="d-flex align-items-center text-black text-extra-large no-font-bp summary-main-title">
                    <img src="${___HTML_LOADER_IMPORT_1___}" alt="Summary" loading="lazy">
                    <span class="ms-1">\${'CX_SUMMARY' | siteStrings}</span>
                </div>
                <div class="text-regular no-font-bp text-black summary-container \${isExchangeOrder ? '' : 'info-spacing'}">
                    <div class="summary-content-grid-row d-flex align-items-center justify-content-between" if.bind="isExchangeOrder && showYouGive">
                        <div class="summary-content-grid-row__title">
                            \${'CX_SPEND' | siteStrings}
                        </div>
                        <div class="grid-justify-self-end summary-content-grid-row__data" skeleton-loading="is-loading.bind:pageLoading;width:170px;height:17px">
                            \${orderYouGive & async}
                        </div>
                    </div>
                    <div if.bind="isExchangeOrder" class="summary-content-grid-row d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <span class="summary-content-grid-row__title">\${'CX_TRANSACTION_FEE' | siteStrings}</span>
                            <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                                aria-label="tooltip"
                                id="transaction-fee-icon">
                                contact_support
                            </mdc-icon>
                            <cds-tooltip 
                                reference-id="transaction-fee-icon"
                                placement="right"
                                show-arrow="true">
                                \${'CX_TRANSACTION_FEE_TOOLTIP' | siteStrings}
                            </cds-tooltip>
                        </div>
                        <div class="grid-justify-self-end d-flex align-items-center summary-content-grid-row__data" skeleton-loading="is-loading.bind:pageLoading;width:170px;height:17px">
                            <span>\${orderTransactionFee & async}</span>
                        </div>
                    </div>
                    <div class="summary-content-grid-row" if.bind="validCoupon && isExchangeOrder">
                        <div>
                            <span class="summary-content-grid-row__title">\${'CX_COUPON_CODE_TITLE' | siteStrings}</span>
                            <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                                if.bind="validCoupon"
                                aria-label="tooltip"
                                id="coupon-code-icon">
                                contact_support
                            </mdc-icon>
                            <cds-tooltip 
                                reference-id="coupon-code-icon"
                                placement="right"
                                show-arrow="true">
                                \${couponExplanation}
                            </cds-tooltip>
                        </div>
                        <div class="grid-justify-self-end">
                            <span class="coupon-apply-amount summary-content-grid-row__data" skeleton-loading="is-loading.bind:pageLoading;width:170px;height:17px">
                                \${couponDiscountApply}
                            </span>
                        </div>
                    </div>
                    <div if.bind="isExchangeOrder" class="summary-content-grid-row d-flex align-items-center justify-content-between">
                        <div class="receive-content-wrapper">
                            <span class="summary-content-grid-row__title">
                                \${'CX_RECEIVE' | siteStrings}
                            </span>                            
                            <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                                if.bind="youReceive"
                                aria-label="tooltip"
                                id="receive-icon">
                                contact_support
                            </mdc-icon>
                            <cds-tooltip 
                                reference-id="receive-icon"
                                placement="right"
                                show-arrow="true"
                                if.bind="youReceive">
                                    <div class="d-flex flex-column" 
                                        receive-tooltip-content="ordered-exchange.bind: updatedOrderCryptoExchange & signal:'update-receive-tooltip-content'">
                                    </div>
                            </cds-tooltip>
                        </div>
                        <div class="grid-justify-self-end d-flex align-items-center summary-content-grid-row__data" skeleton-loading="is-loading.bind:pageLoading;width:170px;height:17px">
                            \${orderYouReceive & async}
                        </div>
                    </div>
                    <div class="summary-content-grid-row d-flex align-items-center justify-content-between" if.bind="isExchangeOrder && (order.orderDetail.cashInPersonDetail.meetingType || order.orderDetail.cashInMailDetail.deliveryType)">
                        <div class="summary-content-grid-row__title">
                            \${'CX_DELIVERY_TIME' | siteStrings}
                        </div>
                        <div class="grid-justify-self-end" skeleton-loading="is-loading.bind:pageLoading;width:170px;height:17px">
                            <span if.bind="order.receivingMethod.reference === 'cash-in-person'" class="ms-1 summary-content-grid-row__data">
                                \${order.orderDetail.cashInPersonDetail.meetingType} delivery
                            </span>
                            <span
                                else
                                class="ms-1 summary-content-grid-row__data"
                            >
                            \${order.orderDetail.cashInMailDetail.deliveryType} delivery
                            </span>
                        </div>
                    </div>
                    <div if.bind="isExchangeOrder" class="summary-content-grid-row d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <span class="summary-content-grid-row__title">\${'CX_RECIPIENT' | siteStrings}</span>                            
                            <div class="tooltip-container">
                                <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                                    if.bind="order.receivingMethod.reference === 'cash-in-person'" id="recipient-icon">
                                    contact_support
                                </mdc-icon>
                                <cds-tooltip 
                                    reference-id="recipient-icon"
                                    placement="right"
                                    show-arrow="true">
                                     \${'CX_MEETUP_TOOLTIP_1' | siteStrings:'params':[order.orderDetail.cashInPersonDetail.location, order.orderDetail.cashInPersonDetail.meetingType.replace('days', 'business days'), order.orderDetail.cashInPersonDetail.time, timezone]}
                                    <br><br>
                                    \${'CX_MEETUP_TOOLTIP_2' | siteStrings}
                                </cds-tooltip>
                            </div>
                        </div>
                        <div class="recipient-container grid-justify-self-end" skeleton-loading="is-loading.bind:pageLoading;width:170px;height:17px">
                            <span
                                if.bind="order.orderCryptoExchanges[0].walletAddress"
                                class="ms-1 summary-content-grid-row__data"
                                mdc-tooltip="value.bind: order.orderCryptoExchanges[0].walletAddress"
                                alt="tooltip"
                            >
                                \${orderPaymentMethod}: \${walletAddress}
                            </span>
                            <div else>
                                <div if.bind="helper.includesSome(order.receivingMethod.reference, ['cash-in-person', 'cash-in-mail'])">
                                    <span class="ms-1 summary-content-grid-row__data" if.bind="order.receivingMethod.reference === 'cash-in-person'">
                                        \${order.orderDetail.cashInPersonDetail.location}
                                    </span>
                                    <span class="ms-1 summary-content-grid-row__data" else>
                                        \${user.firstName}, \${order.orderDetail.cashInMailDetail.address}, \${order.orderDetail.cashInMailDetail.state}, \${order.orderDetail.cashInMailDetail.city}, \${order.orderDetail.cashInMailDetail.zipCode}
                                    </span>
                                </div>
                                <span else class="ms-1 summary-content-grid-row__data">
                                    \${order.receivingMethod.name ? \`\${order.receivingMethod.name}: \` : ''}\${user.email}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="summary-content-grid-row d-flex align-items-center justify-content-between" if.bind="isExchangeOrder && showDeliveryFee">
                        <div class="d-flex align-items-center">
                            <span class="summary-content-grid-row__title">\${'CX_DELIVERY_FEE' | siteStrings}</span>
                            <div class="tooltip-container">
                                <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub" id="delivery-fee-icon">
                                    contact_support
                                </mdc-icon>
                                <cds-tooltip 
                                    reference-id="delivery-fee-icon"
                                    placement="right"
                                    show-arrow="true">
                                    \${'CX_DELIVERY_FEE_TOOLTIP_2' | siteStrings}
                                </cds-tooltip>
                            </div>
                        </div>
                        <div class="grid-justify-self-end d-flex align-items-center">
                            <span class="ms-1 summary-content-grid-row__data" skeleton-loading="is-loading.bind:pageLoading;width:150px;height:17px">
                                \${orderDeliveryFee & async}
                            </span>
                            <div if.bind="isManualOrder">
                                <span class="material-icons text-lilac-action watch-icon" id="delivery-update-icon">update</span>
                                <cds-tooltip 
                                    reference-id="delivery-update-icon"
                                    placement="left"
                                    show-arrow="true">
                                    \${'CX_COIN_PRICE_CHANGE_MESSAGE' | siteStrings}
                                </cds-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="normal-order-details" if.bind="!isExchangeOrder">
                        <div class="payment-information-title">
                            <p class="m-0">
                                \${'CX_PAYMENT_INFORMATION' | siteStrings}
                            </p>
                            <cx-line
                                color="purple"
                                class="m-0"
                                width="65"
                            ></cx-line>
                        </div>
                        <cx-table
                            table-headers.bind="tableHeaders"
                            header-width.bind="isOrderSubscription ? 80 : 116"
                        >
                            <!-- Balance -->
                            <cx-order-row
                                if.bind="!isOrderSubscription"
                                ref="orderRow"
                                order.bind="mappedOrderRow"
                                width.bind="width"
                                no-hover="true"
                                column-width.bind="116"
                                container-left-padding.bind="15"
                                status-column-width.bind="129"
                                status-column-width-tablet.bind="96"
                                type="OrderCompleted"
                                skeleton-loading="is-loading.bind:pageLoading"
                            ></cx-order-row>
                            <!-- Subscription -->
                            <cx-order-row
                                if.bind="isOrderSubscription"
                                ref="orderRow"
                                order.bind="mappedOrderSubscriptionRow"
                                width.bind="width"
                                no-hover="true"
                                column-width.bind="80"
                                container-left-padding.bind="15"
                                status-column-width.bind="80"
                                status-column-width-tablet.bind="96"
                                type="OrderCompleted"
                                is-subscription.bind="isOrderSubscription"
                                user.bind="user"
                                skeleton-loading="is-loading.bind:pageLoading"
                            ></cx-order-row>
                        </cx-table>
                    </div>
                    <div class="order-status purchase-flow-spacing about-spacing">
                        <h1 class="about-title text-start">
                            \${'CX_ABOUT_YOUR_ORDER' | siteStrings}
                        </h1>
                        <!-- Order status validation -->
                        <div if.bind="order.paymentMethod.reference == 'interac'" class="pending-verification grid-justify-self-end d-inline-block">
                            <img src="${___HTML_LOADER_IMPORT_2___}" class="d-inline-block payment-verification-icon" alt="payment in process" loading="lazy">
                            <div class="d-inline-block font-light">
                                \${'CX_PAYMENT_BEING_VERIFIED' | siteStrings}
                            </div>
                        </div>
                    </div>
                    <div class="about-us-content text-regular text-darkergray">
                        <p class="m-0 order-thanks-paragraph">
                            <span skeleton-loading="is-loading.bind:pageLoading;">\${'CX_THANKS_FOR_ORDERING' | siteStrings} \${user.firstName}.</span>
                            </br>
                            <span skeleton-loading="is-loading.bind:pageLoading;">
                                \${'CX_HERE_IS_YOUR_RECEIPT_2' | siteStrings:'params':[order.website.name, orderAmountCharged]}
                            </span>
                        </p>
                        <div class="d-flex justify-content-between flex-wrap align-items-center purchase-flow-spacing about-spacing">
                            <div if.bind="showSimplePaymentMethod" class="line-1" skeleton-loading="is-loading.bind:pageLoading;">
                                <img id="payment-method-image" class="payment-method-image" src="\${baseAwsEndpoint}\${order.paymentMethod.imagePath}" alt.bind="order.paymentMethod.name" loading="lazy">
                                <span class="text-medium-black font-regular">\${order.paymentMethod.name}</span>
                            </div>
                            <div else class="detailed-payment-method-container" skeleton-loading="is-loading.bind:pageLoading;">
                                <img id="payment-method-image" class="payment-method-image" src="\${methodImageSource}" alt.bind="order.paymentMethod.name" loading="lazy">
                                <div class="payment-method-description-container">
                                    <p class="m-0 description">\${paymentMethodDescription}</p>
                                    <p class="m-0 holder">\${paymentMethodHolderName}</p>
                                </div>
                            </div>
                            <cx-timer
                                if.bind="isManualOrder"
                                time-limit.bind="15"
                                auto-restart.bind="autoRestart"
                                reverse-row.bind="true"
                                text.bind="'CX_LIVE_RATE_UPDATE_MESSAGE' | siteStrings"
                                element.ref="timer"
                                class="text-normal sub-lh timer"
                                skeleton-loading="is-loading.bind:pageLoading;"
                            ></cx-timer>
                        </div>
                    </div>
                    <div class="summary-content-grid-row-line"></div>
                    <div class="order-support-section">
                        <div class="flex-center-between w-100">
                            <a class="email-option" href="mailto:support@chicksx.com">
                                support@chicksx.com
                            </a>
                            <a class="help-option" href="\${contactUsPageRoute | languageRoute & async}">
                                \${'CX_GET_HELP' | siteStrings}
                            </a>
                        </div>
                        <div class="support-actions-container">
                            <cx-button
                                click.delegate="resendReceipt()"
                                button-text.bind="'CX_RESEND_RECEIPT' | siteStrings"
                                button-style.bind="'secondary resend-receipt font-medium ps-3 button-h50'"
                            ></cx-button>
                            <cx-button
                                click.delegate="handleExportingPdf()"
                                button-text.bind="'CX_DOWNLOAD_PDF' | siteStrings"
                                button-style.bind="'purple-background download-pdf font-medium text-white'"
                            ></cx-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Address verification -->
            <div tabindex="0" focusout.delegate="focusHandler(\$event) & debounce:100" class="single-container mt-4 \${activeVerificationTabs['address'] && !user.addressVerified ? 'active' : 'update'} mb-4 d-flex \${!user.addressVerified ? 'cursor-pointer' : ''} \${!user.idVerified ? 'disabled' : ''}" if.bind="false">
                <div class="d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <span class="material-icons-outlined text-cx-purple me-2 text-extra-huge-plus">
                            fmd_good
                        </span>
                        <div class="d-flex flex-column">
                            <span class="section-header text-darkergray">\${'CX_ADDRESS_VERIFICATION' | siteStrings}</span>
                            <span if.bind="!user.addressVerified" class="section-subtitle text-darkergray">\${'CX_PENDING' | siteStrings}</span>
                        </div>
                    </div>
                </div>
                <div class="mb-4 d-flex">
                    <div show.bind="!user.addressVerified" class="au-animate animate-fade-in animate-fade-out input-outer-container" click.delegate="\$event.stopPropagation()">
                        <div class="input-inner-container">
                            <span if.bind="!fileUploaded" class="text-darkergray text-small mt-2">\${'CX_UPLOAD_DOCUMENT_FRONT' | siteStrings}</span>
                            <form submit.delegate="catchSubmit()" class="position-relative mt-2">
                                <mdc-text-field
                                    outlined
                                    autocomplete="off"
                                    id="fileHolder"
                                    class="w-100 input-password cx-form-control \${ user.addressVerified ? 'cx-form-control--success' : '' } \${ showErrorMark ? 'cx-form-control--error' : '' } \${ isAddressInReview ? 'cx-form-control--warning' : '' }"
                                    required.bind="true"
                                    placeholder.bind="'CX_SELECT_DOCUMENT_PROMPT' | siteStrings"
                                    click.delegate="triggerDocumentSelector()"
                                    value.bind="fileName"
                                    disabled
                                    ref="addressInput"
                                ></mdc-text-field>
                                <span if.bind="!user.addressVerified && !showErrorMark && !isAddressInReview && !addressUploading" class="material-icons-outlined text-cx-purple upload-icon">upload_outlined</span>
                                <span if.bind="user.addressVerified" class="circle-icon check text-large position-absolute material-icons-outlined" alt="green check">check_circle</span>
                                <span if.bind="showErrorMark" class="circle-icon error text-large material-icons" alt="error">error_outline</span>
                                <span if.bind="isAddressInReview" class="circle-icon_close text-large material-icons-outlined" alt="waiting">watch_later</span>
                                <span if.bind="isAddressInReview && !addressUploading" class="material-icons-outlined text-cx-purple upload-icon disabled">upload_outlined</span>
                                <mdc-circular-progress if.bind="addressUploading" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                            </form>
                        </div>
                    </div>
                </div>
                <input type="file"
                    id="documentSelector"
                    files.bind="selectedFiles"
                    input.delegate="handleAddressUpload(selectedFiles)"
                    class="d-none"
                >
            </div>
            <div if.bind="order.paymentMethod.reference == 'cash-in-mail'" class="box order-box tracking-box purchase-flow-spacing">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="text-large-plus mt-2">
                        <img src="${___HTML_LOADER_IMPORT_3___}" alt="tracking" loading="lazy">
                        \${'CX_TRACKING_PROGRESS' | siteStrings}
                    </div>
                    <div class="d-none d-sm-flex flex-column align-items-end text-darkergray text-regular">
                        <div>
                            \${'CX_EXPECTED_ARRIVAL' | siteStrings}:
                            <span skeleton-loading="is-loading.bind:pageLoading;width:80px">\${ trackingData.est_delivery_date | dateFormatter }</span>
                        </div>
                        <span>\${'CX_TRACKING_NUMBER' | siteStrings}: <span class="font-bold text-purple">#\${trackingData ? trackingData.tracking_code : order.easyPostTrackingId}</span></span>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-center w-100">
                    <cx-tracking-progress class='w-100' num-steps.bind="5" tracking-data.bind="trackingData" is-tooltip.bind="false"></cx-tracking-progress>
                </div>
                <div class="d-flex d-sm-none flex-column align-items-start text-darkergray text-regular">
                    <div>
                        \${'CX_EXPECTED_ARRIVAL' | siteStrings}:
                        <span skeleton-loading="is-loading.bind:pageLoading;width:80px">\${ trackingData.est_delivery_date | dateFormatter }</span>
                    </div>
                    <span>\${'CX_TRACKING_NUMBER' | siteStrings}: <span class="font-bold text-purple">#\${trackingData ? trackingData.tracking_code : order.easyPostTrackingId}</span></span>
                </div>
            </div>
        </div>
    </section>
</template>
`;
// Exports
export default code;