// Module
var code = `<template>
    <div id="horizontal-list-container">
        <div class="fade-in" if.bind="currentIndex > 0"></div>
        <div class="horizontal-list" ref="listContainer">
            <slot></slot>
        </div>
        <div class="fade-out"></div>
        <cds-icon
            icon="arrow_right"
            variant="filled"
            class="arrow right-arrow"
            click.trigger="scrollRight()"
        >
        </cds-icon>
    </div>
</template>
`;
// Exports
export default code;