// Module
var code = `<template>
    <div id="balance">
        <cx-card class.bind="'balance-view-container'">
            <div class="title-container">
                <h1 class="page-title" skeleton-loading="is-loading.bind:contentLoading;width:89px;height:32px;">
                    <cx-page-content-area 
                        key.bind="'CX_BALANCE_TITLE'"
                    ></cx-page-content-area>
                </h1>
                <p class="page-subtitle" skeleton-loading="is-loading.bind:contentLoading;width:191px;height:14px;">
                    <cx-page-content-area 
                        key.bind="'CX_BALANCE_TEXT'"
                    ></cx-page-content-area>
                </p>
            </div>
            <div class="options-container">
                <!-- TODO: this is not changing on admin view -->
                <cds-large-button
                    icon="manage_search"
                    title.bind="'CX_HISTORY' | siteStrings"
                    description.bind="historyDescription"
                    onclick.call="redirectTo('history')"
                    skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                ></cds-large-button>
                <cds-large-button
                    icon="monetization_on"
                    title.bind="'CX_TOP_UP' | siteStrings"
                    description.bind="'CX_ADD_FUNDS_BALANCE' | siteStrings"
                    onclick.call="redirectTo('top-up')"
                    skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                ></cds-large-button>
                <cds-large-button
                    icon="savings"
                    title.bind="'CX_WITHDRAW' | siteStrings"
                    description.bind="'CX_WITHDRAW_BALANCE_TO_PAYMENT_METHOD' | siteStrings"
                    onclick.call="redirectTo('withdraw')"
                    skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                ></cds-large-button>
            </div>
        </cx-card>
    </div>
</template>
`;
// Exports
export default code;