// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/database.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-currency-selector">
        <div class="text-end align-items-center position-relative dropdown">
            <button
                id="currency-selector-\${id}"
                class="cx-select cx-form-select currency-selector-section w-100 position-relative text-start cursor-pointer align-items-center d-flex text-normal"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                data-bs-auto-close="outside"
                aria-expanded="false"
                type="button"
                focusin.delegate="handleTextFieldFocusIn()"
                focusout.delegate="handleTextFieldFocusOut()"
            >
                <div class="d-flex align-items-center">
                    <div class="col">
                        <mdc-text-field
                            id="currency-selector-search-\${id}"
                            view-model.ref="input"
                            autocomplete="on"
                            label.bind="currencyLabel"
                            class="w-100 p-0 cx-form-select payment-method-text-field \${currencySelected ? 'selected' : ''}"
                            change.delegate="handleSearchChange(\$event)"
                            keyup.delegate="handleSearchChange(\$event)"
                        >
                            <mdc-icon mdc-text-field-icon leading class="material-icons material-icons-outlined">
                                <img
                                    if.bind="currencySelected.imagePath && !focusIn" 
                                    class="aling-items-left selected-currency-img"
                                    src="\${baseAwsEndpoint}\${currencySelected.imagePath}"
                                    loading="lazy" 
                                    alt="currency-flag">
                                <img
                                    else
                                    class="aling-items-left selected-currency-icon"
                                    src="${___HTML_LOADER_IMPORT_0___}"
                                    loading="lazy" 
                                    alt="currency-icon">
                            </mdc-icon>
                        </mdc-text-field>
                    </div>
                </div>
                <span class="material-icons expand-icon">
                    arrow_drop_down
                </span>
            </button>
            <div name="currency-dropdown-menu" id="currency-dropdown-menu-\${id}" class="dropdown-menu text-start expanded-selected" aria-labelledby="currency-selector-\${id}">
                <section data-simplebar class="payment-method-scrollbar purple-scrollbar">
                    <div
                        repeat.for="currency of currencyList"
                        if.bind="search ? currency.show : true"
                        >
                        <div class="text-small text-darkergray currency-area-header" if.bind="!search && \$index === 0">
                            Trending Currencies
                        </div>
                        <div class="text-small text-darkergray currency-area-header other-currencies" if.bind="!search && \$index === 3">
                            Other Currencies
                        </div>
                        <div class="text-small mb-2 text-darkergray" if.bind="search && search !== '' && \$index === firstResultIndex">
                            Results
                        </div>
                        <div class="currency-item \${\$last ? 'border-0' : ''}" click.delegate="selectCurrency(currency)">
                            <div class="cursor-pointer \${\$last ? '' : 'darkblue-border-bottom'}">
                                <div class="d-flex">
                                    <div class="pe-2">
                                        <div class="currency-img d-inline-flex" if.bind="currency.imagePath">
                                            <img if.bind="currency.imagePath" src="\${baseAwsEndpoint}\${currency.imagePath}" alt="currency-flag" loading="lazy" class="aling-items-left">
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <div class="w-100 text-regular-plus text-darkergray currency-selector-dropdown truncate-text-1-b">
                                            <div 
                                                class="w-100 animated-text" 
                                                mouseover.trigger="hoverCurrency(\$event, currency) & debounce:100"
                                                mouseout.trigger="hoverOutCurrency(\$event, currency) & debounce:100">
                                                \${currency.code} - \${currency.shortName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;