import {
    autoinject,
    bindable,
    computedFrom,
    observable,
    TaskQueue,
} from 'aurelia-framework';
import './cx-nav-preorder.scss';
import {
    Currency,
    PaymentMethodWebsite,
} from 'services/models/purchase-flow/exchange';
import { getAWSBucketEndpoint } from 'environment';
import { WebsiteService } from 'services/website-service';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SessionService } from 'services/session-service';
import { LangHelper } from 'resources/helpers/lang-helper';

@autoinject()
export class CxNavPreorder {
    @bindable preoderTypeAction: 'buy' | 'sell' | 'exchange';
    @bindable cryptoList: Currency[];
    @bindable currencyList: Currency[];
    @bindable paymentMethodsList: PaymentMethodWebsite[];
    @bindable maxVisibleOptions: number = 6;
    private taskQueue: TaskQueue;

    exchangeType: string = 'FF';

    @computedFrom('exchangeType')
    get exchangeTypeComputed(): string {
        return this.exchangeType;
    }

    leftList = [];
    leftListFiltered = [];
    rightList = [];
    rightListFiltered = [];

    leftFilter: string = '';
    rightFilter: string = '';

    @observable selectedLeft: string;
    @observable selectedRight: string;

    private tradePageRoute: string;

    visibilityListItems: number = 6;

    currentLanguage;

    contentAction = {
        buy: {
            title: 'CX_BUY_CRYPTO',
            leftSideTitle: 'CX_SELECT_CRYPTO_PURCHASE',
            rightSideTitle: 'CX_SELECT_PAYMENT_METHOD_PROCEED',
            leftSidePlaceHolder: 'CX_SELECT_CRYPTO_BUY',
            rightSidePlaceHolder: 'CX_SEARCH_PAYMENT_METHOD_PROCEED',
        },
        sell: {
            title: 'CX_SELL_CRYPTO',
            leftSideTitle: 'CX_TRENDING_CRYPTO',
            rightSideTitle: 'CX_TRENDING_PAYMENT_METHODS',
            leftSidePlaceHolder: 'CX_SELECT_CRYPTO_SELL',
            rightSidePlaceHolder: 'CX_SEARCH_PAYMENT_METHOD_PROCEED',
        },
        exchange: {
            title: 'CX_EXCHANGE',
            FF: {
                leftSideTitle: 'CX_TRENDING_CURRENCIES_EXCHANGE',
                rightSideTitle: 'CX_TRENDING_CURRENCIES_PROCEED',
                leftSidePlaceHolder: 'CX_SELECT_FIAT_EXCHANGE',
                rightSidePlaceHolder: 'CX_SELECT_FIAT_PROCEED',
            },
            CC: {
                leftSideTitle: 'CX_TRENDING_CRYPTO_EXCHANGE',
                rightSideTitle: 'CX_TRENDING_CRYPTO_PROCEED',
                leftSidePlaceHolder: 'CX_SELECT_CRYPTO_EXCHANGE',
                rightSidePlaceHolder: 'CX_SELECT_CRYPTO_PROCEED',
            },
        },
    };

    baseAwsEndpoint: string;
    basePaymentMethodsEndpoint: string;

    constructor(
        private websiteService: WebsiteService,
        private router: Router,
        taskQueue: TaskQueue,
        private eventAggregator: EventAggregator,
        private pageContentAreaService: PageContentAreaService,
        private sessionService: SessionService,
        private langHelper: LangHelper
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
        this.basePaymentMethodsEndpoint =
            getAWSBucketEndpoint('payment-methods');
        this.taskQueue = taskQueue;
    }

    async activate() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.tradePageRoute =
            pages.find((x) => x.name === 'Trade')?.routeName ?? 'trade';
    }

    async attached() {
        this.selectedLeft = this.selectedRight = '';
        this.updateLists();
        this.currentLanguage = await this.sessionService.getLanguage();
    }

    preoderTypeActionChanged() {
        this.updateLists();
        this.cleanSelectedOptions();
    }

    exchangeTypeChanged() {
        this.updateLists();
        this.cleanSelectedOptions();
        this.eventAggregator.publish('nav-fix-content');
    }

    selectedChanged(type: string = 'Left') {
        const listSelector = {
            left: 'right',
            right: 'left'
        };
        this.allSelectedPreorderEvent();
        if (this[`selected${type}`]) this.eventAggregator.publish('nav-fix-content', { fixContent: true });
        if (this.preoderTypeAction !== 'exchange') return;
        const typeLower = type.toLowerCase();
        this[`${listSelector[typeLower]}ListFiltered`] = this[`${typeLower}List`]?.filter(
            ({ id }) => id !== this[`selected${type}`]
        );
    }

    selectedLeftChanged = () => this.selectedChanged();
    selectedRightChanged = () => this.selectedChanged('Right');

    filterLeftOptions = () => this.filterListOptions();
    filterRightOptions = () => this.filterListOptions('right');

    private filterListOptions(list: string = 'left') {
        const oppositeSelected = list === 'left' ? this.selectedRight : this.selectedLeft;
        this[`${list}ListFiltered`] = this[`${list}List`]?.filter(({ id }) => id !== oppositeSelected).filter(({ code, name }) =>
            (code as string)
                .toLocaleLowerCase()
                .includes(this[`${list}Filter`].trim().toLowerCase()) ||
            (name as string)
                .toLocaleLowerCase()
                .includes(this[`${list}Filter`].trim().toLowerCase())
        );
    }

    private updateLists() {
        this.leftListFiltered = this.leftList = this.formattingCurrencyList(
            ['buy', 'sell'].includes(this.preoderTypeAction) || this.exchangeType === 'CC' ? this.cryptoList : this.currencyList
        );

        if (this.preoderTypeAction !== 'exchange') {
            this.rightListFiltered = this.rightList = this.formattingMethodList(this.paymentMethodsList);
            return;
        }

        if (this.exchangeType === 'FF') {
            this.rightListFiltered = this.rightList = this.formattingCurrencyList(this.currencyList);
            return;
        }

        this.rightListFiltered = this.rightList = this.formattingCurrencyList(this.cryptoList);
    }

    private cleanSelectedOptions() {
        this.selectedLeft = '';
        this.selectedRight = '';
        this.rightFilter = '';
        this.leftFilter = '';
    }

    cleanFilter(filterSide = 'left') {
        this[`${filterSide}Filter`] = '';
        this.filterListOptions(filterSide);
    }

    allSelectedPreorderEvent() {
        if (this.preoderTypeAction === 'exchange' && (!this.selectedLeft || !this.selectedRight)) return;
        if (['buy', 'sell'].includes(this.preoderTypeAction) && !this.selectedLeft) return;

        let route = '';
        const objectParams = {
            give: undefined,
            receive: undefined,
            method: undefined,
            exchangeType: undefined
        };

        const leftCurrencyCode = this.leftListFiltered.find(c => c.id === this.selectedLeft)?.code.toLowerCase();
        const rightCurrencyCode = this.rightListFiltered.find(c => c.id === this.selectedRight)?.code.toLowerCase();

        if (this.preoderTypeAction === 'sell') {
            route += `/sell/${leftCurrencyCode}`;
            objectParams.give = this.selectedLeft;
            objectParams.exchangeType = 'S';
            objectParams.method = this.selectedRight?.length ? this.selectedRight : undefined;
        }

        if (this.preoderTypeAction === 'buy') {
            route += `/buy/${leftCurrencyCode}`;
            objectParams.receive = this.selectedLeft;
            objectParams.exchangeType = 'B';
            objectParams.method = this.selectedRight?.length ? this.selectedRight : undefined;
        }

        if (this.preoderTypeAction === 'exchange' && this.exchangeType === 'CC') {
            route = `/swap/crypto/${leftCurrencyCode}-to-${rightCurrencyCode}`;
            objectParams.give = this.selectedLeft;
            objectParams.receive = this.selectedRight;
            objectParams.exchangeType = 'CC';
        }

        if (this.preoderTypeAction === 'exchange' && this.exchangeType === 'FF') {
            route = `/swap/fiat/${leftCurrencyCode}-to-${rightCurrencyCode}`;
            objectParams.give = this.selectedLeft;
            objectParams.receive = this.selectedRight;
            objectParams.exchangeType = 'FF';
        }

        if (objectParams.method)
            this.sessionService.saveSelectedPaymentMethod(objectParams.method);

        const hrefLang = ['en', 'en-US'].includes(this.currentLanguage.hrefLang) ? '' : `/${this.currentLanguage.hrefLang}`;
        this.router.navigate(`${hrefLang}${route}`);
        const currentRoute = this.router.currentInstruction.config.route;
        if (currentRoute.includes('buy') || currentRoute.includes('sell') || currentRoute.includes('trade'))
            this.eventAggregator.publish('new-order-from-navbar', objectParams);

        this.eventAggregator.publish('nav-fix-content', { fixContent: false });
    }

    formattingCurrencyList(currency: Currency[]) {
        return currency?.map(curr => ({
            id: curr.reference || curr.code,
            haveImage: !!curr?.imagePath,
            imagePath: `${this.baseAwsEndpoint}${curr.imagePath}`,
            code: curr.code,
            name: curr.shortName
        }));
    }

    formattingMethodList(method: PaymentMethodWebsite[]) {
        return method?.map(method => ({
            id: method.paymentMethod.reference,
            haveImage: !!method?.paymentMethod?.imagePath,
            imagePath: `${this.basePaymentMethodsEndpoint}${method.paymentMethod.imagePath}`,
            code: method.paymentMethod.name,
            name: method.paymentMethod.name,
        }));
    }

    get rightListFilteredIsEmpty() {
        return this.rightListFiltered?.length === 0;
    }

    get leftListFilteredIsEmpty() {
        return this.leftListFiltered?.length === 0;
    }
}
