// Module
var code = `<template>
    <div id="cx-footer-menu">
        <cds-menu 
            typeahead-controller.bind="typeaheadController"
            menuopening.trigger="openMenu = true"
            menuclosing.trigger="openMenu = false">
            <a slot="menu-anchor" class="menu-anchor">
                \${link.keyName | siteStrings}
                <cds-icon class="d-none d-md-block footer-menu-icon \${openMenu ? 'active' : 'inactive'}" icon="arrow_drop_down"></cds-icon>
                <cds-icon class="d-md-none footer-menu-icon \${openMenu ? 'active' : 'inactive'}" icon="keyboard_arrow_down"></cds-icon>
            </a>
            <md-menu-item if.bind="link.showFilter" keep-open="true" slot="menu-options" class="menu-items-filter">
                <cds-option-box>
                    <cds-text-field
                        leading-icon="search"
                        variant="filled"
                        value.two-way="keyword & debounce: 400"
                        label="\${'CX_FILTER_PAGES' | siteStrings}"
                        stay-open-on-focusout="true"
                        stay-open-on-outside-click="true"
                    >
                    </cds-text-field>
                </cds-option-box>
            </md-menu-item>
            <md-menu-item slot="menu-options" repeat.for="item of filteredItems">
                <cds-option-box click.trigger="navigateToRoute(item)">\${item.name}</cds-option-box>
            </md-menu-item>
            <md-menu-item slot="menu-options" show.bind="!filteredItems.length">
                <cx-no-results
                    message="\${'CX_NO_ENTRIES_TO_SHOW' | siteStrings}"
                ></cx-no-results>
            </md-menu-item>
        </cds-menu>
    </div>
</template>
`;
// Exports
export default code;