// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../../static/icons/no-results.svg", import.meta.url);
// Module
var code = `<template >
    <div id="cx-no-results" if.bind="show">
        <img
            src="${___HTML_LOADER_IMPORT_0___}"
            loading="lazy"
        />
        <span>\${message}</span>
    </div>
</template>
`;
// Exports
export default code;