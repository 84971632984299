// Module
var code = `<template>
    <section id="orders">
        <cx-section
            title.bind="'CX_ORDERS' | siteStrings"
            description.bind="'CX_MANAGE_ORDERS' | siteStrings"
            content-loading.bind="contentLoading"
        >
            <div slot="content" class="options">
                <cds-large-button
                    class="orders-large-button"
                    title.bind="'CX_BUY' | siteStrings"
                    description.bind="buyDescription & async"
                    icon="shopping_bag"
                    onclick.call="redirectTo('purchased')"
                    is-disabled.bind="countByType[OrderOperationType.Buy] <= 0"
                    skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);">
                </cds-large-button>
                <cds-large-button
                    class="orders-large-button"
                    title.bind="'CX_SELL' | siteStrings"
                    description.bind="sellDescription & async"
                    icon="local_offer"
                    onclick.call="redirectTo('sold')"
                    is-disabled.bind="countByType[OrderOperationType.Sell] <= 0"
                    skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);">
                </cds-large-button>
                <cds-large-button
                    class="orders-large-button"
                    title.bind="'CX_SWAP' | siteStrings"
                    description.bind="exchangeDescription & async"
                    icon="swap_horizontal_circle"
                    onclick.call="redirectTo('exchange')"
                    is-disabled.bind="countByType[OrderOperationType.Exchange] <= 0"
                    skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);">
                </cds-large-button>
            </div>
        </cx-section>
    </section>
</template>
`;
// Exports
export default code;