import { Currency } from '../purchase-flow/exchange';

export class CurrencyCryptoPriceCard {
    code: string;
    priceUSD: number;
    price: number;
    base: Currency;
    changePercentLast24: number;
    redirectToRoute?: string;
}
