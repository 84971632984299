import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import './cx-language-currency-selector.scss';

import { autoinject, computedFrom } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { LanguageService } from 'services/language-service';
import { CurrencyService } from 'services/currency-service';
import { Currency } from 'services/models/purchase-flow/exchange';
import { Language } from 'services/models/language/language';
import { CurrencyChangedEvent } from 'resources/constants';
import { CdsAutocomplete } from '@chicksgroup/web-components';

@autoinject()
export class CxLanguageCurrencySelector {
    currencies: Currency[] = [];
    selectedCurrency: Currency;
    savedCurrency: Currency;
    languages: Language[] = [];
    selectedLanguage: Language;

    currencyAutocompleteRef: CdsAutocomplete;
    languageAutocompleteRef: CdsAutocomplete;

    typeaheadController = {
        active: true, onKeydown: () => { }
    };

    constructor(
        private sessionService: SessionService,
        private currencyService: CurrencyService,
        private languageService: LanguageService,
        private eventAggregator: EventAggregator,
        private router: Router
    ) {
    }

    async attached() {
        try {
            await this.initData();
            setTimeout(() => {
                this.setDefaultValues();
            }, 1000);
        } catch (e) {
            console.log(e);
        }
    }

    async initData() {
        this.currencies = await this.currencyService.getCurrencyOptions();
        this.languages = await this.languageService.getLanguages();
        const currency = await this.sessionService.getCurrency();
        const language = await this.sessionService.getLanguage();
        this.selectedCurrency = this.currencies.find(curr => curr.code === currency);
        this.selectedLanguage = this.languages.find(lng => lng.id === language.id);
        this.handleLanguageForHtml();
    }

    private setDefaultValues() {
        if (this.selectedCurrency) {
            const currencyItemIndex = this.currencyAutocompleteItems.findIndex(option => option.value === this.selectedCurrency.id);
            this.currencyAutocompleteRef.selectItem(currencyItemIndex);
        }
        if (this.selectedLanguage) {
            const langugageItemIndex = this.languageAutocompleteItems.findIndex(option => option.value === this.selectedLanguage.id);
            this.languageAutocompleteRef.selectItem(langugageItemIndex);
        }
    }

    @computedFrom('languages')
    get languageAutocompleteItems() {
        return this.languages.map(option => ({
            label: option.name,
            value: option.id,
            selected: option.id === this.selectedLanguage?.id
        }));
    }

    @computedFrom('currencies')
    get currencyAutocompleteItems() {
        return this.currencies.map(option => ({
            label: option.code,
            value: option.id,
            icon: option.code,
            selected: option.id === this.selectedCurrency?.id
        }));
    }

    async languageSelected(event) {
        this.selectedLanguage = this.languages.find(lng => lng.id === parseInt(event.detail.value));
        const prevLanguage = await this.sessionService.getLanguage();
        if (prevLanguage.id === this.selectedLanguage.id) return;
        this.sessionService.setLanguage(this.selectedLanguage);
        this.changeLanguageURL(prevLanguage, this.selectedLanguage);
        this.setDefaultValues();
        location.reload();
    }

    changeLanguageURL(prevLanguage: Language, selectedLanguage: Language) {
        const hrefLang = ['en', 'en-US'].includes(selectedLanguage.hrefLang) ? '' : selectedLanguage.hrefLang;
        let currentUrl = this.router.currentInstruction.fragment;
        if (!['en', 'en-US'].includes(prevLanguage.hrefLang)) {
            currentUrl = currentUrl.replace(prevLanguage.hrefLang, '');
        }
        this.router.navigate([hrefLang, currentUrl].join('/').split('/').filter(e => e).join('/'));
    }

    currencySelected(event) {
        this.selectedCurrency = this.currencies.find(curr => curr.id === parseInt(event.detail.value));
        this.setDefaultValues();
        this.eventAggregator.publish(CurrencyChangedEvent, { currencySelected: this.selectedCurrency, avoidUpdate: true });
    }

    handleLanguageForHtml = () => {
        const language = this.selectedLanguage;
        if (language) {
            const htmlElement = document.querySelector('html');
            if (htmlElement && language) htmlElement.setAttribute('lang', language.hrefLang);
        }
    };

    languageAutocompleteClosed() {
        this.setDefaultValues();
    }

    currencyAutocompleteClosed() {
        this.setDefaultValues();
    }
}
