// Module
var code = `<template>
    <div id="footer-dropdown" class="footer-dropdown inactive" ref="dropdown">
        <div class="dropdown-top d-flex align-items-center justify-content-between" click.trigger="dropdownToggle()">
            <span>\${title}</span>
            <cds-icon class="dropdown-top-icon" icon="keyboard_arrow_down"></cds-icon>
        </div>
        <div class="dropdown-content">
            <template repeat.for="link of links" containerless>
                <a if.bind="!link.isDropdownMenu" 
                    click.delegate="closeDropdowns()"
                    href="/\${link.routeName | languageRoute & async}" 
                    class="\${link.name}">
                    <!-- \${link.name !== 'Cookies' ? link.name : 'Cookies Policy'} -->
                    \${link.keyName | siteStrings}
                </a>
                <cx-footer-menu containerless else 
                    link.bind="link">
                </cx-footer-menu>
            </template>
        </div>
    </div>
</template>
`;
// Exports
export default code;