// Module
var code = `<template>
    <div id="security">
        <div id="security">
            <cx-card class.bind="'security-view-container'">
                <div class="title-container">
                    <h1 class="page-title" skeleton-loading="is-loading.bind:pageLoading;width:210px;">\${'CX_SECURITY' | siteStrings}</h1>
                    <p class="page-subtitle" skeleton-loading="is-loading.bind:pageLoading;width:210px;">\${'CX_MANAGE_SECURITY_SETTINGS' | siteStrings}</p>
                </div>
                <div class="options-container">
                    <cds-large-button 
                        repeat.for="option of options"
                        title.bind="\`CX_\${option.title.split(' ').join('_').toUpperCase()}\` | siteStrings:header.title" 
                        description.bind="\`CX_\${option.title.split(' ').join('_').toUpperCase()}_DESCRIPTION\` | siteStrings:header.description"
                        onclick.call="redirectTo(option.route)"
                        icon.bind="option.icon | iconPath"
                        is-disabled.bind="option.disabled"
                        skeleton-loading="is-loading.bind:pageLoading;"
                    ></cds-large-button>
                </div>
            </cx-card>
            <cx-confirm-dialog
                view-model.ref="dialogModule" 
                user.bind="user" 
                content.bind="dialogContent" 
                buttons="true"
                confirm-text.bind="'CX_DELETE_ACCOUNT' | siteStrings" 
                use-password.bind="true" 
                icon.bind="'delete'">
            </cx-confirm-dialog>
        </div>
    </div>
</template>
`;
// Exports
export default code;