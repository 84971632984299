import './footer.scss';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { WebsiteService } from 'services/website-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { SessionService } from 'services/session-service';
import { Helper } from 'resources/helpers/helper';
import { chicksXBlogsUrl, baseUrl } from 'environment';
import { ProductCategoryService } from 'services/product-category-service';
import { DesktopBreakpoint, TabletBreakpoint } from 'resources/constants';
import { PageByWebsite } from 'services/models/page/pageByWebsite';

@autoinject()
export class Footer {
    constructor(
        private router: Router,
        private websiteService: WebsiteService,
        private pageContentAreaService: PageContentAreaService,
        private eventAggregator: EventAggregator,
        private sessionService: SessionService,
        private helper: Helper,
        private productCategoryService: ProductCategoryService
    ) { }

    footerContainer;
    currentYear;
    detailsSelectors;
    careersRoute;
    aboutUsRoute;
    blogRoute;
    bugBountyRoute;
    contactUsRoute;
    sitemapRoute;
    faqRoute;
    privacyPolicyRoute;
    termsOfServiceRoute;
    cookiesRoute;
    locationsRoute;
    buySellSwapDropdown;
    legalPagesMoreDropdown;
    homePageRoute;
    trustPilotStarRating;
    amountOfStars;
    halfStar;
    semiSesquiStar;
    viewingAsAdmin;
    adminViewSubscriber: Subscription;
    refreshTokenSubscriber: Subscription;
    sizeChangedSubscriber: Subscription;
    sizeNavigationChangedSubscriber: Subscription;
    isLoading = true;
    chicksxSection = [];
    supportSection = [];
    legalSection = [];
    showFooter;
    @observable width = 0;
    firstTimeLoad = true;
    user;
    chicksXBlogsUrl = chicksXBlogsUrl();
    baseUrl = baseUrl();
    pages: PageByWebsite[];
    logoImg = 'chicksx-main-logo';

    created() {
        this.currentYear = new Date().getFullYear();
    }

    async attached() {
        this.pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.initPages();
        this.buySellSwapDropdown.menuItems = (await this.productCategoryService.getNavCategory('trade'))?.gameForNav;
        this.width = Helper.getScreenWidth();

        const pageContentArea = await this.pageContentAreaService.getByPageId(this.pages.find(x => x.name === 'Home')?.id);
        this.helper.getTrustPilotStarRatingVariables(this, pageContentArea);
        this.isLoading = false;
        this.handleEventSubscriptions();
        this.handleMargin();

        try {
            this.user = await this.sessionService.getProfile();
            const canAccessAdminPanel = await this.sessionService.checkRolesForPanelAccess();
            this.viewingAsAdmin = this.sessionService.getAdminView() && this.user && canAccessAdminPanel;
            this, this.handleEventSubscriptions();
        } catch (e) {
            console.log(e);
        }
    }

    private initPages() {
        this.resetSections();
        this.careersRoute = this.pages.find(x => x.name === 'Careers' && x.routeName) ?? { name: 'Careers', routeName: 'careers' };
        this.careersRoute.keyName = 'CX_CAREERS';
        this.blogRoute = { name: 'Blog', routeName: this.chicksXBlogsUrl };
        this.blogRoute.keyName = 'CX_BLOG';
        this.bugBountyRoute = this.pages.find(x => x.name === 'Bug Bounty' && x.routeName) ?? { name: 'Bug Bounty', routeName: 'bug-bounty' };
        this.bugBountyRoute.keyName = 'CX_BUG_BOUNTY';
        this.contactUsRoute = this.pages.find(x => x.name === 'Contact Us' && x.routeName) ?? { name: 'Contact Us', routeName: 'contact' };
        this.contactUsRoute.keyName = 'CX_CONTACT_US';
        this.sitemapRoute = this.pages.find(x => x.name === 'Sitemap' && x.routeName) ?? { name: 'Sitemap', routeName: 'sitemap' };
        this.sitemapRoute.keyName = 'CX_SITEMAP';
        this.faqRoute = this.pages.find(x => x.name === 'Faq' && x.routeName) ?? { name: 'Faq', routeName: 'faq' };
        this.faqRoute.keyName = 'CX_FAQ';
        this.privacyPolicyRoute = this.pages.find(x => x.name === 'Privacy Policy' && x.routeName) ?? { name: 'Privacy Policy', routeName: 'privacy-policy' };
        this.privacyPolicyRoute.keyName = 'CX_PRIVACY_POLICY';
        this.termsOfServiceRoute = this.pages.find(x => x.name === 'Terms Of Service' && x.routeName) ?? { name: 'Terms Of Service', routeName: 'terms-of-service' };
        this.termsOfServiceRoute.keyName = 'CX_TERMS_OF_SERVICE';
        this.cookiesRoute = this.pages.find(x => x.name === 'Cookies' && x.routeName) ?? { name: 'Cookies', routeName: 'cookies' };
        this.cookiesRoute.keyName = 'CX_COOKIES';
        this.locationsRoute = this.pages.find(x => x.name === 'Locations' && x.routeName) ?? { name: 'Locations', routeName: 'locations' };
        this.locationsRoute.keyName = 'CX_LOCATIONS';
        this.homePageRoute = this.pages.find(x => x.name === 'Home' && x.routeName) ?? { name: 'Home', routeName: '' };
        this.buySellSwapDropdown = { name: 'Buy Sell & Swap', isDropdownMenu: true, showFilter: true, keyName: 'CX_BUY_SELL_SWAP', menuItems: [] };
    }

    private resetSections() {
        this.chicksxSection = [];
        this.supportSection = [];
        this.legalSection = [];
        this.legalPagesMoreDropdown = {
            name: 'More', isDropdownMenu: true, keyName: 'CX_MORE', menuItems: [
                this.pages.find(x => x.name === 'Accessibility' && x.routeName),
                this.pages.find(x => x.name === 'Code of Ethics' && x.routeName),
                this.pages.find(x => x.name === 'Complaints Policy' && x.routeName),
                this.pages.find(x => x.name === 'Disclaimer' && x.routeName),
                this.pages.find(x => x.name === 'Editorial Policy' && x.routeName),
                this.pages.find(x => x.name === 'Modern Slavery Statement' && x.routeName)
            ]
        };
    }

    async checkRoute() {
        this.showFooter = !this.helper.includesWithout(this.router?.currentInstruction?.config?.moduleId, ['/auth/'], ['/expired-link/']);
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    handleEventSubscriptions() {
        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => this.viewingAsAdmin = payload.bool);
        this.sizeChangedSubscriber = this.eventAggregator.subscribe('size-changed', payload => this.width = payload.width);
        this.sizeNavigationChangedSubscriber = this.eventAggregator.subscribe('size-changed-navigation-pages', payload => this.widthChanged(payload.target));

        this.refreshTokenSubscriber = this.eventAggregator.subscribe('router:navigation:complete', () => {
            this.checkRoute();
            this.handleMargin();
        });

    }

    widthChanged(sticky: HTMLElement) {
        this.helper.debounce(this, 'sizeChanging', 'sizeChangedTimeout', this.firstTimeLoad ? 500 : 150, () => {
            this.handleMargin(sticky);
            if (!this.width)
                return;
            if (this.width < TabletBreakpoint) {
                this.filterPagesForMobileView();
            }
            else if (this.width < DesktopBreakpoint)
                this.filterPagesForTableView();
            else if (this.width >= DesktopBreakpoint)
                this.filterPagesForDesktopView();
        });
    }

    handleMargin(sticky: HTMLElement = undefined) {
        this.firstTimeLoad = false;
        if (!this.footerContainer) return;
        if (this.width > 992) {
            this.footerContainer.style.marginBottom = '';
            return;
        }
        sticky ??= document.querySelector('#navigation-pages:not(.is-dropdown)');
        this.footerContainer.style.marginBottom = sticky ? `${sticky.offsetHeight}px` : '';
    }

    private filterPagesForMobileView() {
        this.resetSections();
        this.chicksxSection.push(this.locationsRoute, this.careersRoute, this.blogRoute, this.bugBountyRoute, this.buySellSwapDropdown);
        this.supportSection.push(this.faqRoute, this.contactUsRoute, this.sitemapRoute);
        this.legalSection.push(this.privacyPolicyRoute, this.termsOfServiceRoute, this.cookiesRoute, this.legalPagesMoreDropdown);
    }

    private filterPagesForDesktopView() {
        this.resetSections();
        this.chicksxSection.push(this.locationsRoute, this.blogRoute, this.bugBountyRoute, this.buySellSwapDropdown);
        this.supportSection.push(this.faqRoute, this.contactUsRoute, this.sitemapRoute);
        this.legalPagesMoreDropdown.menuItems.unshift(this.careersRoute);
        this.legalSection.push(this.privacyPolicyRoute, this.termsOfServiceRoute, this.cookiesRoute, this.legalPagesMoreDropdown);
    }

    private filterPagesForTableView() {
        this.resetSections();
        this.chicksxSection.push(this.locationsRoute, this.blogRoute, this.buySellSwapDropdown);
        this.supportSection.push(this.faqRoute, this.contactUsRoute, this.sitemapRoute);
        this.legalPagesMoreDropdown.menuItems.unshift(this.bugBountyRoute, this.careersRoute, this.cookiesRoute);
        this.legalSection.push(this.privacyPolicyRoute, this.termsOfServiceRoute, this.legalPagesMoreDropdown);
    }

    mouseOverLogo() {
        this.logoImg = 'chicksx-main-logo-hover';
    }

    mouseOutLogo() {
        this.logoImg = 'chicksx-main-logo';
    }

    @computedFrom('logoImg')
    get logoPath() {
        return `/static/logo/${this.logoImg}.svg`;
    }
}
