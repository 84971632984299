// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/home/star_full_grey_900.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/home/star_half_grey_900.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/home/star_semisesqui_grey_900.svg", import.meta.url);
// Module
var code = `<template>
    <a href="https://www.trustpilot.com/review/chicksx.com" class="d-block" target="_blank"
        rel="nofollow noopener noreferrer">
        \${'CX_TRUSTPILOT_REVIEWS' | siteStrings}
    </a>
    <div class="stars flex-center-between mb-regular">
        <img repeat.for="i of amountOfStars" src="${___HTML_LOADER_IMPORT_0___}" class="trust-pilot-star"
            alt="Star icon" loading="lazy">
        <img if.bind="halfStar" src="${___HTML_LOADER_IMPORT_1___}" class="trust-pilot-star" alt="Half Star icon"
            loading="lazy">
        <img if.bind="semiSesquiStar" src="${___HTML_LOADER_IMPORT_2___}"
            class="trust-pilot-semisesqui-star" alt="Semisesqui Star icon" loading="lazy">
        <span if.bind="viewingAsAdmin" class="admin-view-size ms-1">Key: { CX_TRUST_PILOT_RATING
            }/5</span>
        <span else class="text-medium-static trust-pilot-text">\${ trustPilotStarRating }/5</span>
    </div>
</template>
`;
// Exports
export default code;