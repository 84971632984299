// Module
var code = `<template>
    <cx-try-again-box
        use-keys.bind="true"
        main="EXPIRED_LINK_TITLE"
        subtitle="EXPIRED_LINK_SUBTITLE"
        page="Expired Link"
        button-text="Go home"
        variant="outlined"
        icon="link_off"
        href="/sign-in"
    >
    </cx-try-again-box>
</template>
`;
// Exports
export default code;