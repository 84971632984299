import './cx-progress-bar.scss';
import { autoinject, bindable, TaskQueue } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { MDCLinearProgress } from '@material/linear-progress';
import { User } from 'services/models/user/user';

@autoinject()
export class CxProgressBar {
    @bindable numSteps;
    @bindable isVerificationPage;
    @bindable isVertical;
    @bindable user: User;
    @bindable isLoading = true;
    currentStep = 1;
    progressCurrent = 0;
    verificationProgress = '0';
    bannerSubscriber;
    userSubscriber: Subscription;
    veriffSubscriber: Subscription;
    sizeChangeSubcription: Subscription;
    adminViewSubscriber: Subscription;
    currentVerificationLevel = 0;
    width;
    countryCode = '';

    constructor(private sessionService: SessionService, private eventAggregator: EventAggregator, private taskQueue: TaskQueue) { }

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        await this.sessionService.getIpInformation();
        this.countryCode = this.sessionService.getCountry();
        if (this.user) {
            if (this.user.emailConfirmed && this.user.idVerified) {
                this.currentVerificationLevel = 2;
            } else {
                if (this.user.phoneNumberConfirmed) {
                    this.currentVerificationLevel = 1;
                }
            }
        }
        this.setProgress(this.currentStep - 1);
        this.handleEventSubscriptions();
        this.taskQueue.queueMicroTask(() => {
            this.updateProgressBarWidth();
        });
    }

    detached() {
        this.veriffSubscriber?.dispose();
        this.userSubscriber?.dispose();
        this.sizeChangeSubcription?.dispose();
        this.adminViewSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async (payload: { user: User }) => {
            this.user = payload.user;
        });

        this.veriffSubscriber = this.eventAggregator.subscribe('veriff-verification', async(payload) => {
            if (payload?.user.idVerified) {
                this.user.idVerified = payload.user.idVerified;
                this.user = await this.sessionService.refreshProfile();
            }
        });

        this.sizeChangeSubcription = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
            setTimeout(() => this.updateProgressBarWidth(), 50);
        });

        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', () => {
            setTimeout(() => this.updateProgressBarWidth(), 50);
        });
    }

    setProgress(step) {
        this.progressCurrent = (1 / (this.numSteps - 1)) * step;
    }

    nextStep() {
        if (this.currentStep < this.numSteps) {
            this.currentStep++;
            if (this.currentStep === this.numSteps) {
                document.getElementsByClassName('step').item(this.numSteps - 1).classList.add('step-success');
            }
        }
        this.setProgress(this.currentStep - 1);
    }

    previousStep() {
        if (this.currentStep > 1) {
            if (this.currentStep === this.numSteps) {
                document.getElementsByClassName('step').item(this.numSteps - 1).classList.remove('step-success');
            }
            this.currentStep--;
        }
        this.setProgress(this.currentStep - 1);
    }

    updateProgressBarWidth() {
        const progress = document.querySelectorAll('#cx-progress-bar');
        const progressIntoView = Array.from(progress).find(el => el.clientHeight > 0);
        const progressSucess = progressIntoView?.querySelector('.cx-linear-progress_success') as HTMLElement;
        if (progressSucess) {
            const levels = Array.from(progressIntoView?.querySelectorAll('.verification-column .level-title')) as HTMLElement[];
            const steps = (Array.from(progressIntoView?.querySelectorAll('li'))) as HTMLElement[];
            const linearProgress = new MDCLinearProgress(progressSucess);
            let totalWidth = 0;

            if (this.width >= 576 && this.width <= 992) {
                totalWidth = (levels[0].offsetLeft + levels[levels.length - 1].offsetLeft + 10);
                const verificationContent = document.querySelector('.verification-container');
                const verificationFooter = document.querySelector('.verification-footer');
                const verificationPills = document.querySelector('.verification-pills');
                verificationFooter.setAttribute('style', `min-height: ${verificationContent.clientHeight}px;`);
                verificationPills.setAttribute('style', `width: ${totalWidth}px;`);
                linearProgress.progress = this.currentVerificationLevel / 2;
                steps.forEach((step, index) => step.style.left = `${ levels[index].offsetLeft }px`);
            } else {
                totalWidth = levels[0].offsetTop + levels[levels.length - 1].offsetTop;
                steps.forEach((step, index) => step.style.top = `${ levels[index].offsetTop - 4 }px`);
                linearProgress.progress = levels[this.currentVerificationLevel].offsetTop / totalWidth;
            }

            progressSucess.style.width = `${ totalWidth }px`;
            if (linearProgress.progress) {
                this.verificationProgress = `${linearProgress.progress}`;
            }
        }
    }
}
