import './navbar.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { getAWSBucketEndpoint } from 'environment';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { CurrencyService } from 'services/currency-service';
import { LanguageService } from 'services/language-service';
import { CustomerService } from 'services/customer-service';
import {
    Currency,
    PaymentMethodForSelectorResponse
} from 'services/models/purchase-flow/exchange';
import { Helper } from 'resources/helpers/helper';
import { PageContentAreaService } from 'services/page-content-area-service';
import { AuthenticationExtension } from 'resources/extensions/sso_extension';
import { SubscriptionService } from 'services/subscription-service';
import { OpenedVeriffDialogEvent } from 'resources/constants';
import { clientId, websiteShortCode } from 'environment';

@autoinject()
export class Navbar extends AuthenticationExtension {
    constructor(
        toastService: ToastService,
        sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private router: Router,
        private paymentMethodWebsiteService: PaymentMethodWebsiteService,
        private currencyService: CurrencyService,
        private languageService: LanguageService,
        private pageContentAreaService: PageContentAreaService,
        private subscriptionService: SubscriptionService,
        private helper: Helper,
        private customerService: CustomerService,
    ) {
        super(toastService, sessionService);
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
    }

    @bindable user;
    @bindable buttonStyling;
    @bindable navbarLogo: HTMLElement;
    @bindable showReadingProgress;
    @bindable pages;
    @bindable currencyOptions = [];
    homePageRoute;
    tradeRoute;
    userSubscriber;
    sizeSubscriber;
    bannerSubscriber;
    @bindable showBanner;
    stateBanner;
    textBanner;
    clickFunctionName;
    url;
    drawer;
    currentCurrency;
    currentLanguage;
    currencyDropdown;
    currencyExpandable;
    languageExpandable;
    baseAwsEndpoint;
    dynamicNavContent: string = '';
    navBarLoading = true;
    languageList;

    dropdowns = [
        {
            title: 'CX_BUY_CRYPTO',
            contentName: 'buy',
            isOpen: false,
            isContentHovered: false,
            isClicked: false,
            isActive: false
        },
        {
            title: 'CX_SELL_CRYPTO',
            contentName: 'sell',
            isOpen: false,
            isContentHovered: false,
            isClicked: false,
            isActive: false
        },
        {
            title: 'CX_EXCHANGE',
            contentName: 'exchange',
            isOpen: false,
            isContentHovered: false,
            isClicked: false,
            isActive: false
        }
    ];

    languageAndCurrency = {
        isOpen: false,
        isContentHovered: false,
        isClicked: false,
        isActive: false
    };

    isContentHovered: boolean = false;
    selectedContentSubscriber;
    fixContentSubscriber;
    tradePageRedirectSubscriber;
    selectedCurrencySubscriber;
    selectedLanguageSubscriber;
    exchangeTypeSubscriber;
    languageAndCurrencySubscriber;
    activeExchange: 'buy' | 'sell' | 'exchange' = null;

    activeCurrencies: Currency[];
    currencyList: Currency[];
    cryptoList: Currency[];
    paymentMethods: PaymentMethodForSelectorResponse[];
    allPaymentMethods: PaymentMethodForSelectorResponse[];
    allCxPaymentMethods: PaymentMethodForSelectorResponse[];

    filterOptionsMobile: string = '';

    maxVisibleOptions: number;
    pageLoadedSubscriber: Subscription;

    navbar: HTMLElement;

    async attached() {
        try {
            this.helper.addLoadingComponent('navbar');
            this.activeCurrencies = await this.currencyService.getActiveCurrenciesByWebsite();
            this.cryptoList = await this.currencyService.getCryptoForSelector();
            this.currencyList = this.activeCurrencies.filter(e => e.type === 'F');
            this.allPaymentMethods = await this.paymentMethodWebsiteService.getByWebsiteForSelector(true);
            this.currencyOptions = await this.currencyService.getCurrencyOptions();
            this.paymentMethods = this.allCxPaymentMethods = this.allPaymentMethods?.filter(p => {
                const isCryptoPaymentMethod = this.helper.includesSome(p.reference, ['coinpayments', 'btcpay', 'bitcart', 'crypto']);
                return !isCryptoPaymentMethod && p.payoutable;
            });

            this.homePageRoute = this.pages.find(x => x.name === 'Home')?.routeName ?? '';
            this.tradeRoute = this.pages.find(x => x.name === 'Trade')?.routeName ?? 'trade';

            if (this.user) {
                this.user.isSubscribed = this.subscriptionService.hasSubscription(this.user);
            }

            this.handleEventSubscriptions();
            this.overrideMdc();
            this.handleSetVisibleDesktopOptions(window.innerWidth);
            document.addEventListener('click', this.handleOutsideClick);
        } finally {
            this.helper.validateLoading('navbar');
        }
    }

    async bind() {
        this.languageList = await this.languageService.getLanguages();
    }

    detached() {
        this.userSubscriber?.dispose();
        this.sizeSubscriber?.dispose();
        this.selectedContentSubscriber?.dispose();
        this.fixContentSubscriber?.dispose();
        this.selectedContentSubscriber?.dispose();
        this.exchangeTypeSubscriber?.dispose();
        this.languageAndCurrencySubscriber?.dispose();
        this.pageLoadedSubscriber?.dispose();
        document.removeEventListener('click', this.handleOutsideClick);
    }

    async closeBanner() {
        this.showBanner = false;
    }

    handleCloseBanner() {
        this.eventAggregator.publish('banner-updated', { clickFunctionName: 'close' });
        this.showBanner = false;
    }

    async clickFunction() {
        this.closeBanner();
        if (this.clickFunctionName) {
            switch (this.clickFunctionName) {
                case 'resetPassword': {
                    this.user = this.user ?? await this.sessionService.getProfile();
                    const requestPasswordResponse = await this.customerService.requestPasswordReset({
                        email: this.user.email,
                        websiteShortCode: websiteShortCode(),
                        redirectUrl: location.href,
                        clientId: clientId()
                    });
                    if (!requestPasswordResponse) {
                        return;
                    }
                    await this.toastService.showToast(
                        'Info',
                        'You are required to reset your password. An email has been sent to you to do so.',
                        'default'
                    );
                    break;
                }
                case 'refresh': {
                    location.reload();
                    break;
                }
                default: {
                    console.error(`Unsupported clickFunctionName: ${this.clickFunctionName}`);
                }
            }
        }
    }

    handleEventSubscriptions() {
        const events = {
            'banner-updated': payload => {
                this.showBanner = true;
                this.stateBanner = payload.stateBanner ?? 'default';
                this.textBanner = payload.text;
                this.clickFunctionName = payload.clickFunctionName;
                this.url = payload.url;
            },
            'user-updated': async (payload) => {
                this.user = payload.user;

                if (this.user) {
                    this.user.isSubscribed = this.subscriptionService.hasSubscription(this.user);
                }
            },
            'size-changed': async (payload) => {
                const width = payload.width;
                let drawerOpen = document.getElementById('drawer-mobile-style');
                this.navbarLogo.setAttribute('height', '40');
                if (width <= 992) {
                    if (!drawerOpen) {
                        drawerOpen = document.createElement('style');
                        drawerOpen.setAttribute('id', 'auth-mobile-style');
                    }
                    drawerOpen.innerHTML = `.page-host.drawer-open { overflow: hidden; height: ${document.documentElement.clientHeight}px; }`;
                    document.head.append(drawerOpen);
                    if (width < 350) {
                        this.navbarLogo.setAttribute('height', '25');
                    } else {
                        this.navbarLogo.setAttribute('height', '34');
                    }
                } else {
                    if (drawerOpen) {
                        drawerOpen.remove();
                    }
                }
                this.handleSetVisibleDesktopOptions(width);
            },
            'nav-content-selected': payload => {
                const movedToNavContent = ['cx-nav-preorder', 'baner-box-newsletter-prompt', 'cx-banner-container'].includes(payload.moveTo);
                if (!payload.selected && this.dynamicNavContent === payload.content && movedToNavContent) {
                    return;
                }

                this.paymentMethods = [...this.filterPaymentMethods(payload.content)];

                if (this.someOneContentClicked() && payload.content !== this.dynamicNavContent) return;

                this.dynamicNavContent = payload.content;

                const dropdownChangedIndex = this.dropdowns.findIndex(drop => drop.contentName === this.dynamicNavContent);
                if (dropdownChangedIndex > -1) {
                    this.dropdowns[dropdownChangedIndex].isOpen = this.dropdowns[dropdownChangedIndex].isContentHovered = payload.selected;
                    this.dropdowns[dropdownChangedIndex].isClicked = payload.event === 'click' && payload.selected;
                }
                if (this.dynamicNavContent === 'language-and-currency') {
                    this.languageAndCurrency.isOpen = this.languageAndCurrency.isContentHovered = payload.selected;
                    this.languageAndCurrency.isClicked = payload.event === 'click' && payload.selected;
                }
                this.toggleActive();
                if (!movedToNavContent && this.dynamicNavContent === payload.content && !payload.selected) {
                    this.dynamicNavContent = '';
                    this.handleContentMouseLeave();
                    return;
                }
            },
            'exchange-type-changed':payload => {
                this.activeExchange = payload.exchangeType === 'B' ? 'buy' : payload.exchangeType === 'S' ? 'sell' : ['FF', 'CC'].includes(payload.exchangeType) ? 'exchange' : null;
                this.dropdowns.forEach(drop => drop.isActive = drop.contentName === this.activeExchange);
            },
            'nav-fix-content': payload => {
                if (!this.dynamicNavContent) return;

                const dropdownChangedIndex = this.dropdowns.findIndex(drop => drop.contentName === this.dynamicNavContent);
                this.dropdowns[dropdownChangedIndex].isOpen =
                    this.dropdowns[dropdownChangedIndex].isContentHovered =
                    this.dropdowns[dropdownChangedIndex].isClicked = payload.fixContent;
                if (!payload.fixContent) this.dynamicNavContent = '';
            },
            'trade-page-redirect': () => {
                this.handleDrawerOpenClick();
            },
            'selected-currency': payload => {
                const { currency, element } = payload;
                this.selectCurrency(currency, element);
            },
            'selected-language': payload => {
                const { language, element } = payload;
                this.selectLanguage(language, element);
            },
            'page-loaded': () => {
                this.navBarLoading = false;
            },
            'router:navigation:processing':  async() => {
                if (this.clickFunctionName !== 'refresh') return;
                location.reload();
            },
            'router:navigation:complete': async() => {
                if (this.stateBanner === 'warning' || this.stateBanner === 'default') return;
                this.showBanner = false;
            },
        };
        this.helper.subscribeEvents(this, events);
    }

    handleOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const navbarElements = ['dynamic-dropdown-content', 'cx-nav-preorder', 'cx-dropdown-content'];
        if (!navbarElements.some((element) => target.closest(element)) && this.dynamicNavContent) {
            this.dropdowns = this.dropdowns.map((drop) => ({
                ...drop,
                isOpen: false,
                isContentHovered: false,
                isClicked: false
            }));
            this.languageAndCurrency = {
                ...this.languageAndCurrency,
                isOpen: false,
                isContentHovered: false,
                isClicked: false
            };
            this.dynamicNavContent = '';
        }
        this.toggleActive();
    };

    overrideMdc() {
        const navbar = document.getElementById('navigation-bar');
        const selectArrows = navbar.querySelectorAll('.mdc-expandable__dropdown-icon');
        selectArrows?.forEach(el => el.innerHTML = '<span class="expandable-item-arrow material-icons">arrow_drop_down</span>');
    }

    async showIntercomMessage(type: string) {
        if (window.Intercom) {
            window.Intercom('showNewMessage', `I would like to ${type} crypto.`);
        }
    }

    async logOut() {
        await this.sessionService.logout();
        await this.toastService.showToast('You have been logged out', 'Please log back in to see your orders and continue trading!.', 'default');
    }

    handleDrawerOpenClick() {
        this.drawer.open = !this.drawer.open;
        const mainPageHost = document.getElementById('main-page-host');
        const simplebarVertical = document.querySelector('#main-page-host > div.simplebar-track.simplebar-vertical');
        const simplebarContent = document.querySelector('#main-page-host .simplebar-content-wrapper');

        if (!this.drawer.root.className.includes('mdc-drawer--closing')) {
            simplebarVertical.classList.add('d-none');
            simplebarContent.classList.add('noscroll');
            mainPageHost.classList.add('drawer-open');
        } else {
            simplebarVertical.classList.remove('d-none');
            simplebarContent.classList.remove('noscroll');
            mainPageHost.classList.remove('drawer-open');

            this.eventAggregator.publish('navbar-close');
        }

        this.eventAggregator.publish(OpenedVeriffDialogEvent, { openedVeriffDialog: !this.drawer.root.className.includes('mdc-drawer--closing'), triggerDeviceStyling: true });
    }

    handleDropdownOpenClick(dropdown: string) {
        for (const drop of this.dropdowns) {
            drop.isOpen = drop.contentName === dropdown;
        }
        if (dropdown === 'language-and-currency') {
            this.languageAndCurrency = {
                ...this.languageAndCurrency,
                isOpen: true
            };
        }
    }

    toggleActive() {
        this.dropdowns.forEach(drop => drop.isActive = this.dropdowns.some((dropdown) => dropdown.isOpen) ? false : drop.contentName === this.activeExchange);
    }

    handleContentMouseEnter(event) {
        const mouseEventType = event.type;
        this.dropdowns = this.dropdowns.map((dropdown) => {
            dropdown.isContentHovered = mouseEventType === 'mouseover' && dropdown.contentName === this.dynamicNavContent;
            return dropdown;
        });
        const isAnyContentHovered = !this.dropdowns.some((dropdown) => {
            dropdown.isContentHovered === true;
        });
        this.languageAndCurrency = {
            ...this.languageAndCurrency,
            isContentHovered: !isAnyContentHovered
        };
    }

    handleLangAndCurrencyMouseEnter(event) {
        this.handleContentMouseEnter(event);
        this.languageAndCurrency = {
            ...this.languageAndCurrency,
            isContentHovered: true
        };
    }

    handleContentMouseLeave() {
        if (this.someOneContentClicked()) return;

        this.dropdowns = this.dropdowns.map((dropdown) => {
            dropdown.isContentHovered = false;
            return dropdown;
        });
        this.languageAndCurrency = {
            ...this.languageAndCurrency,
            isContentHovered: false
        };
        this.dynamicNavContent = '';
    }

    someOneContentHovered() {
        return this.dropdowns.some((drop) => drop.isContentHovered);
    }

    someOneContentClicked() {
        return this.dropdowns.some((drop) => drop.isClicked) || this.languageAndCurrency.isClicked;
    }

    async movetToTrade() {
        await this.router.navigate(`/${this.pages.find(x => x.name === 'Trade')?.routeName ?? 'trade'}`);
    }

    async handleNavigationOptions(option) {
        switch (option) {
            case 'blogs':
                await this.router.navigate(`/${this.pages.find(x => x.name === 'Blog')?.routeName ?? 'blog'}`);
                break;
            default:
                break;
        }
    }

    async selectCurrency(currency, element) {
        await this.currencyDropdown?.au.controller.viewModel.selectCurrency(currency);

        if (element) element.click();

        this.handleDrawerOpenClick();
    }

    async selectLanguage(language, element) {
        await this.currencyDropdown?.au.controller.viewModel.selectLanguage(language);

        if (element) element.click();

        this.handleDrawerOpenClick();
    }

    filterOptionsMobileChange(event) {
        this.filterOptionsMobile = event;
    }

    handleSetVisibleDesktopOptions(width: number) {
        if (width >= 1920) {
            this.maxVisibleOptions = 6;
        } else if (width >= 1400) {
            this.maxVisibleOptions = 4;
        } else {
            this.maxVisibleOptions = 3;
        }
    }

    cleanMobileSearchBar() {
        this.filterOptionsMobile = '';
    }

    filterPaymentMethods(action: 'buy' | 'sell') {
        if (!['buy', 'sell'].includes(action)) return this.allCxPaymentMethods;
        return this.allCxPaymentMethods.filter(method => action === 'buy' ? method.live : method.payoutable);
    }

    filterDynamicNavContent() {
        return this.dynamicNavContent && ['buy', 'sell', 'exchange'].includes(this.dynamicNavContent);
    }

    handleRedirect = (route: string) => {
        this.handleAuthRedirection(null, null, route);
    };
}
