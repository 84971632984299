// Module
var code = `<template>
    <div id="profile" class="position-relative main-content-profile is-\${sectionName.toLowerCase()}">
        <div class="container-profile-style">
            <div class="style-title text-extra-large no-font-bp font-medium text-left lh-1 mb-4" skeleton-loading="is-loading.bind:contentLoading;width:80px;height:28px;">
                \${'CX_PROFILE' | siteStrings}
            </div>
            <div ref="mainContainer" class="profile-main-container">
                <cx-user-avatar
                    is-profile.bind="true"
                    class="profile-styles"
                    size.bind= "48"
                    if.bind="user && !contentLoading"
                    user.bind="user"
                    is-edit.bind='true'
                    use-icon-size.bind="true"
                ></cx-user-avatar>
                <div show.bind="contentLoading" skeleton-loading="is-loading.bind:contentLoading;width:46px;height:46px;border-radius:100%;"></div>
                <div class="text-profile">
                    <span class="profile-name text-normal truncate-text-1-b" skeleton-loading="is-loading.bind:contentLoading;width:120px;height:28px">
                        \${fullName}
                    </span>
                    <div class="member-since-container">
                        <p if.bind="!userSubscription.subscribedAt" show.bind="!contentLoading" class="profile-member profile-subtitle mb-0">
                            \${'CX_MEMBER_SINCE' | siteStrings}: \${user.createdDate | dateFormatter:'timeless'}<br />
                        </p>
                        <div if.bind="userSubscription.subscribedAt" show.bind="!contentLoading" class="profile-member mb-0">
                            <span class="d-lg-block profile-subtitle" skeleton-loading="is-loading.bind:contentLoading;">
                               \${'CX_VIP_MEMBER_SINCE' | siteStrings}: \${userSubscription.subscribedAt | dateFormatter:'format':'MMMM yyyy'}
                            </span>
                        </div>
                        <div show.bind="contentLoading" skeleton-loading="is-loading.bind:contentLoading;width:182px;height:16px" class="profile-member profile-subtitle mb-0"></div>
                        <cx-tooltip 
                            message.bind="memberSinceString" 
                            is-loading.bind="contentLoading" 
                            no-wrap.bind="true"
                            x-position.bind="width > phone ? 'MIDDLE' : 'END'">
                        </cx-tooltip>
                    </div>
                </div> 
            </div>
            <div class="info-text profile-info d-none d-lg-block">
                <span class="d-inline-block" skeleton-loading="is-loading.bind:contentLoading;">\${'CX_PERSONAL_INFORMATION' | siteStrings}</span>
            </div>
            <div class="content-desc">
                <div class="position-relative font-regular content-row profile-grid profile-grid--info">
                    <div class="d-flex cg-col-2 cg-col-sm-1 align-items-stretch info-field position-relative font-regular">
                        <span class="profile-field col-lg-6" skeleton-loading="is-loading.bind:contentLoading;">
                            \${'CX_FULL_NAME' | siteStrings}
                        </span>
                        <div class="profile-value">
                            <span class="profile-info text-normal truncate-text-1-b info-desc" skeleton-loading="is-loading.bind:contentLoading;width:60%;height:100%;">
                                \${fullName}
                            </span>
                            <cx-tooltip
                                class="info-icon"
                                icon="watch_later"
                                message.bind="loadTooltips('name', 'pending')"
                                color="#FED766"
                                is-loading.bind="contentLoading"
                                x-position="END"
                                if.bind="!contentLoading && !idVerified"
                            >
                            </cx-tooltip>
                        </div>
                    </div>
                    <div class="d-flex cg-col-2 cg-col-sm-1 align-items-stretch info-field position-relative font-regular">
                        <span class="profile-field col-lg-2" skeleton-loading="is-loading.bind:contentLoading;">
                            \${'CX_EMAIL_ADDRESS' | siteStrings}
                        </span>
                        <div class="profile-value">
                            <span class="profile-info text-normal truncate-text-1-b info-desc" skeleton-loading="is-loading.bind:contentLoading;width:60%;height:100%;">
                                \${ user.email ? user.email : '-' | textTruncate:37 }
                            </span>
                            <cx-tooltip
                                class="info-icon"
                                icon="watch_later"
                                message.bind="loadTooltips('email', 'pending')"
                                color="#FED766"
                                is-loading.bind="contentLoading"
                                x-position="END"
                                if.bind="!contentLoading && (user.email && !user.emailConfirmed)"
                            >
                            </cx-tooltip>
                        </div>
                    </div>
                </div>
                <div class="position-relative font-regular content-row profile-grid profile-grid--info">
                    <div class="d-flex cg-col-2 cg-col-sm-1 align-items-stretch info-field position-relative font-regular">
                        <span class="profile-field col-lg-3" skeleton-loading="is-loading.bind:contentLoading;">
                            \${'CX_MOBILE_NUMBER' | siteStrings}
                        </span>
                        <div class="profile-value">
                            <span class="profile-info text-normal truncate-text-1-b info-desc" skeleton-loading="is-loading.bind:contentLoading;width:60%;height:100%;">
                                \${ user.phoneNumber | phoneFormat:user.phoneCountryCode }
                            </span>
                            <cx-tooltip
                                class="info-icon"
                                icon="watch_later"
                                message.bind="loadTooltips('phone', 'pending')"
                                color="#FED766"
                                is-loading.bind="contentLoading"
                                x-position="END"
                                if.bind="!contentLoading && (!user.phoneNumberConfirmed && user.phoneNumber)"
                            >
                            </cx-tooltip>
                        </div>
                    </div>
                    <div class="d-flex cg-col-2 cg-col-sm-1 align-items-stretch info-field position-relative font-regular">
                        <span class="profile-field col-lg-3" skeleton-loading="is-loading.bind:contentLoading;">
                            \${'CX_ADDRESS' | siteStrings}
                        </span>
                        <div class="profile-value">
                            <span class="profile-info text-normal truncate-text-1-b info-desc" skeleton-loading="is-loading.bind:contentLoading;width:60%;height:100%;">
                                \${ address }
                            </span>
                            <cx-tooltip
                                class="info-icon"
                                icon="watch_later"
                                message.bind="loadTooltips('address', 'pending')"
                                color="#FED766"
                                is-loading.bind="contentLoading"
                                x-position="END"
                                if.bind="addressPending"
                            >
                            </cx-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div show.bind="!isMain" class="profile-separator profile-separator--main d-lg-none" skeleton-loading="is-loading.bind:contentLoading;"></div>
        <div class="container-profile-style-settings marketing-preferences font-medium">
            <cds-back-button 
                class="pb-40px"
                if.bind="!isMain"
                click.trigger="setSection(Sections.Main)"
                disabled.bind="updatingInfoInner"
                page-loading.bind="contentLoading">
            </cds-back-button>
            <span 
                class="settings-title" 
                skeleton-loading="is-loading.bind:contentLoading;"
            >
                \${title}
            </span>
            <div 
                class="profile-subtitle \${isNotifications ? 'mb-big' : 'mb-regular'}" 
                skeleton-loading="is-loading.bind:contentLoading;width:178px;height:16px"
            >
                \${description}
            </div>
            <section if.bind="isMain">
                <div class="profile-options profile-grid">
                    <cds-large-button
                        title.bind="'CX_NOTIFICATION' | siteStrings"
                        description.bind="'CX_CUSTOMER_PORTAL_NOTIFICATION_INFO' | siteStrings"
                        onclick.call="setSection(Sections.Notifications)"
                        skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                        icon="settings"
                        class="profile-large-button">
                    </cds-large-button>
                    <cds-large-button
                        title.bind="'CX_REFERRAL_PROGRAM' | siteStrings"
                        description.bind="referralProgramDescription"
                        onclick.call="router.navigateToRoute('referrals')"
                        skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                        icon="redeem"
                        class="profile-large-button">
                    </cds-large-button>
                    <cds-large-button
                        title="Discord"
                        description.bind="discordOptionDescription"
                        onclick.call="!user.discordTag ? linkDiscordProfile() : openDialog(true)"
                        skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                        is-disabled.bind="updatingInfo"
                        icon="discord"
                        class="profile-large-button">
                    </cds-large-button>
                </div>
            </section>
            <section show.bind="isNotifications" class="notifications-content profile-grid">
                <div class="notification__section">
                    <p class="notification__title" skeleton-loading="is-loading.bind:contentLoading;">
                        \${'CX_SITE_ALERTS' | siteStrings}
                    </p>
                    <div class="notification__separator profile-separator" skeleton-loading="is-loading.bind:contentLoading;"></div>
                    <div class="notification__checkboxes">
                        <cx-check-container
                            title.bind="'CX_EMAIL_ALERTS_TITLE' | siteStrings"
                            subtitle.bind="'CX_EMAIL_ALERTS_INFO' | siteStrings"
                            on-change.bind="updateInformation"
                            value.two-way="user.optedInEmailAlerts"
                            disabled.bind="updatingInfoInner"
                            skeleton-loading="is-loading.bind:contentLoading;">
                        </cx-check-container>
                        <cx-check-container
                            title.bind="'CX_TEXT_ALERTS_TITLE' | siteStrings"
                            subtitle.bind="'CX_TEXT_ALERTS_INFO' | siteStrings"
                            on-change.bind="updateInformation"
                            value.two-way="user.optedInTexts"
                            disabled.bind="updatingInfoInner"
                            skeleton-loading="is-loading.bind:contentLoading;">
                        </cx-check-container>
                        <cx-check-container
                            title.bind="'CX_NOTIFICATION_ALERTS_TITLE' | siteStrings"
                            subtitle.bind="'CX_NOTIFICATION_ALERTS_INFO' | siteStrings"
                            on-change.bind="updateInformation"
                            value.two-way="user.optedInPushNotifications"
                            disabled.bind="updatingInfoInner"
                            skeleton-loading="is-loading.bind:contentLoading;">
                        </cx-check-container>
                    </div>
                </div>
                <div class="notification__section">
                    <p class="notification__title" skeleton-loading="is-loading.bind:contentLoading;">
                        \${'CX_PROMOTION_&_OFFERS' | siteStrings}
                    </p>
                    <div class="notification__separator profile-separator" skeleton-loading="is-loading.bind:contentLoading;"></div>
                    <div class="notification__checkboxes">
                        <cx-check-container
                            title.bind="'CX_NEWSLETTER_TITLE' | siteStrings"
                            subtitle.bind="'CX_NEWSLETTER_INFO' | siteStrings"
                            on-change.bind="updateInformation"
                            value.two-way="user.optedInForEmails"
                            disabled.bind="updatingInfoInner"
                            skeleton-loading="is-loading.bind:contentLoading;">
                        </cx-check-container>
                        <cx-check-container
                            title.bind="'CX_BIRTHDAY_ALERTS_TITLE' | siteStrings"
                            subtitle.bind="'CX_BIRTHDAY_ALERTS_TITLE' | siteStrings"
                            on-change.bind="updateInformation"
                            value.two-way="user.optedInBirthdayEmails"
                            disabled.bind="updatingInfoInner"
                            skeleton-loading="is-loading.bind:contentLoading;">
                        </cx-check-container>
                    </div>
                </div>
            </section>
            <section if.bind="isUnlink" class="d-flex gap-regular flex-wrap">
                <cx-button
                    class="text-regular unlink-btn"
                    button-text.bind="'CX_UNLINK_ACCOUNT' | siteStrings"
                    click.delegate="unlinkingAccount()"
                    button-style="text-white text-medium no-font-bp secondary-style button-h50">
                </cx-button>
                <cx-button
                    class="text-regular unlink-btn"
                    button-text.bind="'CX_CANCEL' | siteStrings"
                    click.delegate="setSection(Sections.Main)"
                    button-style="text-white text-medium no-font-bp primary-style button-h50">
                </cx-button>
            </section>
        </div>
    </div>
</template>
`;
// Exports
export default code;