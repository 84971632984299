// Module
var code = `<template>
    <cx-try-again-box
        id="site-down"
        use-keys.bind="true"
        show-status.bind="true"
        main="SITE_DOWN_TITLE"
        subtitle="SITE_DOWN_SUBTITLE"
        page="Site Down"
        variant="filled"
        button-text="Try again"
        icon="build"
        href.bind="homePageRoute"
    >
    </cx-try-again-box>
</template>
`;
// Exports
export default code;