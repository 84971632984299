// Module
var code = `<template>
    <div id="subscription-payment-details">
        <cx-card class.bind="'main-container-subscriptions'">
            <cds-back-button click.trigger="router.navigateToRoute(backRoute)" page-loading.bind="pageLoading"></cds-back-button>
            <div class="change-plan-container" show.bind="!isDesktop">
                <div class="payment-details-header">
                    <h2 class="subscription__title" skeleton-loading="is-loading.bind:pageLoading;width:230px;">
                        \${sectionData[sections.ChangePlan].titleKey | siteStrings:sectionData[sections.ChangePlan].title|capitalizeText:'sentence'}
                    </h2>
                    <p class="subscription__desc" skeleton-loading="is-loading.bind:pageLoading;width:260px;height:16px">
                        \${sectionData[sections.ChangePlan].descriptionKey | siteStrings:null:[dateFormatterValueConverter.toView(userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy'),priceFormatted]}
                    </p>
                </div>       
                <div skeleton-loading="is-loading.bind:pageLoading;width:168px;height:28px" show.bind="pageLoading"></div>
                <div class="options-container" show.bind="!pageLoading">
                    <cds-tabs
                        tabs.bind="tabs"
                        selected.trigger="setTab(\$event)"
                        ref="tabsElement"
                    ></cds-tabs>
                </div>
            </div>            
            <div class="payment-details-header">
                <h2 class="subscription__title" skeleton-loading="is-loading.bind:pageLoading;width:180px;height:32px;">
                    \${sectionData[sections.Details].titleKey | siteStrings:sectionData[sections.Details].title}
                </h2>
                <p if.bind="!user.renewal" class="subscription__desc subscription__notice" skeleton-loading="is-loading.bind:isLoading;height:16px">
                    \${'CX_SUBSCRIPTION_CANCELED_ON' | siteStrings:'params':[dateFormatterValueConverter.toView(userSubscriptionInfo.unsubscribedAt, 'format', 'MMMM Do, yyyy'),dateFormatterValueConverter.toView(userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy')]}
                    <span class="subscription__link" click.delegate="setRenewalFunction()">\${'CX_ENABLE_AUTO_RENEWAL' | siteStrings}</span>
                </p>
                <p else class="subscription__desc" skeleton-loading="is-loading.bind:pageLoading;height:16px">
                    \${'CX_RENEWS_ON_MANAGE' | siteStrings:null:[dateFormatterValueConverter.toView(userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy'),priceFormatted]}
                </p>       
            </div>
            <div class="payment-details-boxes">
                <div class="payment-details-boxes__methods">
                    <div class="payment-selectors">
                        <cx-payment-method-selector 
                            selected-payment-method.two-way="paymentMethodInitial"
                            view-model.ref="paymentMethodSelectorMain"
                            user.bind="user" 
                            auto-select.bind="false"
                            renewal-method-id.bind="userSubscriptionInfo.mainPaymentMethodId"
                            renewal-card-last-digits.bind="userSubscriptionInfo.mainCardLastDigits"
                            ignore-selected-currencies.bind="true"
                            skeleton-loading="is-loading.bind:pageLoading;"
                        ></cx-payment-method-selector>
                        <cx-payment-method-selector 
                            selected-payment-method.two-way="paymentMethodBackup"
                            view-model.ref="paymentMethodSelectorBackUp" 
                            user.bind="user" 
                            auto-select.bind="false"
                            suffix="(Back Up)"
                            renewal-method-id.bind="userSubscriptionInfo.backupPaymentMethodId"
                            renewal-card-last-digits.bind="userSubscriptionInfo.backupCardLastDigits"
                            ignore-selected-currencies.bind="true"
                            skeleton-loading="is-loading.bind:pageLoading;"
                        ></cx-payment-method-selector>
                    </div>
                </div>
            </div>
        </cx-card>
    </div>
</template>
`;
// Exports
export default code;