import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';
import {
    PaymentMethod, PaymentMethodForSelectorResponse,
    PaymentMethodThreshold,
    PaymentMethodWebsite,
    ThresholdOperationType
} from './models/purchase-flow/exchange';
import { SessionService } from './session-service';
import { CustomerService } from './customer-service';
import { TotalSpentByUser } from './models/user/user';
import { CurrencyService } from './currency-service';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class PaymentMethodWebsiteService {
    path = 'PaymentMethodWebsite';
    paymentMethods: PaymentMethodWebsite[];
    paymentMethodsForSelector: PaymentMethodForSelectorResponse[];
    thresholds: PaymentMethodThreshold[];
    recentPaymentMethods: PaymentMethodWebsite[];

    constructor(
        private api: ApiService,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private currencyService: CurrencyService,
		private helper: Helper
    ) { }

    async filter(filter) {
        return await this.api.doGet(this.path + '?filter=' + filter);
    }

    async getByWebsite(payoutableOnly = false): Promise<PaymentMethodWebsite[]> {
        if (this.paymentMethods) {
            if (payoutableOnly) return this.paymentMethods.filter(x => x.payoutable);
            return this.paymentMethods;
        }

        const websiteCode = websiteShortCode();
        const countryShortCode = this.sessionService.getCountry();
        this.paymentMethods = await this.api.doGet(`${this.path}/ByWebsite`, { websiteShortCode: websiteCode, countryShortCode, includePayoutable: true });
        this.paymentMethods = this.paymentMethods.sort((a, b) => Number(b.position !== null) - Number(a.position !== null) || a.position - b.position);

        if (payoutableOnly) return this.paymentMethods.filter(x => x.payoutable);
        return this.paymentMethods;
    }

    async getByWebsiteForSelector(payoutableOnly = false): Promise<PaymentMethodForSelectorResponse[]> {
        if (this.paymentMethodsForSelector) {
            if (payoutableOnly) return this.paymentMethodsForSelector.filter(x => x.payoutable);
            return this.paymentMethodsForSelector;
        }

        const websiteCode = websiteShortCode();
        const countryShortCode = this.sessionService.getCountry();
        this.paymentMethodsForSelector = await this.api.doGet(`${this.path}/ByWebsiteForSelector`, { websiteShortCode: websiteCode, countryShortCode, includePayoutable: true });
        this.paymentMethodsForSelector = this.paymentMethodsForSelector.sort((a, b) => Number(b.position !== null) - Number(a.position !== null) || a.position - b.position);

        if (payoutableOnly) return this.paymentMethodsForSelector.filter(x => x.payoutable);
        return this.paymentMethodsForSelector;
    }

    async getByValue(value: string) {
        const paymentMethods = await this.getByWebsite();
        return paymentMethods.find(p => p.paymentMethod.cryptoAbbreviation === value);
    }

    async getByReference(ref: string) {
        const paymentMethods = await this.getByWebsite();
        return paymentMethods.find(p => p.paymentMethod.reference === ref);
    }

    async getRecentPaymentMethodByUserId() {
        return await this.helper.fetchData(this.api, this.path + `/RecentPaymentMethodByUserId?websiteShortCode=${websiteShortCode()}&countryShortCode=${this.sessionService.getCountry()}`, 'getRecentPaymentMethodByUserId');
    }


    async getThresholds(): Promise<PaymentMethodThreshold[]> {
        if (this.thresholds) return this.thresholds;
        this.thresholds = await this.api.doPost(this.path + '/GetMultipleThresholdsByIds', {
            websiteShortCode: websiteShortCode(),
            countryShortCode: this.sessionService.getCountry()
        });
        return this.thresholds;
    }

    async getVerificationThresholds(totalSpentByUser: TotalSpentByUser | null, selectedPaymentMethod: PaymentMethod, totalPrice: number) {
        if (!this.thresholds) await this.getThresholds();

        const frequencyToAmountMap = {
            0: totalSpentByUser?.totalSpent,
            1: totalSpentByUser?.total1DaySpent,
            7: totalSpentByUser?.total7DaySpent,
            30: totalSpentByUser?.total30DaySpent
        };

        const result = {
            amountRequiresVeriff: false,
            amountRequiresPhoneVeriff: false
        };

        for (const t of this.thresholds) {
            if (t.paymentMethodId !== selectedPaymentMethod?.id) continue;

            const amountSpent = frequencyToAmountMap[t.frequency] || 0;
            const currencyCode = (await this.currencyService.getCurrencyById(t.currencyId))?.code;
            const rate = await this.currencyService.getStoredCurrencyRates(currencyCode);
            const requireVerif = (amountSpent + (totalPrice / rate)) >= t.threshold && totalSpentByUser.totalCountOrderCompleted >= t.transactionThreshold;

            if (!t.operationTypeList.includes(ThresholdOperationType.Buy)) continue;

            t.verificationTypeList.forEach(v => {
                switch (v) {
                    case 1: result.amountRequiresVeriff = requireVerif; break;
                    case 0: result.amountRequiresPhoneVeriff = requireVerif; break;
                }
            });
        }

        return result;
    }
}
