// Module
var code = `<template>
    <div class="unknown-route">
        <div id="unknown-route">
            <main class="d-flex h-100">
                <div class="unknown-container">
                    <header class="d-flex unknown-container--header">
                        <md-icon color="#98A3B6" class="unknown-container--header-leading-icon">
                            sentiment_very_dissatisfied
                        </md-icon>
                        <h1 class="unknown-container--header-title line-height-tiny">
                            404 - Page Not Found
                        </h1>
                    </header>
                    <hr class="unknown-container--divider"/>
                    <p class="unknown-container--message text-color-grey-100">
                        \${message}
                    </p>
                    <div class="unknown-container--cto-link button-container">
                        <a href="\${homePageRoute}">
                            <cx-button
                                button-text="Go home"
                                button-style="try-again-class primary-style btn-try px-5"
                            ></cx-button>
                        </a>
                    </div>
                </div>
            </main>
        </div>
    </div>
    <!-- <router-view></router-view> -->
</template>
`;
// Exports
export default code;