import './cx-currency-selector.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';
import { Currency } from 'services/models/purchase-flow/exchange';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';

@autoinject()
export class CxCurrencySelector {
    constructor(
        private sessionService: SessionService,
        private toastService: ToastService,
        private eventAggregator: EventAggregator,
        private siteStringsValueConverter: SiteStringsValueConverter
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
        this.id = Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    @bindable currencyList: Currency[];
    @bindable currencySelected: Currency;
    @bindable exchangeCurrencySelected: Currency;
    @bindable intent: string;
    @bindable activeCurrencies: Currency[];
    @bindable label: string;
    baseAwsEndpoint: string;
    arrowTriggered: HTMLElement;
    lastEventTriggered: string;
    arrowRotated: boolean;
    parent;
    search = '';
    resultsFound: boolean;
    id: string;
    focusIn = false;
    input;
    triggerPaymentMethodListener = false;
    firstResultIndex = 0;
    isClicked: boolean = false;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    attached() {
        this.handlePaymentMethodSelectorFocusOutEvent();
    }

    handlePaymentMethodSelectorFocusOutEvent() {
        const element = document.getElementById(`currency-selector-${this.id}`);
        if (!element || this.triggerPaymentMethodListener) return;
        this.triggerPaymentMethodListener = true;
        element.addEventListener('hide.bs.dropdown', () => {
            this.handleTextFieldFocusOut();
            this.clearSearch();
            this.input.blur();
        });
    }

    async selectCurrency(currency: Currency) {
        if (!currency) {
            this.handleTextFieldFocusOut();
            return;
        }
        this.isClicked = true;
        if (currency.type === 'F' && this.exchangeCurrencySelected?.type === 'F') {
            this.eventAggregator.publish('force-payment-method', { paymentMethodReference: 'cash-in-person' });
        }
        const selector = document.getElementById(`currency-selector-${this.id}`);
        selector.classList.remove('show');
        const dropdownMenu = document.getElementById(`currency-dropdown-menu-${this.id}`);
        dropdownMenu.classList.remove('show');
        this.currencySelected = currency;
        this.handleTextFieldFocusOut();
    }

    handleTextFieldFocusIn() {
        this.clearSearch();
        this.input.focus();
        if (!this.search?.length) this.input.input_.value = '';
        this.focusIn = true;
    }

    handleTextFieldFocusOut() {
        this.focusIn = false;
        this.setSuffix();
    }

    setSuffix() {
        if (!this.input) return;
        if (!this.currencySelected) {
            this.input.input_.value = '';
            return;
        }
        this.input.input_.value = `${this.currencySelected.code} - ${this.currencySelected.shortName}`;
    }

    handleSearchChange(event: KeyboardEvent) {
        this.search = (<HTMLInputElement>event?.target).value;
        if ((<HTMLInputElement>event?.target)?.value) {
            for (const currency of this.currencyList) {
                currency.show = currency.shortName.toLowerCase().includes(this.search.toLocaleLowerCase()) || currency.code.toLowerCase().includes(this.search.toLocaleLowerCase());
            }
        }
        this.resultsFound = this.currencyList?.some(x => x.show);
        this.firstResultIndex = this.currencyList?.findIndex(x => x.show);
        const dropdownMenu = document.getElementById(`currency-dropdown-menu-${this.id}`);
        dropdownMenu.classList.add('show');
    }

    updateAmountInSelect() {
        this.parent.selectChanged(this.currencySelected, this.intent, this.isClicked);
    }

    currencySelectedChanged() {
        this.updateAmountInSelect();
        this.setSuffix();
        this.isClicked = false;
    }

    clearSearch() {
        if (this.search === this.input?.input_.value) {
            this.input.input_.value = '';
        }
        this.search = null;
    }

    get currencyLabel(): string {
        return this.label ? this.label : this.siteStringsValueConverter.toView('CX_SELECT_CURRENCY_LABEL');
    }

    hoverCurrency(event, currency: Currency) {
        event.stopPropagation();
        const target = event.target as HTMLElement;
        const textWidth = this.calculateTextWidth(`${currency.code} - ${currency.shortName}`);
        if (textWidth <= target.clientWidth || !target.matches(':hover'))
            return;
        target?.style.setProperty('transform', `translateX(${target.clientWidth - textWidth}px)`);
        target?.style.setProperty('white-space', 'nowrap');
    }

    hoverOutCurrency(event) {
        const target = event.target as HTMLElement;
        target?.style.setProperty('transform', 'translateX(0)');
        target?.style.removeProperty('white-space');
    }

    calculateTextWidth(text) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '17px Roboto';
        return context.measureText(text).width;
    }
}
