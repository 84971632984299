// Module
var code = `<template>
    <div id="cx-user-avatar">
        <div
            ref="mainContainer"
            class="user-img position-relative \${'use-icon-size' |in: useIconSize}"
            click.delegate="handleAvatarDialogOpen()"
        >
            <div class="position-absolute d-flex align-items-center justify-content-center mask \${ isEdit ? '' : 'd-none'}">
                <span class="material-icons text-white text-normal">edit</span>
            </div>
            <div class="user-profile-container">
                <img if.bind="user.avatarImagePath && isProfile" class="user-profile" src="\${ user.avatarImagePath ? \`\${environment}Image/chicks-avatars/\${user.avatarImagePath}\` : \`\${environment}Image/chicks-avatars/chick_12\` }" alt="User profile" loading="lazy">
                <cds-icon else icon="person" class="user-profile" variant.bind="isHovered ? 'filled' : 'outlined'"></cds-icon>
            </div>
        </div>
        <cx-avatar-dialog
            view-model.ref="avatarDialogModule"
            user.bind="user"
        ></cx-avatar-dialog>
    </div>
</template>
`;
// Exports
export default code;