// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/branding/isologo.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/customer-portal/upload.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-avatar-dialog">
        <mdc-dialog view-model.ref="dialog" mdcdialog:closing.trigger="handleClosing()">
            <div
                if.bind="dialogStatus !== 'menu'"
                click.delegate="handleBack()"
                class="return d-inline-flex align-items-center"
            >
                <span class="material-icons back-button rounded-circle me-1">
                    chevron_left
                </span>
                \${'CX_BACK' | siteStrings}
            </div>
            <div class="dialogs-wrapper">
                <span click.delegate="close()" class="material-icons close-icon text-black cursor-pointer">
                    close
                </span>
                <mdc-dialog-content show.bind="dialogStatus === 'menu'" class="p-0">
                    <div class="mx-auto vertical-spacer-static">
                        <img class="dialog-user-profile p-3" src="\${user.avatarImagePath ? \`\${environment}Image/chicks-avatars/\${user.avatarImagePath}\` : \`\${environment}Image/chicks-avatars/chick_12\`}" alt="User avatar" loading="lazy">
                    </div>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <span class="text-medium-static text-darkergray font-medium home-title">\${'CX_CHOOSE_AVATAR_OR_UPLOAD_PROMPT' | siteStrings}</span>
                        <div class="dialog-inner">
                            <div click.delegate="switchToSeeAvatars()" tabindex="0" class="dialog-action d-flex align-items-center justify-content-center cursor-pointer">
                                <img src="${___HTML_LOADER_IMPORT_0___}" class="brand-iso me-2" alt="Brand logo" loading="lazy">
                                <span class="text-darkergray">\${'CX_SEE_AVATARS' | siteStrings}</span>
                            </div>
                            <hr class="mt-4">
                            <div click.delegate="switchToUpload()" tabindex="1" class="dialog-action d-flex align-items-center justify-content-center cursor-pointer mb-4">
                                <cds-icon icon="upload_2" label="upload" variant="outlined" color="#565974" class="me-3"></cds-icon>
                                <span class="text-darkergray">\${'CX_UPLOAD_IMAGE' | siteStrings}</span>
                            </div>
                        </div>
                    </div>
                </mdc-dialog-content>
                <mdc-dialog-content show.bind="dialogStatus === 'seeAvatars'" class="p-0">
                    <div show.bind="!selectedAvatar">
                        <div class="d-flex chicks-avatar-container">
                            <img class="dialog-user-profile img-sm p-2" src="\${user.avatarImagePath ? \`\${environment}Image/chicks-avatars/\${user.avatarImagePath}\` : \`\${environment}Image/chicks-avatars/chick_12\`}" alt="User avatar" loading="lazy">
                        </div>
                        <div class="d-flex flex-column justify-content-start align-items-start">
                            <span class="text-medium font-medium text-purple chicks-options-spacer">\${'CX_CHOOSE_AVATAR_PROMPT' | siteStrings}</span>
                            <section data-simplebar class="purple-scrollbar chicks-options-spacer">
                                <div class="avatars-container d-flex">
                                    <div class="avatar-inner-container d-flex align-items-center justify-content-center cursor-pointer" repeat.for="chick of staticChicks" tabindex="\${\$index}">
                                        <img class="avatar" src.bind="chick.src" click.delegate="selectAvatar(chick)" alt="Chicks avatar" loading="lazy">
                                    </div>
                                </div>      
                            </section>   
                        </div>
                    </div>
                    <div show.bind="selectedAvatar" class="mx-auto my-4 pt-4">
                        <span class="text-black font-medium">\${'CX_UPLOAD_IMAGE' | siteStrings}</span>
                        <div class="vertical-spacer">
                            <img class="dialog-user-profile avatar-preview img-lg p-3" src.bind="avatarPreview.src" alt="Chicks avatar" loading="lazy">
                            <cx-button                     
                                button-text.bind="'CX_USE_AS_AVATAR' | siteStrings"
                                button-style.bind="'primary primary-style use-btn font-medium'"
                                click.delegate="manualAvatarUpdate()"
                                class="align-self-center"
                            ></cx-button> 
                        </div>
                    </div>
                </mdc-dialog-content>
                <mdc-dialog-content show.bind="dialogStatus === 'uploadImage'" class="p-0">
                    <div class="mx-auto my-4 pt-4">
                        <span class="text-black font-medium">\${'CX_UPLOAD_IMAGE' | siteStrings}</span>
                        <div show.bind="selectedFiles">
                            <div if.bind="loading" class="drop-zone vertical-spacer-static mx-auto d-flex flex-column justify-content-center align-items-center">
                                <span class="text-black">\${'CX_UPLOADING' | siteStrings}...</span>
                                <mdc-linear-progress class="cx-linear-progress_success loading-container" open.bind="true" buffer="1"></mdc-linear-progress>
                            </div>
                            <div if.bind="!loading" repeat.for="file of selectedFiles | fileListToArray">
                                <img class="dialog-user-profile avatar-preview img-lg p-3" src.bind="file | blobToUrl" alt="User avatar" loading="lazy">
                                <cx-button                     
                                    button-text.bind="'CX_USE_AS_AVATAR' | siteStrings"
                                    button-style.bind="'primary primary-style p-btn font-medium'"
                                    click.delegate="handleAvatarUpdate(selectedFiles)"
                                ></cx-button>
                            </div>
                        </div>
                        <div if.bind="filesList.length > 0" class="mt-5">
                            <img class="dialog-user-profile img-lg p-3 mb-5" src.bind="filesList[0] | blobToUrl" alt="User avatar" loading="lazy">
                            <cx-button                     
                                button-text.bind="'CX_USE_AS_AVATAR' | siteStrings"
                                button-style.bind="'primary primary-style p-btn font-medium'"
                                click.delegate="handleAvatarUpdate(filesList)"
                            ></cx-button>
                        </div>
                        <div if.bind="filesList.length == 0 && !selectedFiles" drop.trigger="dropHandler(\$event)" dragenter.trigger="dragEnterHandler(\$event)" dragleave.trigger="dragLeaveHandler(\$event)" dragover.delegate="dragEnterHandler(\$event)" id="drop-zone" class="d-flex flex-column align-items-center justify-content-between drop-zone mx-auto py-5 vertical-spacer-static">
                            <img src="${___HTML_LOADER_IMPORT_1___}" alt="Upload an avatar" loading="lazy">
                            <span class="text-offblack text-medium">\${'CX_DRAG_FILES' | siteStrings}</span>
                            <cx-button
                                button-text="Browse files"
                                button-style.bind="'offlilac p-btn font-medium'"
                                click.delegate="triggerImgInput()"
                            ></cx-button>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <label id="profile-picture-upload" class="profile-picture-upload text-small">
                            <input id="image-input" ref="inputFileRef" type="file" class="d-none" accept="image/*" files.bind="selectedFiles" name="Image" change.delegate="avatarUploadChange()" />
                        </label>
                    </div>
                </mdc-dialog-content>
                <div class="p-manual"></div>
            </div>
        </mdc-dialog>
    </div>
</template>
`;
// Exports
export default code;