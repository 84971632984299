// Module
var code = `<template>
    <section id="tos" class="legal-page">
        <div class="header font-bold text-cx-purple">
            <div class="header-title text-start">\${'CX_TERMS_OF_SERVICE' | siteStrings}</div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_LAST_UPDATED'" class="text-end last-update">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_SERVICES_WEBSITE_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_SUMARY_OF_TERMS_TITLE'" class="font-bold text-title summary">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_SUMMARY_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_DEFINITIONS_TITLE'" class="font-bold text-title"></cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_DEFINITIONS_CONTENT'" class="text-content margened-paragraph"></cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ACCOUNT_REGISTRATION_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ACCOUNT_REGISTRATION_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_USE_OF_SERVICE_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_USE_OF_SERVICE_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_PAYMENT_FEES_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_PAYMENT_FEES_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_TRANSACTIONS_TITLE'" class="font-bold text-title"></cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_TRANSACTIONS_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_DISPUTES_USER_TITLE'" class="font-bold text-title"></cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_DISPUTES_USER_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_WARRANTIES_USER_TITLE'" class="font-bold text-title"></cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_WARRANTIES_USER_CONTENT'" class="text-content TOSmargened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_THIRD_PARTY_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_THIRD_PARTY_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_LICENSE_USE_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_LICENSE_USE_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INTELLECTUAL_PROPERTY_TITLE'" class="font-bold text-title"></cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INTELLECTUAL_PROPERTY_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_RISK_DISCLOSURE_TITLE'" class="font-bold text-title"></cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_RISK_DISCLOSURE_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_CONFIDENTIALITY_TITLE'" class="font-bold text-title"></cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_CONFIDENTIALITY_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INDEMNIFICATION_TITLE'" class="font-bold text-title"></cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INDEMNIFICATION_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ELECTRONIC_COMMUNICATIONS_TITLE'" class="font-bold text-title"></cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ELECTRONIC_COMMUNICATIONS_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INFORMATION_POSTED_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_INFORMATION_POSTED_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_LIMITATIONS_LIABILITY_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_LIMITATIONS_LIABILITY_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_WARRANTIES_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_WARRANTIES_CONTENT'" class="text-content margened-paragraph">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ENTIRE_AGREEMENT_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ENTIRE_AGREEMENT_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ASSIGNMENT_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_ASIGNMENT_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_NON_WAIVER_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_NON_WAIVER_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_FORCE_MAJEURE_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_FORCE_MAJEURE_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_GOVERNING_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_GOVERNING_CONTENT'" class="text-content">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_CONTACT_US_TITLE'" class="font-bold text-title">
            </cx-page-content-area>
            <cx-page-content-area key.bind="'TERMS_OF_SERVICE_CONTACT_US_CONTENT'" class="text-content">
            </cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;