import { autoinject, bindable } from 'aurelia-framework';
import './cx-no-results.scss';

@autoinject()
export class CxNoResults {
    @bindable() message: string = '';
    @bindable() show: boolean = true;

    constructor()
    {}
}
