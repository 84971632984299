import './cx-seo-dropdown.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class CxSeoDropdown {
    @bindable isLoading: boolean = true;
    @bindable headerTitle: string = 'View More';
    @bindable showHeaderIcon: boolean = false;
    @bindable headerIcon: string = 'info';
}
